import { Injectable } from '@angular/core';
import {
    SupabaseClient,
    RealtimeChannel,
    RealtimePostgresChangesPayload,
} from '@supabase/supabase-js';
import { Observable, from, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { SupabaseClientService } from './supabase-client.service';
import { InsuranceGroupedView } from '../supabase-models/insurance-grouped-view';

@Injectable({
    providedIn: 'root',
})
export class InsuranceGroupedViewService {
    private supabase: SupabaseClient;
    private channel: RealtimeChannel;

    constructor(private _supabaseClientService: SupabaseClientService) {
        this.supabase = this._supabaseClientService.getClient();
    }

    getAllInsuranceGroupedView(): Observable<InsuranceGroupedView[]> {
        return from(
            this.supabase
                .from('insurance_grouped_view')
                .select('*')
        ).pipe(
            map((response) => {
                if (response.error) {
                    console.error('Supabase error:', response.error);
                    throw response.error;
                }
                return (response.data || []) as InsuranceGroupedView[];
            }),
            catchError((error) => {
                console.error('Error in getAllInsuranceGroupedView:', error);
                return throwError(() => new Error(error.message));
            })
        );
    }

    getInsuranceGroupedByOwnerId(ownerId: string): Observable<InsuranceGroupedView[]> {
        return from(
            this.supabase
                .from('insurance_grouped_view')
                .select('*')
                .eq('holder_owner_id', ownerId)
        ).pipe(
            map((response) => {
                if (response.error) {
                    throw response.error;
                }
                return (response.data || []) as InsuranceGroupedView[];
            }),
            catchError((error) => throwError(() => new Error(error.message)))
        );
    }

    getInsuranceGroupedByExtractionId(extractionId: number): Observable<InsuranceGroupedView[]> {
        return from(
            this.supabase
                .from('insurance_grouped_view')
                .select('*')
                .eq('policy_extraction_id', extractionId)
        ).pipe(
            map((response) => {
                if (response.error) {
                    throw response.error;
                }
                return (response.data || []) as InsuranceGroupedView[];
            }),
            catchError((error) => throwError(() => new Error(error.message)))
        );
    }

    subscribeToInsurancesByOwnerId(
        ownerId: string
    ): Observable<RealtimePostgresChangesPayload<any>> {
        return new Observable((observer) => {
            this.channel = this.supabase
                .channel(`public:insurance:holder_owner_id=eq.${ownerId}`)
                .on(
                    'postgres_changes',
                    {
                        event: '*',
                        schema: 'public',
                        table: 'insurance',
                        filter: `holder_owner_id=eq.${ownerId}`,
                    },
                    (payload) => {
                        observer.next(payload);
                    }
                )
                .subscribe();

            return () => {
                this.supabase.removeChannel(this.channel);
            };
        });
    }
}
