import { BooleanInput } from '@angular/cdk/coercion';
import { NgClass, NgIf } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnDestroy,
    OnInit,
    ViewEncapsulation,
    ViewChild,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { Router, RouterModule } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { inject } from '@angular/core';

import { PersonWithSettingService } from '../../../common/supabase-services/person-with-setting.service';
import { PersonWithSetting } from '../../../common/supabase-models/person-with-setting';
import { SharedSwitcherService } from '../../../common/services/shared-switcher.service';
import { NavigationService } from '../../../core/navigation/navigation.service';
import { AuthService } from '../../../core/auth/auth.service';

@Component({
    selector: 'user',
    templateUrl: './user.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'user',
    standalone: true,
    imports: [
        MatButtonModule,
        MatMenuModule,
        NgIf,
        MatIconModule,
        NgClass,
        MatDividerModule,
        RouterModule,
    ],
})
export class UserComponent implements OnInit, OnDestroy {
    /* eslint-disable @typescript-eslint/naming-convention */
    static ngAcceptInputType_showAvatar: BooleanInput;
    /* eslint-enable @typescript-eslint/naming-convention */
    @Input() showAvatar: boolean = true;
    user: PersonWithSetting;

    private _unsubscribeAll: Subject<any> = new Subject<any>();
    auth: AuthService = inject(AuthService);
    isAdminDisplay: boolean = true;
    isAdmin: boolean = true;
    isSuperUser: boolean = false;

    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _router: Router,
        private _personWithSettingService: PersonWithSettingService,
        private sharedSwitcherService: SharedSwitcherService,
        private _navigationService: NavigationService
    ) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        const storedView = localStorage.getItem('view') || 'user';
        
        this.isAdminDisplay = storedView === 'admin';

        this._personWithSettingService.personWithSetting$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((user: PersonWithSetting) => {
                this.user = user;
                this.isAdmin = this.auth.isAdmin();

                this.auth.isSuperUser$
                    .pipe(takeUntil(this._unsubscribeAll))
                    .subscribe((isSuperUser) => {
                        this.isSuperUser = isSuperUser;
                        this._changeDetectorRef.detectChanges();
                    });
            });

        this.sharedSwitcherService.setSwitcherStateLocal(this.isAdminDisplay);
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Update the user status
     *
     * @param status
     */
    updateUserStatus(status: string): void {
        // Return if user is not available
        if (!this.user) {
            return;
        }
    }

    /**
     * Sign out
     */
    signOut(): void {
        this.isAdminDisplay = false;
        this.sharedSwitcherService.setSwitcherStateLocal(false);

        // Navigate after a small delay to allow Angular to complete current cycle
        setTimeout(() => {
            // Try navigating with replaceUrl to avoid history issues
            this._router.navigate(['/sign-out'], { replaceUrl: true });
        }, 10);
    }
    async switchToAdmin() {
        // Switch to admin display (does not affect authentication)
        this.isAdminDisplay = true;
        localStorage.setItem('view', 'admin'); // Keep user view stored
        try {
            this._navigationService.navigation$
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe((navigation) => {
                    this._navigationService.setAdminMenu(navigation);
                    this._router.navigate(['admin/tasks']);
                });
            this.sharedSwitcherService.setSwitcherStateLocal(true);
        } catch (error) {
            console.error('Error occured:', error);
        }
    }

    async switchToUser() {
        // Switch to user display (does not affect authentication)
        localStorage.setItem('view', 'user'); // Keep user view stored
        this.isAdminDisplay = false;
        try {
            this._navigationService.navigation$
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe((navigation) => {
                    this._navigationService.setAdminMenu(navigation);
                    this._router.navigate(['/ai-chat']);
                });
            this.sharedSwitcherService.setSwitcherStateLocal(false);
        } catch (error) {
            console.error('Error occured:', error);
        }
    }
}
