import {
    Component,
    Input,
    Output,
    EventEmitter,
    OnInit,
    ChangeDetectorRef,
    ChangeDetectionStrategy,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { Observable, combineLatest, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { TranslocoModule } from '@ngneat/transloco';

import { PersonService } from 'app/common/supabase-services/person.service';
import { CoverageGroupWithMembersService } from 'app/common/supabase-services/coverage-group-with-members.service';

import { Person } from 'app/common/supabase-models/person';
import { CoverageGroupWithMembers } from 'app/common/supabase-models/coverage-group-with-members';

import { PersonFormComponent } from 'app/common/components/person-form/person-form.component';
import { CoverageGroupFormComponent } from 'app/common/components/coverage-group-form/coverage-group-form.component';

@Component({
    selector: 'app-select-beneficiary',
    templateUrl: './select-beneficiary.component.html',
    styleUrls: ['./select-beneficiary.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        MatSelectModule,
        MatButtonModule,
        TranslocoModule,
        MatFormFieldModule,
        PersonFormComponent,
        CoverageGroupFormComponent,
    ],
})
export class SelectBeneficiaryComponent implements OnInit {
    @Input() ownerId: string;
    @Input() selectedType: 'person' | 'group' ='person';
    @Input() selectedId: number | null = null;
    @Input() selectedPersonIds: number[] = [];
    @Input() selectedGroupIds: number[] = [];
    @Input() onChangeOnly: boolean = false;
    @Input() allowAdhoc: boolean = true;
    @Input() disabled: boolean = false;
    @Input() label: string = 'Select Beneficiary';
    @Input() btnLabel: string = 'Save';
    @Output() adhocToggle = new EventEmitter<boolean>();
    @Output() onChange = new EventEmitter<{
        type: 'person' | 'group';
        data: Person | CoverageGroupWithMembers;
    }>();
    @Output() onSave = new EventEmitter<{
        type: 'person' | 'group';
        data: Person | CoverageGroupWithMembers;
    }>();

    personBeneficiaries: { type: 'person'; data: Person }[] = [];
    groupBeneficiaries: { type: 'group'; data: CoverageGroupWithMembers }[] = [];
    selectedBeneficiary: {
        type: 'person' | 'group';
        data: Person | CoverageGroupWithMembers;
    } | null = null;
    showPersonForm: boolean = false;
    showGroupForm: boolean = false;

    constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private personService: PersonService,
        private coverageGroupWithMembersService: CoverageGroupWithMembersService
    ) {}

    ngOnInit() {
        this.loadBeneficiaries();
    }

    loadBeneficiaries() {
        if (!this.ownerId) {
            this.personBeneficiaries = [];
            this.groupBeneficiaries = [];
            return;
        }

        const persons$: Observable<{ type: 'person'; data: Person }[]> =
            this.personService
                .getAllByOwnerId(this.ownerId)
                .pipe(
                    map((persons: Person[]) =>
                        persons.map((person) => ({ type: 'person', data: person }))
                    )
                );

        const groups$: Observable<{ type: 'group'; data: CoverageGroupWithMembers }[]> =
            this.coverageGroupWithMembersService
                .getAllByOwnerId(this.ownerId)
                .pipe(
                    map((groups: CoverageGroupWithMembers[]) =>
                        groups.map((group) => ({ type: 'group', data: group }))
                    )
                );

        combineLatest([persons$, groups$]).subscribe(([persons, groups]) => {
            this.personBeneficiaries = persons;
            this.groupBeneficiaries = groups;

            if (this.selectedId) {
                if (this.selectedType === 'person') {
                    this.selectedBeneficiary =
                        this.personBeneficiaries.find(
                            (beneficiary) =>
                                (beneficiary.data as Person).id === this.selectedId
                        ) || null;
                } else {
                    this.selectedBeneficiary =
                        this.groupBeneficiaries.find(
                            (beneficiary) =>
                                (beneficiary.data as CoverageGroupWithMembers).coverage_group_id === this.selectedId
                        ) || null;
                }
            }

            this.changeDetectorRef.markForCheck();
        });
    }

    onSelectBeneficiary(
        selected:
            | { type: 'person' | 'group'; data: Person | CoverageGroupWithMembers }
            | number
    ) {
        if (!this.disabled) {
            if (typeof selected === 'number' && this.allowAdhoc) {
                if (selected === -1) {
                    this.showPersonForm = true;
                    this.adhocToggle.emit(true);
                } else if (selected === -2) {
                    this.showGroupForm = true;
                    this.adhocToggle.emit(true);
                }
            } else if (typeof selected !== 'number') {
                this.selectedBeneficiary = selected;
                this.onChange.emit(selected);
            }
        }
    }

    onSavePersonForm(person: Person) {
        this.selectedBeneficiary = { type: 'person', data: person };
        this.onChange.emit(this.selectedBeneficiary);
        this.showPersonForm = false;
        this.loadBeneficiaries();
    }

    onCancelPersonForm() {
        this.selectedBeneficiary = null;
        this.adhocToggle.emit(false);
        this.showPersonForm = false;
        this.loadBeneficiaries();
    }

    closeGroup(group?: CoverageGroupWithMembers) {
        if (group) {
            this.selectedBeneficiary = { type: 'group', data: group };
            this.onChange.emit(this.selectedBeneficiary);
        } else {
            this.selectedBeneficiary = null;
            this.adhocToggle.emit(false);
        }
        this.showGroupForm = false;
        this.loadBeneficiaries();
    }

    onClickSave() {
        this.onSave.emit(this.selectedBeneficiary);
    }

    compareWithFunction(
        item1: { type: 'person' | 'group'; data: Person | CoverageGroupWithMembers },
        item2: { type: 'person' | 'group'; data: Person | CoverageGroupWithMembers }
    ) {
        if (!item1 || !item2) return item1 === item2;
        
        if (item1.type === 'person' && item2.type === 'person') {
            return (item1.data as Person).id === (item2.data as Person).id;
        } 
        
        if (item1.type === 'group' && item2.type === 'group') {
            return (item1.data as CoverageGroupWithMembers).coverage_group_id === 
                   (item2.data as CoverageGroupWithMembers).coverage_group_id;
        }
    
        return false;
    }

    trackById(
        index: number,
        item: { type: 'person' | 'group'; data: Person | CoverageGroupWithMembers }
    ) {
        return item.type === 'person'
            ? (item.data as Person).id
            : (item.data as CoverageGroupWithMembers).coverage_group_id;
    }
}
