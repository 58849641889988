import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { AvailableLangs, TranslocoService } from '@ngneat/transloco';
import { TranslocoModule } from '@ngneat/transloco';

import { UsersnapComponent } from './mock-api/apps/usersnap/usersnap.component';
import { AuthService } from './core/auth/auth.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [RouterOutlet, UsersnapComponent, TranslocoModule],
})
export class AppComponent implements OnInit {
    activeLangDisplay: string;
    defaultLanguage: string;
    savedLanguage: string;

    constructor(
        private _translocoService: TranslocoService,
        private _authService: AuthService
    ) {}

    ngOnInit(): void {
        const browserLanguage = navigator.language || 'en-US';
        this.defaultLanguage = browserLanguage.split('-')[0];

        // Subscribe to auth service's user changes
        this._authService.user.subscribe((user) => {
            if (user) {
                // Retrieve saved language from user settings
                if (user.language) {
                    this.savedLanguage = user.language;
                } else {
                    // Fallback to default language if none is saved
                    this.savedLanguage = this.defaultLanguage;
                }

                // Set the active language using Transloco
                this._translocoService.setActiveLang(this.savedLanguage);
            } else {
                // If no user is logged in, use the default language
                this._translocoService.setActiveLang(this.defaultLanguage);
            }
        });
    }
}
