<ng-select 
    #ngSelectAdminUser
    class="w-full"
    matInput
    appearance="outline"
    [items]="adminUsers" 
    bindLabel="full_name" 
    bindValue="user_id" 
    [markFirst]="false"
    [editableSearchTerm]="true"
    [formControl]="adminUserControl"
    [loading]="isLoading"
>
</ng-select>
