import { Component, inject } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-redirect',
    template: '',
})
export class RedirectComponent {
    private router = inject(Router);

    constructor() {
        const storedView = localStorage.getItem('view') || 'user';
        const redirectUrl = storedView === 'admin' ? '/admin/tasks' : '/ai-chat';

        this.router.navigateByUrl(redirectUrl, { replaceUrl: true });
    }
}
