import { Injectable } from '@angular/core';
import { createClient, SupabaseClient } from '@supabase/supabase-js';
import { environment } from 'environments/environment';

@Injectable({
    providedIn: 'root', // Make sure this service is singleton and available app-wide
})
export class SupabaseClientService {
    private supabase: SupabaseClient;
    public supabaseUrl = environment.supabaseUrl;

    constructor() {
        // Initialize Supabase client once
        this.supabase = createClient(environment.supabaseUrl, environment.supabaseKey);
    }

    // Getter to return the Supabase client
    getClient(): SupabaseClient {
        return this.supabase;
    }
}
