<ng-container *ngIf="!toggleObjectForm; else displayForm;">
  <div class="flex flex-col justify-center items-center">
    <mat-form-field appearance="outline" class="w-full">
      <mat-label>{{ label }}</mat-label>
      <mat-select
        [(ngModel)]="selectedObject"
        (ngModelChange)="onSelect($event)"
        [compareWith]="compareWithFunction"
      >
        <mat-option [value]="0">+ Add beneficiary</mat-option>
        <mat-option
          *ngFor="let po of (peopleAndObjects$ | async); trackBy: trackById"
          [value]="po"
          [disabled]="selectedIds.includes(po.id)"
        >
          {{ (po.date_of_birth | date: 'dd.MM.yyyy') + ' ' + po.full_name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div
      *ngIf="!onChangeOnly"
      class="w-full flex justify-end items-center mt-4 px-4"
    >
      <button mat-raised-button color="primary" (click)="onSave()">
        {{ btnLabel }}
      </button>
    </div>
  </div>
</ng-container>
<ng-template #displayForm>
  <app-object-form
    [method]="'create'"
    [inputData]="{ owner_id: ownerId, type: 'person' }"
    (closeForm)="closeObject($event)"
  >
  </app-object-form>
</ng-template>