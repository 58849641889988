import { Injectable } from '@angular/core';
import { SupabaseClient } from '@supabase/supabase-js';
import { SupabaseClientService } from './supabase-client.service';
import { InsuranceRetrieval } from '../supabase-models/insurance-retrieval';
import { from, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class InsuranceRetrievalService {
    private supabase: SupabaseClient;

    constructor(private _supabaseClientService: SupabaseClientService) {
        this.supabase = this._supabaseClientService.getClient();
    }

    // Create new insurance retrieval
    create(entity: InsuranceRetrieval): Observable<InsuranceRetrieval> {
        return from(
            this.supabase.from('insurance_retrieval').insert([entity]).select()
        ).pipe(
            map((response) => {
                // Check if there's an error in the response
                if (response.error) {
                    throw new Error(response.error.message);
                }

                // Ensure the data is treated as an array of InsuranceRetrieval
                const data = response.data as InsuranceRetrieval[] | undefined;

                // If data is available and has items, return the first record
                if (data && data.length > 0) {
                    return data[0];
                }

                // If no data is returned but no error occurred, assume the insert was successful
                return entity as InsuranceRetrieval;
            }),
            catchError((error) => throwError(() => new Error(error.message)))
        );
    }

    // Get insurance retrieval by ID
    getById(id: number): Observable<InsuranceRetrieval> {
        return from(
            this.supabase.from('insurance_retrieval').select('*').eq('id', id).single()
        ).pipe(
            map((response) => {
                if (response.data) {
                    return response.data as InsuranceRetrieval;
                }
                throw new Error('Record not found');
            }),
            catchError((error) => throwError(() => new Error(error.message)))
        );
    }

    // Update insurance retrieval by ID
    update(
        id: number,
        entity: Partial<InsuranceRetrieval>
    ): Observable<InsuranceRetrieval> {
        return from(
            this.supabase.from('insurance_retrieval').update(entity).eq('id', id).select()
        ).pipe(
            map((response) => {
                // Check if the update operation returned an error
                if (response.error) {
                    throw new Error(response.error.message);
                }

                // Ensure the data is treated as an array of InsuranceRetrieval
                const data = response.data as InsuranceRetrieval[] | undefined;

                // If data is available and has items, return the first record
                if (data && data.length > 0) {
                    return data[0];
                }

                // If no data is returned but no error occurred, assume the update was successful
                return entity as InsuranceRetrieval;
            }),
            catchError((error) => throwError(() => new Error(error.message)))
        );
    }

    // Delete insurance retrieval by ID
    delete(id: number): Observable<void> {
        return from(this.supabase.from('insurance_retrieval').delete().eq('id', id)).pipe(
            map(() => void 0),
            catchError((error) => throwError(() => new Error(error.message)))
        );
    }
}
