<div class="flex w-full relative p-6 flex-col justify-center">
    <div class="w-full mb-6">
        <!-- Header Section -->
        <div class="flex justify-between items-center mb-4">
            <div class="text-lg font-semibold text-gray-700">
                {{ 'policyRequest.details' | transloco }}
            </div>
            <button mat-icon-button (click)="onClickClose()">
                <mat-icon>close</mat-icon>
            </button>
        </div>

        <!-- User Info Section -->
        <div class="flex justify-start items-end gap-4 mb-3">
            <img
                *ngIf="policyRequest.user_photo"
                class="h-12 w-12 rounded"
                [src]="policyRequest.user_photo"
                [alt]="policyRequest.user_full_name"
            />
            <mat-icon
                *ngIf="!policyRequest.user_photo"
                class="icon-size-12"
                svgIcon="heroicons_outline:user-circle"
            ></mat-icon>
            <div>
                <p class="text-black">{{ policyRequest.user_full_name }}</p>
                <p class="text-black">{{ policyRequest.user_email }}</p>
            </div>
        </div>

        <!-- Insurer Section -->
        <div class="flex justify-between items-center mb-1">
            <span class="text-secondary">{{ 'Insurer' }}</span>
            <span class="text-black">
                {{ policyRequest['insurer_name_' + currentLanguage.toLowerCase()] || policyRequest.insurer_default_name }}
            </span>
        </div>

        <!-- Status Section -->
        <div class="flex justify-between items-center mb-1">
            <span class="text-secondary">{{ 'policyRequest.status' | transloco }}</span>
            <div class="flex items-center gap-2">
                <ng-container *ngIf="!editStatus; else editStatusTemplate">
                    <span class="text-black">{{ statusMapping[policyRequest.status] }}</span>
                    <mat-icon
                        *ngIf="isAdmin"
                        class="cursor-pointer icon-size-4"
                        svgIcon="heroicons_outline:pencil-square"
                        (click)="startEditStatus()"
                    ></mat-icon>
                </ng-container>
                <ng-template #editStatusTemplate>
                    <mat-form-field appearance="fill" class="w-48">
                        <mat-select
                            [(ngModel)]="tempStatus"
                            (click)="$event.stopPropagation()"
                        >
                            <mat-option
                                *ngFor="let statusOption of allStatusOptions"
                                [value]="statusOption"
                            >
                                {{ statusMapping[statusOption] }}
                            </mat-option>
                        </mat-select>
                        <mat-icon
                            matSuffix
                            class="cursor-pointer icon-size-4"
                            svgIcon="heroicons_outline:check"
                            (click)="updateStatus(); $event.stopPropagation()"
                        ></mat-icon>
                        <mat-icon
                            matSuffix
                            class="cursor-pointer icon-size-4"
                            svgIcon="heroicons_outline:x-mark"
                            (click)="cancelEditStatus(); $event.stopPropagation()"
                        ></mat-icon>
                    </mat-form-field>
                </ng-template>
            </div>
        </div>

        <!-- Urgency Section -->
        <div class="flex justify-between items-center mb-1">
            <span class="text-secondary">Urgency</span>
            <div class="flex items-center gap-2">
                <ng-container *ngIf="!editUrgency; else editUrgencyTemplate">
                    <span class="text-black capitalize">{{ policyRequest?.urgency || 'Low' }}</span>
                    <mat-icon
                        *ngIf="isAdmin"
                        class="cursor-pointer icon-size-4"
                        svgIcon="heroicons_outline:pencil-square"
                        (click)="toggleEditUrgency()"
                    ></mat-icon>
                </ng-container>
                <ng-template #editUrgencyTemplate>
                    <mat-form-field appearance="fill" class="w-48">
                        <mat-select
                            [(ngModel)]="tempUrgency"
                            (click)="$event.stopPropagation()"
                        >
                            <mat-option value="low">{{ 'Low' }}</mat-option>
                            <mat-option value="medium">{{ 'Medium' }}</mat-option>
                            <mat-option value="high">{{ 'High' }}</mat-option>
                        </mat-select>
                        <mat-icon
                            matSuffix
                            class="cursor-pointer icon-size-4"
                            svgIcon="heroicons_outline:check"
                            (click)="updateUrgency(); $event.stopPropagation()"
                        ></mat-icon>
                        <mat-icon
                            matSuffix
                            class="cursor-pointer icon-size-4"
                            svgIcon="heroicons_outline:x-mark"
                            (click)="toggleEditUrgency(); $event.stopPropagation()"
                        ></mat-icon>
                    </mat-form-field>
                </ng-template>
            </div>
        </div>

        <!-- Assignee Section -->
        <div class="flex justify-between items-center mb-1">
            <span class="text-secondary">Assignee</span>
            <div class="flex items-center gap-2">
                <ng-container *ngIf="!editAssignee; else editAssigneeTemplate">
                    <span class="text-black">{{ assigneeName || 'Unassigned' }}</span>
                    <mat-icon
                        *ngIf="isAdmin"
                        class="cursor-pointer icon-size-4"
                        svgIcon="heroicons_outline:pencil-square"
                        (click)="toggleEditAssignee()"
                    ></mat-icon>
                </ng-container>
                <ng-template #editAssigneeTemplate>
                    <select-admin-user
                        class="min-w-48"
                        [selectedAdminUserId]="tempAssigneeId"
                        (onSelectAdminUserId)="handleAssigneeChange($event)"
                    ></select-admin-user>
                    <div class="flex justify-center items-center gap-1 pb-3">
                        <mat-icon
                            matSuffix
                            class="cursor-pointer icon-size-5"
                            svgIcon="heroicons_outline:check"
                            (click)="updateAssignee(); $event.stopPropagation()"
                        ></mat-icon>
                        <mat-icon
                            matSuffix
                            class="cursor-pointer icon-size-5"
                            svgIcon="heroicons_outline:x-mark"
                            (click)="toggleEditAssignee(); $event.stopPropagation()"
                        ></mat-icon>
                    </div>
                </ng-template>
            </div>
        </div>

        <!-- Created At Section -->
        <div class="flex justify-between items-center">
            <span class="text-secondary">{{ 'policyRequest.createdAt' | transloco }}</span>
            <span class="text-black">
                {{ policyRequest.created_at | date: 'dd.MM.yyyy HH:mm' }}
            </span>
        </div>
    </div>

    <!-- Tabs Section -->
    <div class="w-full">
        <mat-tab-group mat-align-tabs="start" class="mb-4" (selectedTabChange)="onTabChange($event)">
            <!-- Comments Tab -->
            <mat-tab label="Comments">
                <div class="flex flex-col">
                    <!-- Comment Type Selector (Admin Only) -->
                    <mat-form-field *ngIf="isAdmin" appearance="fill" class="w-full">
                        <mat-select class="capitalize" [(ngModel)]="activityType">
                            <mat-option
                                class="capitalize"
                                *ngFor="let activityOption of activityOptions"
                                [value]="activityOption"
                            >
                                {{ 'policyRequest.' + activityTypeMapping[activityOption] | transloco }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <!-- Comment Textarea -->
                    <mat-form-field appearance="fill" class="w-full">
                        <textarea
                            matInput
                            [(ngModel)]="newComment.description"
                            rows="4"
                            [disabled]="isLoading"
                        ></textarea>
                    </mat-form-field>
                    <div class="flex justify-end items-center">
                        <button
                            mat-raised-button
                            color="primary"
                            (click)="addComment()"
                            [disabled]="isLoading"
                        >
                            {{ 'Comment' }}
                        </button>
                    </div>
                </div>
                <!-- Comments List -->
                <div
                    class="mt-4 overflow-auto"
                    [ngClass]="
                        !isMobile
                            ? 'max-h-[calc(100vh-752px)]'
                            : 'max-h-[calc(100vh-672px)]'
                    "
                >
                    <ul>
                        <li *ngFor="let comment of policyRequest.comments" class="mb-2">
                            <p class="text-sm text-secondary">{{ comment.user?.full_name }}</p>
                            <p class="text-sm text-secondary">
                                {{ comment.created_at | date: 'dd.MM.yyyy HH:mm' }}
                            </p>
                            <p class="text-black">{{ comment.description }}</p>
                        </li>
                    </ul>
                </div>
            </mat-tab>

            <!-- Activities Tab -->
            <mat-tab label="Activities">
                <div
                    class="mt-4 overflow-auto"
                    [ngClass]="
                        !isMobile
                            ? 'max-h-[calc(100vh-580px)]'
                            : 'max-h-[calc(100vh-490px)]'
                    "
                >
                    <ul>
                        <li *ngFor="let event of events" class="mb-2">
                            <p class="text-sm text-secondary">{{ event.created_by.full_name }}</p>
                            <p class="text-sm text-secondary">
                                {{ event.created_at | date: 'dd.MM.yyyy HH:mm' }}
                            </p>
                            <p class="text-black" [innerHTML]="event | eventMessage"></p>
                        </li>
                    </ul>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>

<!-- Loading Spinner -->
<ng-container *ngIf="isLoading">
    <div
        class="absolute inset-0 bg-gray-500 bg-opacity-50 flex justify-center items-center rounded"
    >
        <mat-progress-spinner [diameter]="40" mode="indeterminate"></mat-progress-spinner>
    </div>
</ng-container>
