import { Injectable } from '@angular/core';
import { SupabaseClient } from '@supabase/supabase-js';
import { Observable, from, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { SupabaseClientService } from './supabase-client.service';
import { InsuranceProductExpandedView } from '../supabase-models/insurance-product-expanded-view';

@Injectable({
    providedIn: 'root',
})
export class InsuranceProductExpandedViewService {
    private supabase: SupabaseClient;

    constructor(private _supabaseClientService: SupabaseClientService) {
        this.supabase = this._supabaseClientService.getClient();
    }

    /**
     * Fetches expanded insurance product data with associated insurance product type and insurer details.
     */
    getAllExpandedInsuranceProducts(): Observable<InsuranceProductExpandedView[]> {
        return from(this.supabase.from('insurance_product_expanded').select('*')).pipe(
            map((response) => {
                if (response.error) {
                    throw response.error;
                }
                return (response.data || []) as InsuranceProductExpandedView[];
            }),
            catchError((error) => throwError(() => new Error(error.message)))
        );
    }

    /**
     * Fetches expanded insurance product data by a specific insurer.
     * @param insurerId The ID of the insurer.
     */
    getExpandedInsuranceProductsByInsurer(
        insurerId: number
    ): Observable<InsuranceProductExpandedView[]> {
        return from(
            this.supabase
                .from('insurance_product_expanded')
                .select('*')
                .eq('brand_insurer_id', insurerId)
        ).pipe(
            map((response) => {
                if (response.error) {
                    throw response.error;
                }
                return (response.data || []) as InsuranceProductExpandedView[];
            }),
            catchError((error) => throwError(() => new Error(error.message)))
        );
    }

    /**
     * Fetches expanded insurance product data by a specific product type.
     * @param productTypeId The ID of the insurance product type.
     */
    getExpandedInsuranceProductsByType(
        productTypeId: number
    ): Observable<InsuranceProductExpandedView[]> {
        return from(
            this.supabase
                .from('insurance_product_expanded')
                .select('*')
                .eq('insurance_product_type_id', productTypeId)
        ).pipe(
            map((response) => {
                if (response.error) {
                    throw response.error;
                }
                return (response.data || []) as InsuranceProductExpandedView[];
            }),
            catchError((error) => throwError(() => new Error(error.message)))
        );
    }

    /**
     * Fetches expanded insurance product data with optional filters.
     * @param insurerId Optional insurer ID to filter by.
     * @param productTypeIds Optional product type IDs to filter by.
     */
    getExpandedInsuranceProducts(
        insurerId?: number,
        productTypeIds?: number[]
    ): Observable<InsuranceProductExpandedView[]> {
        let query = this.supabase.from('insurance_product_expanded').select('*');

        // Filter by insurer ID if provided
        if (insurerId !== undefined && insurerId !== null) {
            query = query.eq('brand_insurer_id', insurerId);
        }

        // Apply product type filter only if productTypeIds exist and contain values
        if (productTypeIds && productTypeIds.length > 0) {
            const productTypeIdsString = productTypeIds.join(',');

            query = query.or(
                `insurance_product_type_id.in.(${productTypeIdsString}), insurance_product_type_ids.cs.{${productTypeIdsString}}`
            );
        }

        return from(query).pipe(
            map((response) => {
                if (response.error) {
                    throw response.error;
                }
                return (response.data || []) as InsuranceProductExpandedView[];
            }),
            catchError((error) => throwError(() => new Error(error.message)))
        );
    }
}
