import { Injectable } from '@angular/core';
import { SupabaseClient } from '@supabase/supabase-js';
import { from, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { ObjectExpanded } from '../supabase-models/object-expanded';
import { SupabaseClientService } from './supabase-client.service';

@Injectable({
    providedIn: 'root',
})
export class ObjectExpandedService {
    private supabase: SupabaseClient;

    constructor(private supabaseClientService: SupabaseClientService) {
        this.supabase = this.supabaseClientService.getClient();
    }

    /**
     * Retrieve all records from the object_expanded view
     */
    getAll(): Observable<ObjectExpanded[]> {
        return from(this.supabase.from('object_expanded').select('*')).pipe(
            map((response) => {
                if (response.data) {
                    return response.data as ObjectExpanded[];
                }
                throw new Error('Failed to fetch objects');
            }),
            catchError((error) => throwError(() => new Error(error.message)))
        );
    }

    /**
     * Retrieve objects filtered by owner_id
     * @param ownerId The owner ID to filter by
     */
    getByOwnerId(ownerId: string): Observable<ObjectExpanded[]> {
        return from(
            this.supabase.from('object_expanded').select('*').eq('owner_id', ownerId)
        ).pipe(
            map((response) => {
                if (response.data) {
                    return response.data as ObjectExpanded[];
                }
                throw new Error('No objects found for the given owner_id');
            }),
            catchError((error) => throwError(() => new Error(error.message)))
        );
    }

    /**
     * Retrieve a record by ID from the object_expanded view
     * @param id ID of the record to retrieve
     */
    getById(id: number): Observable<ObjectExpanded> {
        return from(
            this.supabase.from('object_expanded').select('*').eq('id', id).single()
        ).pipe(
            map((response) => {
                if (response.data) {
                    return response.data as ObjectExpanded;
                }
                throw new Error('Record not found in object_expanded');
            }),
            catchError((error) => throwError(() => new Error(error.message)))
        );
    }
}
