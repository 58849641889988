<ng-container *ngIf="!activateEntityForm; else displayForm">
  <div class="flex flex-col justify-center items-center">
    <mat-form-field *ngIf="!selectedObjectTypeId" appearance="outline" class="w-full">
      <mat-label>{{ 'objects.form.selectEntityType' | transloco }}</mat-label>
      <mat-select
        [(ngModel)]="selectedObjectType"
        [compareWith]="compareObjTypeWithFunction"
        (ngModelChange)="onSelectObjectType($event)"
      >
        <mat-option
          *ngFor="let objectType of (objectTypes$ | async); trackBy: trackById"
          [value]="objectType"
        >
          {{ objectType.name }}
        </mat-option>
        <mat-option *ngIf="!(objectTypes$ | async)?.length" disabled>
          {{ 'objects.form.noEntityTypesAvailable' | transloco }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field *ngIf="selectedObjectType || selectedObject" appearance="outline" class="w-full mt-4">
      <mat-label>{{ 'objects.form.selectEntity' | transloco }}</mat-label>
      <mat-select
        [(ngModel)]="selectedObject"
        (ngModelChange)="onSelectObject($event)"
        [compareWith]="compareObjWithFunction"
        [disabled]="disabled"
      >
        <mat-option *ngIf="allowAdhoc" [value]="0">{{ 'objects.form.addBeneficiary' | transloco }}</mat-option>
        <mat-option
          *ngFor="let object of (objects$ | async); trackBy: trackById"
          [value]="object"
          [disabled]="selectedIds.includes(object.id) || object.id === entityId"
        >
          {{ object.name }}
        </mat-option>
        <mat-option *ngIf="!(objects$ | async)?.length" disabled>
          {{ 'objects.form.noEntityAvailable' | transloco }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div *ngIf="!onChangeOnly" class="w-full flex justify-end items-center mt-4 px-4">
      <button mat-raised-button color="primary" (click)="onSave()" [disabled]="disabled">
        {{ 'objects.form.saveButton' | transloco }}
      </button>
    </div>
  </div>
</ng-container>

<ng-template #displayForm>
  <form [formGroup]="form" (ngSubmit)="saveObject()" class="flex flex-col gap-4">
    <ng-container *ngIf="currentObjectType === 1">
      <mat-form-field appearance="outline" class="w-full">
        <mat-label>{{ 'objects.form.carFields.make' | transloco }}</mat-label>
        <input matInput formControlName="make" />
      </mat-form-field>
      <mat-form-field appearance="outline" class="w-full">
        <mat-label>{{ 'objects.form.carFields.model' | transloco }}</mat-label>
        <input matInput formControlName="model" />
      </mat-form-field>
      <mat-form-field appearance="outline" class="w-full">
        <mat-label>{{ 'objects.form.carFields.year' | transloco }}</mat-label>
        <input matInput formControlName="year" type="number" />
      </mat-form-field>
      <mat-form-field appearance="outline" class="w-full">
        <mat-label>{{ 'objects.form.carFields.vin' | transloco }}</mat-label>
        <input matInput formControlName="vin" />
      </mat-form-field>
      <mat-form-field appearance="outline" class="w-full">
        <mat-label>{{ 'objects.form.carFields.color' | transloco }}</mat-label>
        <input matInput formControlName="color" />
      </mat-form-field>
      <mat-form-field appearance="outline" class="w-full">
        <mat-label>{{ 'objects.form.carFields.engineSize' | transloco }}</mat-label>
        <input matInput formControlName="engine_size" />
      </mat-form-field>
      <mat-form-field appearance="outline" class="w-full">
        <mat-label>{{ 'objects.form.carFields.registrationNumber' | transloco }}</mat-label>
        <input matInput formControlName="registration_number" />
      </mat-form-field>
    </ng-container>
    <ng-container *ngIf="currentObjectType === 2">
      <mat-form-field appearance="outline" class="w-full">
        <mat-label>{{ 'objects.form.realEstateFields.address' | transloco }}</mat-label>
        <input matInput formControlName="address" />
      </mat-form-field>
      <mat-form-field appearance="outline" class="w-full">
        <mat-label>{{ 'objects.form.realEstateFields.sizeInSqft' | transloco }}</mat-label>
        <input matInput formControlName="size_in_sqft" type="number" />
      </mat-form-field>
      <mat-form-field appearance="outline" class="w-full">
        <mat-label>{{ 'objects.form.realEstateFields.numberOfRooms' | transloco }}</mat-label>
        <input matInput formControlName="number_of_rooms" type="number" />
      </mat-form-field>
      <mat-form-field appearance="outline" class="w-full">
        <mat-label>{{ 'objects.form.realEstateFields.yearBuilt' | transloco }}</mat-label>
        <input matInput formControlName="year_built" type="number" />
      </mat-form-field>
      <mat-form-field appearance="outline" class="w-full">
        <mat-label>{{ 'objects.form.realEstateFields.architect' | transloco }}</mat-label>
        <input matInput formControlName="architect" />
      </mat-form-field>
      <mat-checkbox formControlName="has_fire_alarm">
        {{ 'objects.form.realEstateFields.hasFireAlarm' | transloco }}
      </mat-checkbox>
      <mat-checkbox formControlName="has_security_system">
        {{ 'objects.form.realEstateFields.hasSecuritySystem' | transloco }}
      </mat-checkbox>
    </ng-container>
    <ng-container *ngIf="currentObjectType === 3">
      <!-- Company Fields -->
      <mat-form-field appearance="outline" class="w-full">
          <mat-label>{{ 'objects.form.companyFields.companyName' | transloco }}</mat-label>
          <input matInput formControlName="company_name" />
      </mat-form-field>
  
      <mat-form-field appearance="outline" class="w-full">
          <mat-label>{{ 'objects.form.companyFields.address' | transloco }}</mat-label>
          <input matInput formControlName="address" />
      </mat-form-field>
  
      <mat-form-field appearance="outline" class="w-full">
          <mat-label>{{ 'objects.form.companyFields.legalEntityType' | transloco }}</mat-label>
          <input matInput formControlName="legal_entity_type" />
      </mat-form-field>
  
      <!-- Optional Fields -->
      <mat-form-field appearance="outline" class="w-full">
          <mat-label>{{ 'objects.form.companyFields.foundingYear' | transloco }}</mat-label>
          <input matInput formControlName="founding_year" type="number" />
      </mat-form-field>
  
      <mat-form-field appearance="outline" class="w-full">
          <mat-label>{{ 'objects.form.companyFields.businessDescription' | transloco }}</mat-label>
          <textarea matInput rows="3" formControlName="business_description" ></textarea>
      </mat-form-field>
  
      <!-- File Upload for Logo -->
      <div class="w-full flex-col justify-start items-start">
          <div class="text-secondary px-2 mb-2">{{ 'objects.form.companyFields.logo' | transloco }}</div>
          <input type="file" (change)="uploadLogo($event)" class="p-2 border rounded" />
      </div>
    </ng-container>

    <div class="flex justify-end gap-2 mt-4">
      <button mat-raised-button color="warn" (click)="closeForm()">
        {{ 'objects.form.cancelButton' | transloco }}
      </button>
      <button mat-raised-button color="primary" type="submit" [disabled]="form.invalid">
        {{ 'objects.form.saveButton' | transloco }}
      </button>
    </div>
  </form>
</ng-template>