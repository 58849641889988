import {
    Component,
    Input,
    Output,
    OnInit,
    OnDestroy,
    EventEmitter,
    SimpleChanges,
    ChangeDetectorRef,
    ChangeDetectionStrategy,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatOptionModule } from '@angular/material/core';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { Observable, Subject } from 'rxjs';
import { takeUntil, map, tap } from 'rxjs/operators';

import { InsuranceProductExpandedView } from 'app/common/supabase-models/insurance-product-expanded-view';
import { InsuranceProductExpandedViewService } from 'app/common/supabase-services/insurance-product-expanded-view.service';

@Component({
    selector: 'app-select-grouped-product',
    templateUrl: './select-grouped-product.component.html',
    styleUrls: ['./select-grouped-product.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        MatFormFieldModule,
        MatSelectModule,
        MatOptionModule,
        TranslocoModule,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectGroupedProductComponent implements OnInit, OnDestroy {
    @Input() insurerId: number | null = null; // Insurer ID for filtering products
    @Input() productId: number | null = null; // Pre-selected product ID
    @Input() label: string = 'Select Insurance Product'; // Dropdown label
    @Input() disabled: boolean = false; // Disable interaction
    @Output() onSelectProductChange = new EventEmitter<InsuranceProductExpandedView>(); // Emits selected product

    private ngUnsubscribe = new Subject<void>();
    private isInitialized = false;
    selectedLanguage: string;
    groupedProducts$: Observable<{ [key: string]: InsuranceProductExpandedView[] }>; // Grouped products
    selectedProduct: InsuranceProductExpandedView | null = null; // Selected product

    constructor(
        private translocoService: TranslocoService,
        private changeDetectorRef: ChangeDetectorRef,
        private productExpandedViewService: InsuranceProductExpandedViewService
    ) {}

    ngOnInit() {
        // Subscribe to language changes
        this.translocoService.langChanges$
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((lang) => {
                this.selectedLanguage = lang;
                this.loadProducts();
                this.changeDetectorRef.detectChanges();
            });
    }

    loadProducts() {
        this.groupedProducts$ = this.productExpandedViewService
            .getExpandedInsuranceProductsByInsurer(this.insurerId)
            .pipe(
                map((products) => {
                    const grouped = products.reduce((groups, prod) => {
                        const groupKey = prod[`product_type_name_${this.selectedLanguage}`] || prod.product_type_default_name;
                        if (!groups[groupKey]) {
                            groups[groupKey] = [];
                        }
                        groups[groupKey].push(prod);
                        return groups;
                    }, {} as { [key: string]: InsuranceProductExpandedView[] });

                    // Set `selectedProduct` **only on init**
                    if (!this.isInitialized && this.productId) {
                        const selected = products.find(
                            (prod) => prod.insurance_product_id === this.productId
                        );
                        if (selected) {
                            this.selectedProduct = selected;
                            this.onSelectProductChange.emit(this.selectedProduct);
                        }
                        this.isInitialized = true; // Prevents future updates
                    }

                    return grouped;
                }),
                tap(() => this.changeDetectorRef.markForCheck())
            );
    }

    onSelectProduct(selectedProduct: InsuranceProductExpandedView) {
        this.selectedProduct = selectedProduct;
        this.onSelectProductChange.emit(this.selectedProduct);
    }

    isEmpty(
        groupedProducts: { [key: number]: InsuranceProductExpandedView[] } | null
    ): boolean {
        if (!groupedProducts) return true;
        return Object.values(groupedProducts).every((group) => group.length === 0);
    }

    compareProdWithFunction(
        item1: InsuranceProductExpandedView,
        item2: InsuranceProductExpandedView
    ) {
        return item1 && item2
            ? item1.insurance_product_id === item2.insurance_product_id
            : item1 === item2;
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}
