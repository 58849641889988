import { Timestamp } from '@angular/fire/firestore';

export type EmailNotification = {
    email: string;
    title: string;
    description: string;
    urlLink: string;
    btnLabel: string;
};

export type User = {
    uid: string;
    displayName: string;
    email: string;
    photoURL: string;
};

export type CollectionType =
    | 'policy-extractions'
    | 'insurance-retrievals'
    | 'insurances'
    | 'insurers'
    | 'people-and-objects'
    | 'products';

export const CollectionTypeMapping = {
    'policy-extractions': 'Policy Extraction',
    'insurance-retrievals': 'Policy Request',
    insurances: 'Insurance',
    insurers: 'Insurer',
    'people-and-objects': 'People & Object',
    products: 'Product',
} as const;

export type ActionType =
    | 'created'
    | 'updated'
    | 'deleted'
    | 'request-to-extraction'
    | 'insurance-unpublished'
    | 'insurance-published'
    | 'insurance-updated'
    | 'insurance-deleted'
    | 'beneficiary-added'
    | 'beneficiary-deleted';
export type Status = 'unpublished' | 'published';
export type LangCode = 'DE' | 'EN' | 'IT' | 'FR';

export type CommentLog = {
    type: ActionType;
    id: string; // comment id
    description: string; // comment description
    prevDescription?: string; // previous comment description
};

export type Log = {
    propertyName: string;
    previousValue: string;
    newValue: string;
};

export interface Event {
    id?: string;
    name?: string;
    status: Status;
    recordId: string;
    recordOwnerId: string;
    collectionType: CollectionType;
    actionType: ActionType;
    read?: boolean;
    showOnNotification?: boolean;
    logs: Log[];
    commentLog?: CommentLog;
    language?: LangCode;
    createdBy: User;
    createdAt: Timestamp | Date;
}
