import {
    Component,
    Input,
    Output,
    OnInit,
    OnChanges,
    EventEmitter,
    SimpleChanges,
    ChangeDetectorRef,
    ChangeDetectionStrategy,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoService, TranslocoModule } from '@ngneat/transloco';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { Observable, of } from 'rxjs';

import { ObjectFormComponent } from 'app/modules/admin/apps/people-objects/object-form/object-form.component';
import { PeopleAndObjectService } from 'app/common/supabase-services/people-and-object.service';
import { PeopleAndObject } from 'app/common/supabase-models/people-and-object';

@Component({
    selector: 'app-select-people-objects',
    templateUrl: './select-people-objects.component.html',
    styleUrls: ['./select-people-objects.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        MatSelectModule,
        MatButtonModule,
        TranslocoModule,
        MatFormFieldModule,
        ObjectFormComponent,
    ],
})
export class SelectPeopleObjectsComponent implements OnInit, OnChanges {
    @Input() selectedObject: PeopleAndObject;
    @Input() ownerId: string;
    @Input() selectedIds: number[] = [];
    @Input() onChangeOnly: boolean = false;
    @Input() label: string = 'Select an option';
    @Input() btnLabel: string = 'Save';
    @Output() onChanges = new EventEmitter<PeopleAndObject>();
    @Output() adhocToggle = new EventEmitter<boolean>();
    @Output() onClickSave = new EventEmitter<PeopleAndObject>();
    peopleAndObjects$: Observable<PeopleAndObject[]>;
    toggleObjectForm: boolean = false;

    constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private peopleAndObjectService: PeopleAndObjectService
    ) {}

    ngOnInit() {
        this.loadPeopleAndObjects();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.ownerId) {
            this.loadPeopleAndObjects();
        }
    }

    loadPeopleAndObjects() {
        if (this.ownerId) {
            this.peopleAndObjects$ = this.peopleAndObjectService.getAllByOwnerId(
                this.ownerId
            );
        } else {
            this.peopleAndObjects$ = of([]);
        }
    }

    closeObject(object?: PeopleAndObject) {
        if (object) {
            this.selectedObject = object;
            this.onValueChange();
        }
        this.onAdhocToggle();
        this.loadPeopleAndObjects();
        this.changeDetectorRef.detectChanges();
    }

    onSelect(selectedValue: PeopleAndObject | number): void {
        if (typeof selectedValue !== 'number') {
            this.selectedObject = selectedValue;
            this.onValueChange();
        } else {
            if (selectedValue === 0) {
                this.onAdhocToggle();
            }
        }
    }

    onAdhocToggle() {
        this.toggleObjectForm = !this.toggleObjectForm;
        this.adhocToggle.emit(this.toggleObjectForm);
    }

    onValueChange() {
        if (this.onChangeOnly) {
            this.onChanges.emit(this.selectedObject);
        }
    }

    onSave() {
        this.onClickSave.emit(this.selectedObject);
    }

    compareWithFunction(item1: PeopleAndObject, item2: PeopleAndObject) {
        return item1 && item2 ? item1.id === item2.id : item1 === item2;
    }

    trackById(index, item: PeopleAndObject) {
        return item.id;
    }
}
