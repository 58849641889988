import { TextFieldModule } from '@angular/cdk/text-field';
import { AsyncPipe, NgClass, NgFor, NgIf } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Inject,
    Input,
    OnInit,
    Optional,
    Output,
    ViewEncapsulation,
} from '@angular/core';
import {
    FormsModule,
    ReactiveFormsModule,
    FormGroup,
    FormControl,
    FormArray,
    Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import {
    MAT_DIALOG_DATA,
    MatDialogModule,
    MatDialogRef,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { TranslocoService, TranslocoModule } from '@ngneat/transloco';
import { InsurerService } from 'app/common/supabase-services/insurer.service';
import { InsurerLocalizationService } from 'app/common/supabase-services/insurer-localization.service';
import { SupabaseStorageService } from 'app/common/supabase-services/supabase-storage.service';
import { InsurerWithLocalizationsJson } from 'app/common/supabase-models/insurer-with-localizations-json';
import { InsurerLocalization } from 'app/common/supabase-models/insurer-localization';
import { LanguageCodes } from 'app/models/common.model';

@Component({
    selector: 'insurer-details',
    templateUrl: './insurer-details.component.html',
    styleUrls: ['./insurer-details.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgIf,
        MatButtonModule,
        MatIconModule,
        FormsModule,
        TextFieldModule,
        NgFor,
        NgClass,
        MatRippleModule,
        MatDialogModule,
        AsyncPipe,
        MatInputModule,
        MatFormFieldModule,
        ReactiveFormsModule,
        MatSelectModule,
        MatSnackBarModule,
        TranslocoModule,
    ],
})
export class InsurerDetailsComponent implements OnInit {
    @Input() insurer: InsurerWithLocalizationsJson | null = null;
    @Output() closeDetails: EventEmitter<any> = new EventEmitter();

    languageCodes = Object.values(LanguageCodes);
    form: FormGroup | undefined;
    processing: boolean = false;

    constructor(
        @Optional() private dialogRef: MatDialogRef<InsurerDetailsComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: InsurerWithLocalizationsJson,
        private _insurerService: InsurerService,
        private _insurerLocalizationService: InsurerLocalizationService,
        private _supabaseStorageService: SupabaseStorageService,
        private _snackbar: MatSnackBar,
        public translocoService: TranslocoService
    ) {}

    ngOnInit(): void {
        this.insurer = this.data || this.insurer;
        this.composeForm(this.insurer);
    }

    composeForm(data: InsurerWithLocalizationsJson | undefined): void {
        this.form = new FormGroup({
            insurer_id: new FormControl(data?.insurer_id || ''),
            default_name: new FormControl(data?.insurer_name || ''),
            is_active: new FormControl(data?.is_active || true),
            localizations: new FormArray([]),
        });

        this.languageCodes.forEach((lang) => {
            const langCode = lang.toLowerCase();
            const localizationData = data?.localizations[langCode] || { localization_id: null, name: '', logo: '' };
            this.addLanguage({
                localization_id: localizationData.localization_id,
                language: langCode,
                name: localizationData.name || '',
                logo: localizationData.logo || '',
                preview: localizationData.logo || '',
                file: '',
            });
        });
    }

    get languages(): FormArray {
        return this.form?.controls['localizations'] as FormArray;
    }

    addLanguage(data: any): void {
        this.languages.push(
            new FormGroup({
                localization_id: new FormControl(data.localization_id || null),
                language: new FormControl(data.language, Validators.required),
                name: new FormControl(data.name, Validators.required),
                logo: new FormControl(data.logo),
                preview: new FormControl(data.preview),
                file: new FormControl(''),
            })
        );
    }

    patchLogo(langFormGroup: FormGroup, event: any): void {
        const file = (event.target as HTMLInputElement)?.files?.[0];
        const src = URL.createObjectURL(file);

        if (file) {
            langFormGroup.controls['preview'].patchValue(src);
            langFormGroup.controls['file'].patchValue(file);
        }
    }

    async save(event: Event): Promise<void> {
        event.preventDefault();
        
        if (!this.form?.valid) {
            return;
        }
    
        this.processing = true;
    
        try {
            const formValue = this.form.value;
    
            // Set default_name and default_logo based on the 'en' localization
            const enLocalization = formValue.localizations.find((loc: any) => loc.language === 'en');
            const insurerData = {
                default_name: enLocalization ? enLocalization.name : '',
                is_active: formValue.is_active,
                default_logo: enLocalization ? enLocalization.logo : '', // Placeholder, will be updated after logo upload if file exists
            };
    
            // Determine if this is a create or update operation based on insurer_id
            const isUpdate = !!formValue.insurer_id;
            let savedInsurer;
    
            if (isUpdate) {
                // Update insurer
                savedInsurer = await this._insurerService.update(formValue.insurer_id, insurerData).toPromise();
            } else {
                // Create insurer
                savedInsurer = await this._insurerService.create(insurerData).toPromise();
            }
    
            // Handle localization uploads and saving
            const localizationPromises = formValue.localizations.map(async (loc: any) => {
                const localization: InsurerLocalization = {
                    insurer_id: savedInsurer.id,
                    language_code: loc.language,
                    name: loc.name,
                    logo: loc.logo,
                };
    
                if (loc.file) {
                    const uniqueFileName = `logos/${savedInsurer.id}_${loc.language}_${Date.now()}_${loc.file.name}`;
                    await this._supabaseStorageService.uploadFile('insurers', uniqueFileName, loc.file).toPromise();
                    localization.logo = this._supabaseStorageService.getPublicUrl('insurers', uniqueFileName);
    
                    // Set default_logo for insurer if `en` localization
                    if (loc.language === 'en') {
                        insurerData.default_logo = localization.logo;
                        await this._insurerService.update(savedInsurer.id, { default_logo: localization.logo }).toPromise();
                    }
                }
    
                if (loc.localization_id) {
                    // Update existing localization
                    return this._insurerLocalizationService.update(loc.localization_id, localization).toPromise();
                } else {
                    // Create new localization
                    return this._insurerLocalizationService.create(localization).toPromise();
                }
            });
    
            await Promise.all(localizationPromises);
    
            const savedMessage = this.translocoService.translate('insurers.insurerSaved');
            this._snackbar.open(savedMessage, 'Success', { duration: 3000 });
    
            if (this.dialogRef) {
                this.dialogRef.close(savedInsurer);
            } else {
                this.closeDetails.emit(true);
            }
        } catch (error) {
            console.error('Failed to save insurer or localizations:', error);
            this._snackbar.open('Failed to save insurer', 'Error', { duration: 3000 });
        } finally {
            this.processing = false;
        }
    }

    cancel(): void {
        if (this.dialogRef) {
            this.dialogRef.close();
        }
        this.closeDetails.emit();
    }
}
