import {
    Component,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    ViewEncapsulation,
    ViewChild,
    OnDestroy,
    Optional,
    OnInit,
    Inject,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBarModule, MatSnackBar } from '@angular/material/snack-bar';
import { MatDrawer, MatSidenavModule } from '@angular/material/sidenav';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { TranslocoService, TranslocoModule } from '@ngneat/transloco';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { fuseAnimations } from '@fuse/animations';
import {
    MAT_DIALOG_DATA,
    MatDialog,
    MatDialogModule,
    MatDialogRef,
} from '@angular/material/dialog';
import { Subject, takeUntil, tap, Subscription, firstValueFrom } from 'rxjs';
import cloneDeep from 'lodash-es/cloneDeep';

import { InsuranceDetailsComponent } from 'app/modules/admin/apps/ecommerce/inventory/insurance-details/insurance-details.component';
import { DocumentExtractionFormComponent } from 'app/common/components/document-extraction-form/document-extraction-form.component';
import { SelectPeopleObjectsComponent } from 'app/common/components/select-people-objects/select-people-objects.component';
import { ContractDataFormComponent } from 'app/common/components/contract-data-form/contract-data-form.component';
import { LoadingOverlayComponent } from 'app/common/components/loading-overlay/loading-overlay.component';
import { PdfViewComponent } from 'app/common/components/pdf-view/pdf-view.component';
import { SwissLuxonDateAdapter } from 'app/common/services/swiss-luxondateadapter';

import { generateEmailNotificationData } from 'app/core/helpers/email-notification-helper';
import { SwissDateFormatPipe } from 'app/common/pipes/swiss-date-format';
import { EventMessagePipe } from 'app/common/pipes/event-message.pipe';
import { StripHtmlPipe } from 'app/common/pipes/strip-html.pipe';
import { ToDatePipe } from 'app/common/pipes/to-date.pipe';

import { PolicyExtractionViewService } from 'app/common/supabase-services/policy-extraction-view.service';
import { InsuranceGroupedViewService } from 'app/common/supabase-services/insurance-grouped-view.service';
import { PolicyExtractionService } from 'app/common/supabase-services/policy-extraction.service';
import { InsuranceService } from 'app/common/supabase-services/insurance.service';
import { EventLogService } from 'app/common/supabase-services/event-log.service';
import { AdminUserService } from 'app/common/services/admin-user.service';

import {
    PolicyExtraction,
    Beneficiary,
    ContractData,
} from 'app/common/supabase-models/policy-extraction';
import { PolicyExtractionView } from 'app/common/supabase-models/policy-extraction-view';
import { InsuranceGroupedView } from 'app/common/supabase-models/insurance-grouped-view';
import { PeopleAndObject } from 'app/common/supabase-models/people-and-object';
import { Insurance } from 'app/common/supabase-models/insurance';
import { EventLog } from 'app/common/supabase-models/event-log';
import { LangCode } from 'app/common/supabase-models/common';

type ExtendedInsurance = Insurance & { isNewInsurance?: boolean };

@Component({
    selector: 'app-policy-extraction-task',
    templateUrl: './policy-extraction-task.component.html',
    styleUrls: ['./policy-extraction-task.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: fuseAnimations,
    standalone: true,
    imports: [
        CommonModule,
        MatIconModule,
        MatButtonModule,
        TranslocoModule,
        MatDialogModule,
        MatSidenavModule,
        MatTooltipModule,
        MatSnackBarModule,
        MatProgressBarModule,
        // component imports
        DocumentExtractionFormComponent,
        SelectPeopleObjectsComponent,
        ContractDataFormComponent,
        InsuranceDetailsComponent,
        SwissDateFormatPipe,
        EventMessagePipe,
        PdfViewComponent,
        StripHtmlPipe,
        ToDatePipe,
    ],
})
export class PolicyExtractionTaskComponent implements OnInit, OnDestroy {
    @ViewChild('matDrawer', { static: false }) matDrawer: MatDrawer;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    private subscription = new Subscription();
    private languageSubscription: Subscription;
    insurancesGroupedByBeneficiary: {
        [beneficiaryId: string]: {
            insurances: InsuranceGroupedView[];
            insuranceIds: number[];
            pendingCount: number;
        };
    } = {};
    objectInsurance: ExtendedInsurance | null = null;
    method: 'create' | 'duplicate' | 'update' = 'create';
    matDrawerContext: string = '';
    insurances: InsuranceGroupedView[] | any[] = [];
    events: EventLog[] = [];
    document: PolicyExtractionView = null;
    canMarkAsComplete: boolean = false;
    eventsLoading: boolean = false;
    insuranceLoading: boolean = false;
    isLoading: boolean = false;
    currentLanguage: LangCode;
    mode: string = null;

    constructor(
        @Optional() private dialogRef: MatDialogRef<PolicyExtractionTaskComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: PolicyExtractionView,
        private router: Router,
        private matDialog: MatDialog,
        private snackbar: MatSnackBar,
        private activatedRoute: ActivatedRoute,
        private adminUserService: AdminUserService,
        private translocoService: TranslocoService,
        private changeDetectorRef: ChangeDetectorRef,
        private swissLuxonDateAdapter: SwissLuxonDateAdapter,
        private policyExtractionViewService: PolicyExtractionViewService,
        private insuranceGroupedViewService: InsuranceGroupedViewService,
        private policyExtractionService: PolicyExtractionService,
        private insuranceService: InsuranceService,
        private eventLogService: EventLogService
    ) {
        this.languageSubscription = this.translocoService.langChanges$.subscribe(
            (lang: string) => {
                this.currentLanguage = lang.toLowerCase() as LangCode;
            }
        );
    }

    ngOnInit(): void {
        const id = this.activatedRoute.snapshot.paramMap.get('id');
        if (id) {
            this.fetchPolicyExtractionDetails(parseInt(id));
            this.fetchInsurancesByDocumentId(parseInt(id));
        }
    }

    private fetchPolicyExtractionDetails(id: number): void {
        this.isLoading = true;
        const dataSubscription = this.policyExtractionViewService
            .getPolicyExtractionById(id)
            .subscribe({
                next: (data: PolicyExtractionView) => {
                    this.document = data;
                    this.mode = this.document ? 'edit' : 'create';
                    this.fetchEvents(data.policy_extraction_id, data.user_id);
                    this.isLoading = false;
                    this.changeDetectorRef.markForCheck();
                },
                error: () => {
                    this.isLoading = false;
                    console.error('Error fetching policy extraction details');
                },
            });
        this.subscription.add(dataSubscription);
    }

    private fetchInsurancesByDocumentId(extractionId: number): void {
        this.insuranceGroupedViewService
            .getInsuranceGroupedByExtractionId(extractionId)
            .pipe(
                takeUntil(this._unsubscribeAll),
                tap((insurances) => {
                    this.insurances = insurances;
                    // Check if there are any insurances to enable "Mark as Complete"
                    this.canMarkAsComplete = Boolean(insurances.length);
                    // Group the insurances by beneficiary for further processing/display
                    this.groupInsurancesByBeneficiary(this.insurances);
                    // Trigger change detection to update the view
                    this.changeDetectorRef.markForCheck();
                })
            )
            .subscribe();
    }

    private groupInsurancesByBeneficiary(insurances: InsuranceGroupedView[]): void {
        const grouped = insurances.reduce((accumulator, insurance) => {
            const beneficiaryId = insurance.holder_id;
            if (!accumulator[beneficiaryId]) {
                accumulator[beneficiaryId] = {
                    insurances: [],
                    insuranceIds: [],
                    pendingCount: 0,
                };
            }
            accumulator[beneficiaryId].insurances.push(insurance);
            accumulator[beneficiaryId].insuranceIds.push(insurance.insurance_id);
            if (insurance.captured_status === 'pending') {
                accumulator[beneficiaryId].pendingCount++;
            }

            return accumulator;
        }, {});

        this.insurancesGroupedByBeneficiary = grouped;
        this.changeDetectorRef.markForCheck();
    }

    private fetchEvents(recordId: number, ownerId: string): void {
        this.eventsLoading = true;
        const eventsSubscription = this.eventLogService
            .getEventsByRecordIdAndOwnerId(recordId, ownerId)
            .subscribe({
                next: (events) => {
                    // Map the events and set the current language if necessary
                    this.events = events.map((event: EventLog) => ({
                        ...event,
                        language: this.currentLanguage,
                    }));
                    this.eventsLoading = false;
                    this.changeDetectorRef.markForCheck();
                },
                error: (error) => {
                    console.error('Error fetching events:', error);
                    this.eventsLoading = false;
                    // Handle error
                },
            });
        this.subscription.add(eventsSubscription);
    }

    markAsCompleted() {
        const loadingOverlayData = {
            isLoading: true,
            loadingText: 'Creating Insurances',
            successText: 'Insurances Created',
        };
        const loadingOverlayRef = this.matDialog.open(LoadingOverlayComponent, {
            data: loadingOverlayData,
            disableClose: true,
        });
        this.policyExtractionService
            .update(this.document.policy_extraction_id, { status: 'completed' })
            .subscribe({
                next: () => {
                    loadingOverlayRef.componentInstance.data = {
                        ...loadingOverlayData,
                        isLoading: false,
                    };
                    this.navigateToTasks();
                },
                error: (error) => {
                    console.error('Error marking policy extraction as complete:', error);
                    loadingOverlayRef.componentInstance.data = {
                        ...loadingOverlayData,
                        isLoading: false,
                    };
                },
            });
    }

    delete(): void {
        if (this.document) {
            this.isLoading = true;
            this.policyExtractionService
                .delete(this.document.policy_extraction_id)
                .subscribe({
                    next: () => {
                        this.snackbar.open('Policy Extraction deleted.', 'Close', {
                            duration: 3000,
                        });
                        this.isLoading = false;
                        this.navigateToTasks();
                    },
                    error: (e) => {
                        console.error('Error deleting policy extraction:', e.message);
                        this.isLoading = false;
                    },
                });
        }
    }

    get unpublishEventIds(): number[] {
        return this.events
            .filter((event: EventLog) => !event.status || event.status === 'unpublished')
            .map((event: EventLog) => event.id as number);
    }

    updateSelectedEventsStatus(
        ids: number[],
        newStatus: 'unpublished' | 'published'
    ): void {
        this.eventsLoading = true;
        this.eventLogService.updateEventStatus(ids, newStatus).subscribe({
            next: () => {
                this.events = this.events.map(event => {
                    if (ids.includes(event.id)) {
                        return { ...event, status: newStatus };
                    }
                    return event;
                });
                this.eventsLoading = false;
                this.changeDetectorRef.markForCheck();
            },
            error: (error) => {
                console.error('Failed to update event status:', error);
                this.eventsLoading = false;
                this.changeDetectorRef.markForCheck();
            },
        });
    }

    publishAllEvent() {
        const unpublishIds: number[] = this.unpublishEventIds;
        const newStatus: 'published' = 'published';
        this.updateSelectedEventsStatus(unpublishIds, newStatus);
    }

    updateEventStatus(event: EventLog) {
        let newStatus: 'unpublished' | 'published' = event?.status || 'unpublished';
        if (newStatus === 'unpublished') {
            newStatus = 'published';
        } else {
            newStatus = 'unpublished';
        }
        this.updateSelectedEventsStatus([event.id], newStatus);
    }

    get beneficiaryIds(): number[] {
        if (!this.document) {
            return [];
        } else {
            return (
                this.document?.beneficiary_details.map((beneficiary) => beneficiary.id) ||
                []
            );
        }
    }

    async updateDocumentBeneficiaries(beneficiaryIds: number[]): Promise<void> {
        if (!this.document || !this.document.policy_extraction_id) {
            console.error('Document or policy extraction ID is missing.');
            return;
        }

        this.isLoading = true;
        try {
            // Update the `beneficiaries` field in the policy extraction
            await this.policyExtractionService
                .update(this.document.policy_extraction_id, {
                    beneficiaries: beneficiaryIds,
                })
                .toPromise();

            this.changeDetectorRef.markForCheck();
        } catch (error) {
            console.error('Error updating document beneficiaries:', error);
            this.changeDetectorRef.markForCheck();
        } finally {
            this.isLoading = false;
        }
    }

    async addBeneficiary(object: PeopleAndObject): Promise<void> {
        const beneficiaryIds = [...this.beneficiaryIds, object.id];
        this.isLoading = true;
        try {
            await this.updateDocumentBeneficiaries(beneficiaryIds);
            const beneficiaries = cloneDeep([
                ...(this.document?.beneficiary_details || []),
                {
                    id: object.id,
                    full_name: object.full_name,
                    date_of_birth: object.date_of_birth,
                },
            ]);
            const name = `${object.full_name} ${this.swissLuxonDateAdapter.toSwissFormat(
                object.date_of_birth
            )}`;

            await this.eventLogService
                .createEvent(
                    this.document.policy_extraction_id,
                    this.document.user_id,
                    'policy-extractions',
                    'beneficiary-added',
                    name
                )
                .toPromise();

            // Process sending email notification to user
            const emailNotificationData = generateEmailNotificationData(
                'new-insurance-beneficiary',
                this.document.user_email,
                name,
                this.document.policy_extraction_id.toString(),
                this.currentLanguage || 'en'
            );
            if (emailNotificationData) {
                // Send the email notification
                this.adminUserService
                    .sendEmailNotification(emailNotificationData)
                    .subscribe({
                        next: (response) =>
                            console.log('Email notification sent successfully', response),
                        error: (error) =>
                            console.error('Failed to send email notification', error),
                    });
            }

            this.document = cloneDeep({
                ...this.document,
                beneficiary_details: beneficiaries,
            }) as PolicyExtractionView;
            this.closeDrawer();
            this.isLoading = false;
            this.changeDetectorRef.markForCheck();
        } catch (error) {
            console.error('Error adding beneficiary:', error);
            this.isLoading = false;
            this.changeDetectorRef.markForCheck();
        }
    }

    async deleteBeneficiary(object: PeopleAndObject) {
        let beneficiaryIds = cloneDeep(this.beneficiaryIds);
        beneficiaryIds = beneficiaryIds.filter((id) => id !== object.id);
        const insuranceIds =
            this.insurancesGroupedByBeneficiary[object.id]?.insuranceIds || [];
        const insurances =
            this.insurancesGroupedByBeneficiary[object.id]?.insurances || [];
        this.isLoading = true;

        const updateTasks = [this.updateDocumentBeneficiaries(beneficiaryIds)];

        const name = `${object.full_name} ${this.swissLuxonDateAdapter.toSwissFormat(
            object.date_of_birth
        )}`;
        const eventBeneficiaryDeletePromise: Promise<any> = firstValueFrom(
            this.eventLogService.createEvent(
                this.document.policy_extraction_id,
                this.document.user_id,
                'policy-extractions',
                'beneficiary-deleted',
                name
            )
        );
        updateTasks.push(eventBeneficiaryDeletePromise);

        if (insuranceIds.length > 0) {
            const deleteMultiplePromise = firstValueFrom(
                this.insuranceService.deleteMultiple(insuranceIds)
            );
            updateTasks.push(deleteMultiplePromise);

            // Create an event for each insurance deletion
            const eventCreationPromises: Promise<any>[] = insurances.map(
                async (insurance) => {
                    const productName = insurance.product_default_name;
                    return firstValueFrom(
                        this.eventLogService.createEvent(
                            insurance.insurance_id,
                            insurance.creator_person_id,
                            'insurances',
                            'insurance-deleted',
                            productName
                        )
                    );
                }
            );
            updateTasks.push(...eventCreationPromises);
        }

        try {
            await Promise.all(updateTasks);
            let beneficiaries = cloneDeep(
                this.document?.beneficiary_details || []
            ) as Beneficiary[];
            beneficiaries = beneficiaries.filter(({ id }) => id !== object.id);
            this.document = cloneDeep({
                ...this.document,
                beneficiary_details: beneficiaries,
            });
            this.closeDrawer();
        } catch (error) {
            console.error('Error deleting beneficiary:', error);
        } finally {
            this.isLoading = false;
            this.changeDetectorRef.markForCheck();
        }
    }

    addBeneficiaryInsurance(beneficiary: any) {
        const name = `${beneficiary.lastName}, ${
            beneficiary.firstName
        } ${this.swissLuxonDateAdapter.toSwissFormat(beneficiary.birthday)}`;
        const emptyInsurance = cloneDeep({
            ...this.generateInsurance(),
        }) as ExtendedInsurance;
        this.objectInsurance = cloneDeep({
            ...emptyInsurance,
            isNewInsurance: true,
            health: { beneficiary: { ...beneficiary, name }, insureeNumber: '' },
        }) as ExtendedInsurance;
        this.method = 'create';
        if (this.matDrawer) {
            this.matDrawerContext = 'add-insurance';
            this.matDrawer.open();
        } else {
            console.warn('Drawer is not available');
        }
    }

    editBeneficiaryInsurance(insurance: ExtendedInsurance) {
        this.objectInsurance = cloneDeep({
            ...insurance,
            isNewInsurance: false,
        }) as ExtendedInsurance;
        this.method = 'update';
        if (this.matDrawer) {
            this.matDrawerContext = 'add-insurance';
            this.matDrawer.open();
        } else {
            console.warn('Drawer is not available');
        }
    }

    duplicateBeneficiaryInsurance(insurance: ExtendedInsurance) {
        const insuranceCopy = cloneDeep({
            ...this.generateInsurance(insurance),
        }) as ExtendedInsurance;
        this.objectInsurance = cloneDeep({
            ...insuranceCopy,
            isNewInsurance: true,
        }) as ExtendedInsurance;
        this.method = 'duplicate';
        if (this.matDrawer) {
            this.matDrawerContext = 'add-insurance';
            this.matDrawer.open();
        } else {
            console.warn('Drawer is not available');
        }
    }

    async createPublishUnpublishedEvents(
        insurances: InsuranceGroupedView[],
        newStatus: string
    ): Promise<void> {
        const eventLogs: Promise<any>[] = [];

        for (const insurance of insurances) {
            const languageProperty = `product_name_${this.currentLanguage.toLowerCase()}`;
            const productName =
                insurance[languageProperty] || insurance.product_default_name;
            const actionType =
                newStatus === 'completed'
                    ? 'insurance-published'
                    : 'insurance-unpublished';

            // Create event for 'insurances'
            eventLogs.push(
                firstValueFrom(
                    this.eventLogService.createEvent(
                        insurance.insurance_id,
                        insurance.holder_person_id,
                        'insurances',
                        actionType,
                        productName
                    )
                )
            );

            // Create event for 'policy-extractions'
            eventLogs.push(
                firstValueFrom(
                    this.eventLogService.createEvent(
                        insurance.policy_extraction_id,
                        insurance.holder_person_id,
                        'policy-extractions',
                        actionType,
                        productName,
                        false
                    )
                )
            );

            if (actionType === 'insurance-published') {
                // Process sending email notification to user
                const notificationData = generateEmailNotificationData(
                    'new-insurance',
                    this.document.user_email,
                    productName,
                    insurance.insurance_id.toString(),
                    this.currentLanguage || 'en'
                );
                if (notificationData) {
                    // Send the email notification
                    const sendNotificationResult =
                        this.adminUserService.sendEmailNotification(notificationData);
                    eventLogs.push(sendNotificationResult.toPromise());
                }
            }
        }

        await Promise.all(eventLogs);
    }

    async updateSelectedInsurancesStatus(
        ids: number[],
        newStatus: 'pending' | 'completed'
    ): Promise<void> {
        this.insuranceLoading = true;
        try {
            await firstValueFrom(
                this.insuranceService.updateCaptureStatus(ids, newStatus)
            );
        } catch (error) {
            console.error('Failed to update insurance status', error);
        } finally {
            this.insuranceLoading = false;
            this.changeDetectorRef.markForCheck();
        }
    }

    async updateInsuranceStatus(insurance: InsuranceGroupedView): Promise<void> {
        let newStatus: 'pending' | 'completed' =
            insurance?.captured_status === 'pending' ? 'completed' : 'pending';

        await this.updateSelectedInsurancesStatus([insurance.insurance_id], newStatus);
        await this.createPublishUnpublishedEvents([insurance], newStatus);
    }

    async publishInsurancesByBeneficiary(id: string): Promise<void> {
        const ids = this.insurancesGroupedByBeneficiary[id].insuranceIds;
        const insurances = this.insurancesGroupedByBeneficiary[id].insurances;
        const newStatus = 'completed';
        await this.updateSelectedInsurancesStatus(ids, newStatus);
        await this.createPublishUnpublishedEvents(insurances, newStatus);
    }

    deleteInsurance(insurance: ExtendedInsurance) {
        const loadingOverlayData = {
            isLoading: true,
            loadingText: `${
                insurance?.isNewInsurance
                    ? this.translocoService.translate('admin.docUploads.deletingDraft')
                    : this.translocoService.translate(
                          'admin.docUploads.deletingInsurance'
                      )
            }`,
            successText: `${
                insurance?.isNewInsurance
                    ? this.translocoService.translate('admin.docUploads.draftDiscarded')
                    : this.translocoService.translate('admin.docUploads.insuranceDeleted')
            }`,
        };
        const loadingOverlayRef = this.matDialog.open(LoadingOverlayComponent, {
            data: loadingOverlayData,
            disableClose: true,
        });

        firstValueFrom(this.insuranceService.delete(insurance.id))
            .then(() => {
                loadingOverlayRef.componentInstance.data = {
                    ...loadingOverlayData,
                    isLoading: false,
                };
                this.changeDetectorRef.markForCheck();
            })
            .catch((error) => {
                console.error('Failed to delete insurance:', error);
                loadingOverlayRef.componentInstance.data = {
                    ...loadingOverlayData,
                    isLoading: false,
                };
                this.changeDetectorRef.markForCheck();
            });
    }

    async updateContractData(contractData: ContractData | null): Promise<void> {
        if (!this.document || !this.document.policy_extraction_id) {
            console.error('Document or policy extraction ID is missing.');
            return;
        }

        this.isLoading = true;
        try {
            // Update the `contract_data` field in the policy extraction
            await this.policyExtractionService
                .update(this.document.policy_extraction_id, {
                    contract_data: contractData,
                })
                .toPromise();

            // Update the local document state with the new contract data
            this.document = cloneDeep({ ...this.document, contract_data: contractData });

            this.changeDetectorRef.markForCheck();
        } catch (error) {
            console.error('Failed to update contract data', error);
        } finally {
            this.isLoading = false;
            this.closeDrawer();
            this.changeDetectorRef.markForCheck();
        }
    }

    handleInsuranceFormClose(): void {
        this.closeDrawer();
        this.fetchPolicyExtractionDetails(this.document.policy_extraction_id);
        this.changeDetectorRef.markForCheck();
    }

    openDrawer(drawerType: string): void {
        if (this.matDrawer) {
            this.matDrawer.open();
            this.matDrawerContext = drawerType;
        } else {
            console.warn('Drawer is not available');
        }
    }

    closeDrawer(): void {
        if (this.matDrawer) {
            this.matDrawer.close();
            this.matDrawerContext = '';
        } else {
            console.warn('Drawer is not available');
        }
    }

    onDataUpdated(): void {
        if (this.document) {
            this.fetchEvents(
                this.document.policy_extraction_id,
                this.document.user_id
            );
        }
    }

    navigateToTasks(): void {
        this.router.navigate(['admin/tasks']);
    }

    generateInsurance(fromInsurance: Partial<Insurance> = {}): Insurance {
        // Generate a unique ID if needed, assuming Supabase generates the ID server-side
        // In this case, we don't set the `id` manually if Supabase assigns it
        const currentDate = new Date().toISOString();
    
        // Create a base insurance object with all required and optional properties set to defaults or placeholders
        const insurance: Insurance = {
            captured_status: 'pending',
            insurance_product_id: 0, // Placeholder, should be dynamically updated
            holder_person_id: '',
            creator_person_id: '',
            policy_extraction_id: 0, // Placeholder for policy extraction ID
            options_json: {},
            options_canonical: '',
            individual_contractual_overrides: '',
            policy_pdf_url: '',
            details_notes: '',
            policy_date: '',
            effective_date: '',
            expiration_date: '',
            created_at: currentDate, // Default to current timestamp
            updated_at: currentDate, // Default to current timestamp
            ...fromInsurance // Override any base values with the provided `fromInsurance` data
        };
    
        return insurance;
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
        if (this.languageSubscription) {
            this.languageSubscription.unsubscribe();
        }
    }
}
