import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn, Router } from '@angular/router';
import { AuthService } from 'app/core/auth/auth.service';
import { of, from, switchMap } from 'rxjs';

export const NoAuthGuard: CanActivateFn | CanActivateChildFn = (route, state) => {
    const router: Router = inject(Router);

    // Convert the Promise to an Observable using `from()`
    return from(inject(AuthService).check()).pipe(
        switchMap((status) => {
            // If the user is authenticated...
            if (status === 'authenticated') {
                return of(router.parseUrl(''));
            }

            // Allow the access
            return of(true);
        })
    );
};
