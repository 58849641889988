import { Injectable } from '@angular/core';
import { SupabaseClient } from '@supabase/supabase-js';
import { from, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { PeopleAndObject } from '../supabase-models/people-and-object';
import { SupabaseClientService } from './supabase-client.service';

@Injectable({
    providedIn: 'root',
})
export class PeopleAndObjectService {
    private supabase: SupabaseClient;

    constructor(private supabaseClientService: SupabaseClientService) {
        // Get the Supabase client from the SupabaseClientService
        this.supabase = this.supabaseClientService.getClient();
    }

    /**
     * Create a new record in the people_and_objects table
     * @param entity PeopleAndObject data to insert
     */
    create(entity: PeopleAndObject): Observable<PeopleAndObject> {
        return from(
            this.supabase.from('people_and_objects').insert([entity]).select()
        ).pipe(
            map((response) => {
                if (response.data && (response.data as PeopleAndObject[]).length > 0) {
                    return (response.data as PeopleAndObject[])[0] as PeopleAndObject;
                }
                throw new Error('Failed to create record in people_and_objects');
            }),
            catchError((error) => throwError(() => new Error(error.message)))
        );
    }

    /**
     * Retrieve a record by ID
     * @param id ID of the record to retrieve
     */
    getById(id: number): Observable<PeopleAndObject> {
        return from(
            this.supabase.from('people_and_objects').select('*').eq('id', id).single()
        ).pipe(
            map((response) => {
                if (response.data) {
                    return response.data as PeopleAndObject;
                }
                throw new Error('Record not found in people_and_objects');
            }),
            catchError((error) => throwError(() => new Error(error.message)))
        );
    }

    getByIds(ids: number[]): Observable<PeopleAndObject[]> {
        return from(
            this.supabase.from('people_and_objects').select('*').in('id', ids)
        ).pipe(map((response) => response.data as PeopleAndObject[]));
    }

    /**
     * Update a record by ID
     * @param id ID of the record to update
     * @param entity Updated data
     */
    update(id: number, entity: Partial<PeopleAndObject>): Observable<PeopleAndObject> {
        return from(
            this.supabase.from('people_and_objects').update(entity).eq('id', id).select()
        ).pipe(
            map((response) => {
                // Check if the update operation returned an error
                if (response.error) {
                    throw new Error(response.error.message);
                }

                // Ensure the data is treated as an array of PeopleAndObject
                const data = response.data as PeopleAndObject[] | undefined;

                // If data is available and has items, return the first record
                if (data && data.length > 0) {
                    return data[0];
                }

                // If no data is returned but no error occurred, assume the update was successful
                return entity as PeopleAndObject;
            }),
            catchError((error) => throwError(() => new Error(error.message)))
        );
    }

    /**
     * Delete a record by ID
     * @param id ID of the record to delete
     */
    delete(id: number): Observable<void> {
        return from(this.supabase.from('people_and_objects').delete().eq('id', id)).pipe(
            map(() => void 0),
            catchError((error) => throwError(() => new Error(error.message)))
        );
    }

    /**
     * Retrieve all records in the people_and_objects table
     */
    getAll(): Observable<PeopleAndObject[]> {
        return from(this.supabase.from('people_and_objects').select('*')).pipe(
            map((response) => {
                if (response.data) {
                    return response.data as PeopleAndObject[];
                }
                throw new Error('No records found in people_and_objects');
            }),
            catchError((error) => throwError(() => new Error(error.message)))
        );
    }

    /**
     * Retrieve all records of type 'person' or 'object'
     * @param type 'person' or 'object'
     */
    getAllByType(type: 'person' | 'object'): Observable<PeopleAndObject[]> {
        return from(
            this.supabase.from('people_and_objects').select('*').eq('type', type)
        ).pipe(
            map((response) => {
                if (response.data) {
                    return response.data as PeopleAndObject[];
                }
                throw new Error(`No records found for type: ${type}`);
            }),
            catchError((error) => throwError(() => new Error(error.message)))
        );
    }

    /**
     * Retrieve a linked person by owner ID
     * @param ownerId Owner ID to filter records
     */
    getLinkedPersonByOwnerId(ownerId: string): Observable<PeopleAndObject> {
        return from(
            this.supabase
                .from('people_and_objects')
                .select('*')
                .eq('owner_id', ownerId)
                .eq('type', 'person')
                .eq('account_linked', true)
                .single()
        ).pipe(
            map((response) => {
                if (response.data) {
                    return response.data as PeopleAndObject;
                }
                throw new Error('No linked person found for the specified owner ID');
            }),
            catchError((error) => throwError(() => new Error(error.message)))
        );
    }

    /**
     * Retrieve all records by owner ID
     * @param ownerId Owner ID to filter records
     */
    getAllByOwnerId(ownerId: string): Observable<PeopleAndObject[]> {
        return from(
            this.supabase.from('people_and_objects').select('*').eq('owner_id', ownerId)
        ).pipe(
            map((response) => {
                if (response.data) {
                    return response.data as PeopleAndObject[];
                }
                throw new Error(`No records found for owner ID: ${ownerId}`);
            }),
            catchError((error) => throwError(() => new Error(error.message)))
        );
    }
}
