import { Injectable } from '@angular/core';
import { SupabaseClient } from '@supabase/supabase-js';
import { SupabaseClientService } from './supabase-client.service';
import { PolicyExtraction } from '../supabase-models/policy-extraction';
import { from, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class PolicyExtractionService {
    private supabase: SupabaseClient;

    constructor(private _supabaseClientService: SupabaseClientService) {
        this.supabase = this._supabaseClientService.getClient();
    }

    // Create new policy extraction
    create(entity: PolicyExtraction): Observable<PolicyExtraction> {
        return from(this.supabase.from('policy_extraction').insert([entity]).select()).pipe(
            map((response) => {
                // Check if there's an error in the response
                if (response.error) {
                    throw new Error(response.error.message);
                }

                // Ensure the data is treated as an array of PolicyExtraction
                const data = response.data as PolicyExtraction[] | undefined;

                // If data is available and has items, return the first record
                if (data && data.length > 0) {
                    return data[0];
                }

                // If no data is returned but no error occurred, assume the insert was successful
                return entity as PolicyExtraction;
            }),
            catchError((error) => throwError(() => new Error(error.message)))
        );
    }

    // Get policy extraction by ID
    getById(id: number): Observable<PolicyExtraction> {
        return from(
            this.supabase.from('policy_extraction').select('*').eq('id', id).single()
        ).pipe(
            map((response) => {
                if (response.data) {
                    return response.data as PolicyExtraction;
                }
                throw new Error('Record not found');
            }),
            catchError((error) => throwError(() => new Error(error.message)))
        );
    }

    // Update policy extraction by ID
    update(id: number, entity: Partial<PolicyExtraction>): Observable<PolicyExtraction> {
        return from(
            this.supabase.from('policy_extraction').update(entity).eq('id', id).select()
        ).pipe(
            map((response) => {
                // Check if the update operation returned an error
                if (response.error) {
                    throw new Error(response.error.message);
                }

                // Ensure the data is treated as an array of PolicyExtraction
                const data = response.data as PolicyExtraction[] | undefined;

                // If data is available and has items, return the first record
                if (data && data.length > 0) {
                    return data[0];
                }

                // If no data is returned but no error occurred, assume the update was successful
                return entity as PolicyExtraction;
            }),
            catchError((error) => throwError(() => new Error(error.message)))
        );
    }

    // Delete policy extraction by ID
    delete(id: number): Observable<void> {
        return from(this.supabase.from('policy_extraction').delete().eq('id', id)).pipe(
            map(() => void 0),
            catchError((error) => throwError(() => new Error(error.message)))
        );
    }
}
