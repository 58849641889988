// shared-switcher.service.ts

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedSwitcherService {
  private switcherStateSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  switcherState$ = this.switcherStateSubject.asObservable();
  private readonly switcherKey = 'admin';

  // Retrieve switcher state from localStorage
  getSwitcherStateLocal(): boolean {
    const switcherState = localStorage.getItem(this.switcherKey);
    // If switcher state is not found in localStorage, default to true (admin role)
    return switcherState ? switcherState === 'true' : false;
  }

  // Save switcher state to localStorage
  setSwitcherStateLocal(isAdmin: boolean): void {
    localStorage.setItem(this.switcherKey, isAdmin.toString());
    this.switcherStateSubject.next(isAdmin);
  }

}
