<ng-container *ngIf="!showPersonForm && !showGroupForm; else displayForm">
    <div class="flex flex-col justify-center items-center">
        <mat-form-field appearance="outline" class="w-full">
            <mat-label>{{ label }}</mat-label>
            <mat-select 
                [(ngModel)]="selectedBeneficiary"
                [compareWith]="compareWithFunction"
                (ngModelChange)="onSelectBeneficiary($event)" 
                [disabled]="disabled"
            >
                
                <!-- Person Group -->
                <mat-optgroup [label]="'selectBeneficiary.person' | transloco">
                    <mat-option *ngIf="allowAdhoc" class="italic" [value]="-1">
                        {{ 'selectBeneficiary.addNewPerson' | transloco }}
                    </mat-option>
                    <mat-option 
                        *ngFor="let beneficiary of personBeneficiaries; trackBy: trackById" 
                        [value]="beneficiary"
                        [disabled]="selectedPersonIds.includes(beneficiary.data.id)"
                    >
                        {{ beneficiary.data.full_name }}
                    </mat-option>
                </mat-optgroup>
                
                <!-- Coverage Group -->
                <mat-optgroup [label]="'selectBeneficiary.coverageGroup' | transloco">
                    <mat-option *ngIf="allowAdhoc" class="italic" [value]="-2">
                        {{ 'selectBeneficiary.addNewGroup' | transloco }}
                    </mat-option>
                    <mat-option 
                        *ngFor="let beneficiary of groupBeneficiaries; trackBy: trackById" 
                        [disabled]="selectedGroupIds.includes(beneficiary.data.coverage_group_id)"
                        [value]="beneficiary"
                    >
                        {{ beneficiary.data.coverage_group_name }}
                    </mat-option>
                </mat-optgroup>
                
                <!-- No Available Options -->
                <mat-option *ngIf="!personBeneficiaries.length && !groupBeneficiaries.length && !allowAdhoc" disabled>
                    {{ 'selectBeneficiary.noAvailableOptions' | transloco }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <div *ngIf="!onChangeOnly" class="w-full flex justify-end items-center mt-4 px-4">
            <button mat-raised-button color="primary" (click)="onClickSave()" [disabled]="disabled">
              {{ btnLabel }}
            </button>
        </div>
    </div>
</ng-container>

<!-- Person and Group Forms -->
<ng-template #displayForm>
    <app-person-form
        *ngIf="showPersonForm"
        [method]="'create'"
        [person]="{ owner_id: ownerId }"
        (onSave)="onSavePersonForm($event)"
        (onCancel)="onCancelPersonForm()"
    >
    </app-person-form>

    <app-coverage-group-form
        *ngIf="showGroupForm"
        [method]="'create'"
        [ownerId]="ownerId"
        (onSave)="closeGroup($event)"
        (onCancel)="closeGroup($event)"
    >
    </app-coverage-group-form>
</ng-template>
