import {
    Component,
    ViewChild,
    EventEmitter,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Input,
    OnInit,
    Output,
    OnChanges,
    SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormControl } from '@angular/forms';
import { NgSelectModule, NgSelectComponent } from '@ng-select/ng-select';
import { PersonWithSetting } from '../../supabase-models/person-with-setting';

import { AdminUserService } from '../../supabase-services/admin-user.service';

@Component({
    selector: 'select-admin-user',
    templateUrl: './select-admin-user.component.html',
    styleUrls: ['./select-admin-user.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [CommonModule, NgSelectModule, ReactiveFormsModule],
})
export class SelectAdminUserComponent implements OnInit, OnChanges {
    @ViewChild('ngSelectAdminUser') ngSelectAdminUser: NgSelectComponent;
    @Input() selectedAdminUserId: string;
    @Output() onSelectAdminUserId = new EventEmitter<string>();
    adminUserControl = new FormControl();
    adminUsers: PersonWithSetting[] = [];
    isLoading = false;

    constructor(
        private adminUserService: AdminUserService,
        private changeDetectorRef: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.fetchAdminUsers();

        // Subscribe to valueChanges of the form control
        this.adminUserControl.valueChanges.subscribe((userId: string) => {
            this.onSelectAdminUserId.emit(userId);
        });
    }

    fetchAdminUsers(): void {
        this.isLoading = true;
        this.adminUserService.getAdminUsers().subscribe({
            next: (data) => {
                this.adminUsers = data;
                this.isLoading = false;
                // Set the value after loading admin users
                this.adminUserControl.setValue(this.selectedAdminUserId);
                this.changeDetectorRef.markForCheck();
            },
            error: (error) => {
                console.error('Error fetching admin users:', error);
                this.isLoading = false;
            },
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if ('selectedAdminUserId' in changes) {
            // Set the value if admin users are already loaded
            if (!this.isLoading && this.adminUsers.length > 0) {
                this.adminUserControl.setValue(this.selectedAdminUserId);
            }
        }
    }
}