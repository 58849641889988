import { Injectable } from '@angular/core';
import { SupabaseClient } from '@supabase/supabase-js';
import { from, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { UserSetting } from '../supabase-models/user-setting';
import { SupabaseClientService } from './supabase-client.service';

@Injectable({
    providedIn: 'root',
})
export class UserSettingService {
    private supabase: SupabaseClient;

    constructor(private supabaseClientService: SupabaseClientService) {
        // Get the Supabase client from the SupabaseClientService
        this.supabase = this.supabaseClientService.getClient();
    }

    /**
     * Create a new user setting in the user_settings table
     * @param setting UserSetting data to insert
     */
    createUserSetting(setting: UserSetting): Observable<UserSetting> {
        return from(this.supabase.from('user_settings').insert([setting])).pipe(
            map((response) => {
                if (response.data) {
                    return Array.isArray(response.data)
                        ? (response.data[0] as UserSetting)
                        : (response.data as UserSetting);
                }
                throw new Error('Failed to create user setting');
            }),
            catchError((error) => throwError(() => new Error(error.message)))
        );
    }

    /**
     * Retrieve user setting by user ID
     * @param id ID of the user (auth.users.id)
     */
    getUserSettingById(id: string): Observable<UserSetting> {
        return from(
            this.supabase.from('user_settings').select('*').eq('id', id).single()
        ).pipe(
            map((response) => response.data as UserSetting),
            catchError((error) => throwError(() => new Error(error.message)))
        );
    }

    /**
     * Update user setting by user ID
     * @param id ID of the user (auth.users.id)
     * @param setting Updated data
     */
    updateUserSetting(
        id: string,
        setting: Partial<UserSetting>
    ): Observable<UserSetting> {
        return from(
            this.supabase.from('user_settings').update(setting).eq('id', id)
        ).pipe(
            map((response) => {
                if (response.error) {
                    throw new Error(response.error.message);
                }

                // Ensure data is treated as an array of UserSetting or undefined
                const data = response.data as UserSetting[] | undefined;

                // If data exists, return the first record; otherwise, assume the update was successful
                if (data && data.length > 0) {
                    return data[0];
                }

                // Return the updated setting object as a fallback
                return setting as UserSetting;
            }),
            catchError((error) => throwError(() => new Error(error.message)))
        );
    }

    /**
     * Delete user setting by user ID
     * @param id ID of the user (auth.users.id)
     */
    deleteUserSetting(id: string): Observable<void> {
        return from(this.supabase.from('user_settings').delete().eq('id', id)).pipe(
            map(() => void 0),
            catchError((error) => throwError(() => new Error(error.message)))
        );
    }
}
