import {
    Component,
    OnInit,
    Input,
    Output,
    OnDestroy,
    OnChanges,
    EventEmitter,
    SimpleChanges,
    ChangeDetectorRef,
    ChangeDetectionStrategy,
} from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTabsModule, MatTabChangeEvent } from '@angular/material/tabs';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Subscription, Subject, firstValueFrom } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { SelectAdminUserComponent } from '../select-admin-user/select-admin-user.component';

import { InsuranceRetrievalService } from '../../supabase-services/insurance-retrieval.service';
import { PersonWithSettingService } from '../../supabase-services/person-with-setting.service';
import { EventLogService } from '../../supabase-services/event-log.service';
import { DeviceService } from '../../services/device.service';

import { EventMessagePipe } from '../../pipes/event-message.pipe';
import { generateUUID } from '../../../core/helpers/helper';
import { ToDatePipe } from '../../pipes/to-date.pipe';

import { PersonWithSetting as CurrentUser } from '../../supabase-models/person-with-setting';
import { InsuranceRetrievalView } from '../../supabase-models/insurance-retrieval-view';
import { Status, Urgency } from '../../supabase-models/insurance-retrieval';
import { EventLog } from '../../supabase-models/event-log';
import {
    LangCode,
    Comment,
    CommentType,
    StatusMapping,
    CommentTypeMapping,
} from '../../supabase-models/common';

@Component({
    selector: 'app-policy-request-form',
    templateUrl: './policy-request-form.component.html',
    styleUrls: ['./policy-request-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        FormsModule,
        CommonModule,
        MatIconModule,
        MatTabsModule,
        MatInputModule,
        TranslocoModule,
        MatButtonModule,
        MatSelectModule,
        MatTooltipModule,
        MatFormFieldModule,
        MatProgressSpinnerModule,
        SelectAdminUserComponent,
        EventMessagePipe,
        ToDatePipe,
    ],
})
export class PolicyRequestFormComponent implements OnInit, OnChanges, OnDestroy {
    @Input() policyRequest: InsuranceRetrievalView;
    @Input() isAdmin: boolean = true;
    @Output() closeDetails: EventEmitter<void> = new EventEmitter<void>();
    @Output() dataUpdated: EventEmitter<void> = new EventEmitter<void>();
    private languageSubscription: Subscription;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    private user: CurrentUser;
    events: EventLog[] = [];
    currentLanguage: LangCode;
    tempStatus: Status;
    tempUrgency: Urgency;
    tempAssigneeId: string;
    newComment: Comment = {
        id: '',
        user: null,
        type: 'general',
        description: '',
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString(),
    };
    editStatus: boolean = false;
    editUrgency: boolean = false;
    editAssignee: boolean = false;
    allStatusOptions: Status[] = ['pending', 'in-progress', 'completed'];
    activityOptions: CommentType[] = ['general', 'insurer-contact', 'admin-update'];
    activityTypeMapping = CommentTypeMapping;
    statusMapping = StatusMapping;
    isLoading: boolean = false;
    isMobile: boolean = false;
    activityType: CommentType = 'general';
    assigneeName: string;

    constructor(
        private deviceService: DeviceService,
        private eventLogService: EventLogService,
        private translocoService: TranslocoService,
        private changeDetectorRef: ChangeDetectorRef,
        private personWithSettingService: PersonWithSettingService,
        private insuranceRetrievalService: InsuranceRetrievalService
    ) {
        this.languageSubscription = this.translocoService.langChanges$.subscribe(
            (lang: string) => {
                this.currentLanguage = lang.slice(0, 2).toLowerCase() as LangCode;
            }
        );

        this.personWithSettingService.personWithSetting$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((user: CurrentUser) => {
                this.user = user;
            });
    }

    private fetchEvents(recordId: number, ownerId: string): void {
        this.isLoading = true;
        if (!this.isAdmin) {
            this.eventLogService
                .getEventsByRecordIdOwnerIdAndStatus(recordId, ownerId, 'published')
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe((events) => {
                    this.events = events.map((event) => ({
                        ...event,
                        language: this.currentLanguage,
                    }));
                    this.isLoading = false;
                    this.changeDetectorRef.detectChanges();
                });
        } else {
            this.eventLogService
                .getEventsByRecordIdAndOwnerId(recordId, ownerId)
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe((events) => {
                    this.events = events.map((event) => ({
                        ...event,
                        language: this.currentLanguage,
                    }));
                    this.isLoading = false;
                    this.changeDetectorRef.detectChanges();
                });
        }
    }

    private fetchComments(): void {
        this.isLoading = true;
        this.insuranceRetrievalService
            .getById(this.policyRequest.insurance_retrieval_id)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe({
                next: (retrieval) => {
                    this.policyRequest.comments = retrieval.comments || [];
                    this.sortCommentsByLatest();
                    this.isLoading = false;
                    this.changeDetectorRef.markForCheck();
                },
                error: (error) => {
                    console.error('Error fetching comments:', error);
                    this.isLoading = false;
                },
            });
    }

    onTabChange(event: MatTabChangeEvent): void {
        if (event.tab.textLabel === 'Activities') {
            // Re-fetch events when the Activities tab is selected
            if (this.policyRequest) {
                const recordId = this.policyRequest.insurance_retrieval_id;
                const ownerId = this.policyRequest.user_id;
                this.fetchEvents(recordId, ownerId);
            }
        } else if (event.tab.textLabel === 'Comments') {
            // Re-fetch comments when the Comments tab is selected
            if (this.policyRequest) {
                this.fetchComments();
            }
        }
    }

    ngOnInit() {
        this.isMobile = this.deviceService.isMobile();
        this.sortCommentsByLatest();
        if (this.policyRequest) {
            const recordId = this.policyRequest.insurance_retrieval_id;
            const ownerId = this.policyRequest.user_id;
            this.fetchEvents(recordId, ownerId);
            this.fetchAssigneeName();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.policyRequest && changes.policyRequest.currentValue) {
            const recordId = this.policyRequest.insurance_retrieval_id;
            const ownerId = this.policyRequest.user_id;
            this.fetchEvents(recordId, ownerId);
            this.fetchAssigneeName();
        }
    }

    private fetchAssigneeName(): void {
        if (this.policyRequest.assignee_id) {
            this.personWithSettingService
                .getPersonWithSettingsByUserId(this.policyRequest.assignee_id)
                .subscribe({
                    next: (assignee) => {
                        this.assigneeName = assignee.full_name;
                        this.changeDetectorRef.markForCheck();
                    },
                    error: (error) => {
                        console.error('Error fetching assignee details:', error);
                    },
                });
        } else {
            this.assigneeName = 'Unassigned';
        }
    }

    // Method to start editing the policy request's status
    startEditStatus() {
        this.tempStatus = this.policyRequest.status as Status;
        this.editStatus = true;
    }

    // Method to cancel editing the policy request's status
    cancelEditStatus() {
        this.editStatus = false;
    }

    toggleEditUrgency() {
        this.editUrgency = !this.editUrgency;
        if (this.editUrgency) {
            this.tempUrgency = (this.policyRequest?.urgency as Urgency) || 'low';
        }
    }

    toggleEditAssignee() {
        this.editAssignee = !this.editAssignee;
        if (this.editAssignee) {
            this.tempAssigneeId = this.policyRequest?.assignee_id;
        }
    }

    handleAssigneeChange(assigneeId: string) {
        this.tempAssigneeId = assigneeId;
    }

    sortCommentsByLatest(): void {
        if (this.policyRequest && this.policyRequest.comments) {
            this.policyRequest.comments.sort((a, b) => {
                const dateA = new Date(a.created_at).getTime();
                const dateB = new Date(b.created_at).getTime();
                return dateB - dateA;
            });
        }
    }

    // Method to save the updated status of the policy request
    async updateStatus() {
        if (this.tempStatus !== this.policyRequest.status) {
            this.isLoading = true;
            const previousStatus = this.policyRequest.status;
            try {
                await firstValueFrom(
                    this.insuranceRetrievalService.update(
                        this.policyRequest.insurance_retrieval_id,
                        { status: this.tempStatus }
                    )
                );
                // Update the local state
                this.policyRequest.status = this.tempStatus;
                // Create an event log for status update
                await firstValueFrom(
                    this.eventLogService.createUpdateEvent(
                        { status: previousStatus },
                        { status: this.tempStatus },
                        this.policyRequest.insurance_retrieval_id,
                        this.policyRequest.user_id,
                        'insurance-retrievals'
                    )
                );
                this.dataUpdated.emit();
                this.changeDetectorRef.markForCheck();
            } catch (error) {
                console.error('Error updating policy status:', error);
            } finally {
                this.editStatus = false;
                this.isLoading = false;
            }
        } else {
            this.editStatus = false;
        }
    }

    async updateUrgency() {
        if (this.tempUrgency !== this.policyRequest?.urgency) {
            this.isLoading = true;
            const previousUrgency = this.policyRequest.urgency;
            try {
                await firstValueFrom(
                    this.insuranceRetrievalService.update(
                        this.policyRequest.insurance_retrieval_id,
                        { urgency: this.tempUrgency }
                    )
                );
                // Update the local state
                this.policyRequest.urgency = this.tempUrgency;
                // Create an event log for urgency update
                await firstValueFrom(
                    this.eventLogService.createUpdateEvent(
                        { urgency: previousUrgency },
                        { urgency: this.tempUrgency },
                        this.policyRequest.insurance_retrieval_id,
                        this.policyRequest.user_id,
                        'insurance-retrievals'
                    )
                );
                this.dataUpdated.emit();
                this.changeDetectorRef.markForCheck();
            } catch (error) {
                console.error('Error updating policy urgency:', error);
            } finally {
                this.editUrgency = false;
                this.isLoading = false;
            }
        } else {
            this.editUrgency = false;
        }
    }

    async updateAssignee() {
        if (this.tempAssigneeId !== this.policyRequest?.assignee_id) {
            this.isLoading = true;
            const previousAssigneeId = this.policyRequest.assignee_id;
            try {
                await firstValueFrom(
                    this.insuranceRetrievalService.update(
                        this.policyRequest.insurance_retrieval_id,
                        { assignee_id: this.tempAssigneeId }
                    )
                );
                // Update the local state
                this.policyRequest.assignee_id = this.tempAssigneeId;
                // Fetch the assignee's name
                this.fetchAssigneeName();
                // Create an event log for assignee update
                await firstValueFrom(
                    this.eventLogService.createUpdateEvent(
                        { assignee_id: previousAssigneeId },
                        { assignee_id: this.tempAssigneeId },
                        this.policyRequest.insurance_retrieval_id,
                        this.policyRequest.user_id,
                        'insurance-retrievals'
                    )
                );
                this.dataUpdated.emit();
                this.changeDetectorRef.markForCheck();
            } catch (error) {
                console.error('Error updating policy assignee:', error);
            } finally {
                this.editAssignee = false;
                this.isLoading = false;
            }
        } else {
            this.editAssignee = false;
        }
    }

    async addComment() {
        if (this.newComment.description.trim()) {
            this.isLoading = true;
            this.newComment.id = generateUUID();
            this.newComment.type = !this.isAdmin ? 'user-message' : this.activityType;
            this.newComment.user = {
                id: this.user.user_id,
                full_name: this.user.full_name,
                email: this.user.email,
                photo_url: this.user?.photo,
            };
            this.newComment.created_at = new Date().toISOString();
            this.newComment.updated_at = new Date().toISOString();

            try {
                // Fetch the current retrieval
                const currentRetrieval = await firstValueFrom(
                    this.insuranceRetrievalService.getById(
                        this.policyRequest.insurance_retrieval_id
                    )
                );

                // Update the comments array
                const updatedComments = [
                    ...(currentRetrieval.comments || []),
                    this.newComment,
                ];

                // Update the retrieval with new comments
                await firstValueFrom(
                    this.insuranceRetrievalService.update(
                        this.policyRequest.insurance_retrieval_id,
                        { comments: updatedComments }
                    )
                );

                // Update local state
                this.policyRequest.comments = updatedComments;
                this.sortCommentsByLatest();

                // Create an event log for adding a comment
                await firstValueFrom(
                    this.eventLogService.createCommentEvent(
                        this.policyRequest.insurance_retrieval_id,
                        this.policyRequest.user_id,
                        'insurance-retrievals',
                        {
                            id: this.newComment.id,
                            type: 'created',
                            description: this.newComment.description,
                        }
                    )
                );

                this.dataUpdated.emit();
                this.resetNewActivityForm();
                this.changeDetectorRef.markForCheck();
            } catch (error) {
                console.error('Error adding comment:', error);
            } finally {
                this.isLoading = false;
            }
        }
    }

    resetNewActivityForm() {
        this.newComment = {
            id: '',
            user: null,
            type: 'general',
            description: '',
            created_at: new Date().toISOString(),
            updated_at: new Date().toISOString(),
        };
    }

    onClickClose(): void {
        this.closeDetails.emit();
    }

    ngOnDestroy() {
        if (this.languageSubscription) {
            this.languageSubscription.unsubscribe();
        }
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }
}
