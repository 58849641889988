import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SupabaseClient } from '@supabase/supabase-js';
import { BehaviorSubject, from, Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { PersonWithSettingService } from '../../common/supabase-services/person-with-setting.service';
import { PeopleAndObjectService } from '../../common/supabase-services/people-and-object.service';
import { SupabaseClientService } from '../../common/supabase-services/supabase-client.service';
import { UserSettingService } from '../../common/supabase-services/user-setting.service';
import { PersonWithSetting } from '../../common/supabase-models/person-with-setting';
import { PeopleAndObject } from '../../common/supabase-models/people-and-object';
import { UserSetting } from '../../common/supabase-models/user-setting';

@Injectable({ providedIn: 'root' })
export class AuthService {
    private _authenticated = false;
    private supabase: SupabaseClient;
    public readonly user: Observable<PersonWithSetting | null> = of(null);
    private _rolesSubject = new BehaviorSubject<('user' | 'admin')[] | null>(null);
    public roles$ = this._rolesSubject.asObservable();

    constructor(
        private _httpClient: HttpClient,
        private _personWithSettingService: PersonWithSettingService,
        private _peopleAndObjectService: PeopleAndObjectService,
        private _supabaseClientService: SupabaseClientService,
        private _userSettingService: UserSettingService
    ) {
        // Get the Supabase client from the SupabaseClientService
        this.supabase = this._supabaseClientService.getClient();

        // Monitor auth state change
        this.supabase.auth.onAuthStateChange(async (event, session) => {
            if (session?.user) {
                const userId = session.user.id;

                // Get the user from Supabase table using the user ID
                this._personWithSettingService
                    .getPersonWithSettingsById(userId)
                    .subscribe(
                        (profile) => {
                            if (profile) {
                                this._authenticated = true;
                                this._personWithSettingService.personWithSetting = profile;
                                this._rolesSubject.next(profile.roles);
                            }
                        },
                        (error) => {
                            console.error('Error retrieving user:', error);
                            this._authenticated = false;
                            this._personWithSettingService.personWithSetting = undefined;
                            this._rolesSubject.next(null);
                        }
                    );
            } else {
                this._authenticated = false;
                this._personWithSettingService.personWithSetting = undefined;
                this._rolesSubject.next(null);
            }
        });
    }

    // Function to map or validate the provider
    private getProvider(provider: string): 'email' | 'google' | 'facebook' | 'x' {
        switch (provider) {
            case 'email':
            case 'google':
            case 'facebook':
            case 'x':
                return provider;
            default:
                return 'email';
        }
    }

    // Function to insert user data into the "people_and_objects" and "user_settings" tables in Supabase
    private async insertUserToTables(user: {
        id: string;
        email: string;
        name: string;
        provider: string;
    }) {
        // Insert into "people_and_objects"
        const peopleAndObject: Partial<PeopleAndObject> = {
            first_name: user.name,
            type: 'person',
            owner_id: user.id,
            email: user.email,
            account_linked: true,
        };

        const { error: peopleError } = await this.supabase
            .from('people_and_objects')
            .insert([peopleAndObject]);

        if (peopleError) {
            console.error('Error inserting into people_and_objects:', peopleError);
            throw new Error('Failed to insert into people_and_objects');
        }

        // Insert into "user_settings"
        const userSetting: Partial<UserSetting> = {
            id: user.id,
            account_provider: this.getProvider(user.provider),
            roles: ['user'],
            status: 'online',
            language: this.getBrowserLanguage(),
        };

        const { error: settingsError } = await this.supabase
            .from('user_settings')
            .insert([userSetting]);

        if (settingsError) {
            console.error('Error inserting into user_settings:', settingsError);
            throw new Error('Failed to insert into user_settings');
        }
    }

    // Helper to get the browser's language
    private getBrowserLanguage(): 'en' | 'de' | 'fr' | 'it' {
        const language = navigator.language.slice(0, 2);
        return (['en', 'de', 'fr', 'it'].includes(language) ? language : 'en') as
            | 'en'
            | 'de'
            | 'fr'
            | 'it';
    }

    // Access Token
    async getAccessToken(): Promise<string> {
        const { data: session, error } = await this.supabase.auth.getSession();
        if (error) {
            throw new Error(error.message);
        }
        return session?.session?.access_token || '';
    }

    // Forgot password
    forgotPassword(email: string): Observable<void> {
        return from(this.supabase.auth.resetPasswordForEmail(email)).pipe(
            map(() => void 0),
            catchError((error) => throwError(() => new Error(error.message)))
        );
    }

    // Sign In
    signIn(credentials: { email: string; password: string }): Observable<any> {
        return from(
            this.supabase.auth.signInWithPassword({
                email: credentials.email,
                password: credentials.password,
            })
        ).pipe(
            map((response) => {
                if (response.error) {
                    throw response.error;
                }
                return { _type: 'success' } as const;
            }),
            catchError((error) => {
                console.error('Error during sign-in:', error);

                // Map the error to a user-friendly message
                if (error.message === 'Invalid login credentials') {
                    return of({
                        _type: 'error',
                        code: 'invalid-credentials',
                        message: error.message,
                    } as const);
                }

                return of({
                    _type: 'error',
                    code: 'internal-error',
                    message: error.message || 'An unexpected error occurred.',
                } as const);
            })
        );
    }

    // Sign Up
    signUp(user: { name: string; email: string; password: string }): Observable<any> {
        return from(
            this.supabase.auth.signUp({
                email: user.email,
                password: user.password,
            })
        ).pipe(
            switchMap(async (response) => {
                // After signing up, insert the user data into the people_and_objects and user_settings tables
                const provider = this.getProvider(
                    response.data?.user?.identities?.[0]?.provider || 'email'
                );

                const newUser = {
                    id: response.data?.user?.id || '',
                    email: user.email,
                    name: user.name,
                    provider: provider,
                };

                await this.insertUserToTables(newUser);
                return { _type: 'success' } as const;
            }),
            catchError((error) => {
                if (error.message === 'User already registered') {
                    return of({
                        _type: 'error',
                        code: 'email-already-registered',
                        message: error.message,
                    } as const);
                }
                return of({
                    _type: 'error',
                    code: 'internal-error',
                    message: error.message,
                } as const);
            })
        );
    }

    // Sign Out
    signOut(): Observable<any> {
        return from(this.supabase.auth.signOut()).pipe(
            map((_) => {
                this._authenticated = false;
                this._personWithSettingService.personWithSetting = undefined;
                this._rolesSubject.next(null);
                return true;
            }),
            catchError((error) => {
                console.error('[debug] unable to sign out.', error);
                return of(false);
            })
        );
    }

    // Check session
    async check(): Promise<'unauthenticated' | 'authenticated'> {
        try {
            const { data: session } = await this.supabase.auth.getSession();
            return session?.session ? 'authenticated' : 'unauthenticated';
        } catch (error) {
            console.error('Error during session check:', error);
            return 'unauthenticated';
        }
    }

    // Google OAuth sign-in
    signInWithGoogle(): Promise<void> {
        return this.supabase.auth
            .signInWithOAuth({
                provider: 'google',
            })
            .then(({ data, error }) => {
                if (error) {
                    throw new Error(error.message);
                }

                // Handle the OAuth URL redirection, user will follow the URL to sign in
                if (data.url) {
                    window.location.href = data.url; // Redirect the user to the OAuth provider
                }
            });
    }

    isAdmin(): boolean {
        const roles = this._rolesSubject.value;
        return roles ? roles.includes('admin') : false;
    }

    isSuperUser(): boolean {
        const roles = this._rolesSubject.value;
        return roles ? roles.includes('admin') && roles.includes('user') : false;
    }
}
