import { Injectable } from '@angular/core';
import { SupabaseClient } from '@supabase/supabase-js';
import { from, Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { User, CollectionType, ActionType, CommentLog } from '../supabase-models/common';
import { PersonWithSetting } from '../supabase-models/person-with-setting';
import { EventLog, Status, Log } from '../supabase-models/event-log';

import { SupabaseClientService } from './supabase-client.service';
import { PersonWithSettingService } from './person-with-setting.service';

@Injectable({
    providedIn: 'root',
})
export class EventLogService {
    private supabase: SupabaseClient;
    private currentUser: User | null = null;

    constructor(
        private _supabaseClientService: SupabaseClientService,
        private _personWithSettingService: PersonWithSettingService
    ) {
        // Initialize Supabase client
        this.supabase = this._supabaseClientService.getClient();

        // Subscribe to the current user from PersonWithSettingService
        this._personWithSettingService.personWithSetting$.subscribe(
            (user: PersonWithSetting) => {
                if (user) {
                    this.currentUser = {
                        id: user.user_id,
                        full_name: user.full_name,
                        email: user.email,
                        photo_url: user.photo || '',
                    };
                } else {
                    this.currentUser = null;
                }
            }
        );
    }

    // Create new event log
    create(entity: EventLog): Observable<EventLog> {
        return from(this.supabase.from('event_log').insert([entity]).select('*')).pipe(
            map((response) => {
                if (response.data && response.data.length > 0) {
                    return response.data[0] as EventLog;
                }
                throw new Error('Failed to create event log record');
            }),
            catchError((error) => throwError(() => new Error(error.message)))
        );
    }

    // Listen for event notifications
    listenForEventNotifications(uid: string): Observable<EventLog[]> {
        return from(
            this.supabase
                .from('event_log')
                .select('*')
                .eq('status', 'published')
                .eq('show_on_notification', true)
                .eq('record_owner_id', uid)
                .order('created_at', { ascending: false })
        ).pipe(
            map((response) => response.data || []),
            catchError((error) => throwError(() => new Error(error.message)))
        );
    }

    // Get events by record ID and owner ID
    getEventsByRecordIdAndOwnerId(
        recordId: number,
        ownerId: string
    ): Observable<EventLog[]> {
        return from(
            this.supabase
                .from('event_log')
                .select('*')
                .eq('record_id', recordId)
                .eq('record_owner_id', ownerId)
                .order('created_at', { ascending: false })
        ).pipe(
            map((response) => response.data || []),
            catchError((error) => throwError(() => new Error(error.message)))
        );
    }

    // Get events by record ID, owner ID, and status
    getEventsByRecordIdOwnerIdAndStatus(
        recordId: number,
        ownerId: string,
        status: Status
    ): Observable<EventLog[]> {
        return from(
            this.supabase
                .from('event_log')
                .select('*')
                .eq('record_id', recordId)
                .eq('record_owner_id', ownerId)
                .eq('status', status)
                .order('created_at', { ascending: false })
        ).pipe(
            map((response) => response.data || []),
            catchError((error) => throwError(() => new Error(error.message)))
        );
    }

    // Create a general event
    createEvent(
        recordId: number,
        recordOwnerId: string,
        collectionType: CollectionType,
        actionType: ActionType,
        name: string = '',
        showOnNotification: boolean = true
    ): Observable<EventLog> {
        const event: EventLog = {
            name,
            record_id: recordId,
            action_type: actionType,
            collection_type: collectionType,
            record_owner_id: recordOwnerId,
            show_on_notification: showOnNotification,
            status: 'published',
            read: false,
            logs: [],
            created_by: this.currentUser as User,
            created_at: new Date().toISOString(),
        };

        return this.create(event);
    }

    // Create a 'created' event
    createCreateEvent(
        recordId: number,
        recordOwnerId: string,
        collectionType: CollectionType,
        name = ''
    ): Observable<EventLog> {
        const event: EventLog = {
            name,
            record_id: recordId,
            status: 'published',
            record_owner_id: recordOwnerId,
            collection_type: collectionType,
            action_type: 'created',
            logs: [],
            created_by: this.currentUser as User,
            created_at: new Date().toISOString(),
        };

        return this.create(event);
    }

    // Create a 'deleted' event
    createDeleteEvent(
        recordId: number,
        recordOwnerId: string,
        collectionType: CollectionType
    ): Observable<EventLog> {
        const event: EventLog = {
            record_id: recordId,
            status: 'published',
            record_owner_id: recordOwnerId,
            collection_type: collectionType,
            action_type: 'deleted',
            logs: [],
            created_by: this.currentUser as User,
            created_at: new Date().toISOString(),
        };

        return this.create(event);
    }

    // Create an 'updated' event with change logs
    createUpdateEvent(
        previousObj: object,
        newObj: object,
        recordId: number,
        recordOwnerId: string,
        collectionType: CollectionType
    ): Observable<EventLog | null> {
        const logs: Log[] = this.compareObjects(previousObj, newObj);
        if (logs.length > 0) {
            const event: EventLog = {
                record_id: recordId,
                status: 'published',
                record_owner_id: recordOwnerId,
                collection_type: collectionType,
                action_type: 'updated',
                logs: logs,
                created_by: this.currentUser as User,
                created_at: new Date().toISOString(),
            };

            return this.create(event);
        } else {
            // No changes detected
            return of(null);
        }
    }

    // Create multiple event logs
    createMultipleEventLogs(events: EventLog[]): Observable<EventLog[]> {
        return from(this.supabase.from('event_log').insert(events).select('*')).pipe(
            map((response) => response.data as EventLog[]),
            catchError((error) => throwError(() => new Error(error.message)))
        );
    }

    // Create an event log for comment actions
    createCommentEvent(
        recordId: number,
        recordOwnerId: string,
        collectionType: CollectionType,
        commentLog: CommentLog
    ): Observable<EventLog> {
        const event: EventLog = {
            record_id: recordId,
            status: 'published',
            record_owner_id: recordOwnerId,
            collection_type: collectionType,
            action_type: 'updated',
            logs: [],
            comment_log: commentLog,
            created_by: this.currentUser as User,
            created_at: new Date().toISOString(),
        };

        return this.create(event);
    }

    // Update event status
    updateEventStatus(eventIds: number[], newStatus: Status): Observable<EventLog[]> {
        return from(
            this.supabase
                .from('event_log')
                .update({ status: newStatus })
                .in('id', eventIds)
                .select('*')
        ).pipe(
            map((response) => response.data as EventLog[]),
            catchError((error) => throwError(() => new Error(error.message)))
        );
    }

    // Mark events as read or unread
    markEventsReadOrUnread(eventIds: number[], read: boolean): Observable<EventLog[]> {
        return from(
            this.supabase
                .from('event_log')
                .update({ read })
                .in('id', eventIds)
                .select('*')
        ).pipe(
            map((response) => response.data as EventLog[]),
            catchError((error) => throwError(() => new Error(error.message)))
        );
    }

    // Remove events from notifications
    removeEventsFromNotifications(eventIds: number[]): Observable<EventLog[]> {
        return from(
            this.supabase
                .from('event_log')
                .update({ show_on_notification: false })
                .in('id', eventIds)
                .select('*')
        ).pipe(
            map((response) => response.data as EventLog[]),
            catchError((error) => throwError(() => new Error(error.message)))
        );
    }

    // Delete an event
    deleteEvent(eventId: number): Observable<void> {
        return from(this.supabase.from('event_log').delete().eq('id', eventId)).pipe(
            map(() => void 0),
            catchError((error) => throwError(() => new Error(error.message)))
        );
    }

    // Utility method to compare objects and generate logs
    private compareObjects(previousObj: object, newObj: object): Log[] {
        const logs: Log[] = [];
        const allKeys = new Set([
            ...Object.keys(previousObj || {}),
            ...Object.keys(newObj || {}),
        ]);

        allKeys.forEach((key) => {
            if (key === 'updatedAt') return;
            let prevValue = previousObj ? previousObj[key] : undefined;
            let newValue = newObj ? newObj[key] : undefined;

            // Handle nested objects and arrays
            if (
                typeof prevValue === 'object' &&
                typeof newValue === 'object' &&
                prevValue !== null &&
                newValue !== null
            ) {
                const nestedLogs = this.compareObjects(prevValue, newValue);
                if (nestedLogs.length > 0) {
                    logs.push(
                        ...nestedLogs.map((log) => ({
                            property_name: `${key}.${log.property_name}`,
                            prev_value: log.prev_value,
                            new_value: log.new_value,
                        }))
                    );
                }
            } else if (prevValue !== newValue) {
                logs.push({
                    property_name: key,
                    prev_value: this.stringifyValue(prevValue),
                    new_value: this.stringifyValue(newValue),
                });
            }
        });

        return logs;
    }

    // Utility method to stringify values
    private stringifyValue(value: any): string {
        if (typeof value === 'undefined' || value === null) {
            return '';
        }
        if (typeof value === 'object') {
            try {
                return JSON.stringify(value, null, 2);
            } catch (error) {
                console.error('Error stringifying value:', error);
                return '';
            }
        }
        return value.toString();
    }
}
