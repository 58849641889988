import { Injectable } from '@angular/core';
import { SupabaseClient } from '@supabase/supabase-js';
import { from, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Insurer } from '../supabase-models/insurer';
import { SupabaseClientService } from './supabase-client.service';

@Injectable({
    providedIn: 'root',
})
export class InsurerService {
    private supabase: SupabaseClient;

    constructor(private supabaseClientService: SupabaseClientService) {
        this.supabase = this.supabaseClientService.getClient();
    }

    // Create a new insurer
    create(insurer: Insurer): Observable<Insurer> {
        return from(this.supabase.from('insurer').insert([insurer]).select()).pipe(
            map((response) => {
                if (response.error) {
                    throw new Error(response.error.message);
                }
                const data = response.data as Insurer[] | undefined;
                return data && data.length > 0 ? data[0] : insurer;
            }),
            catchError((error) => throwError(() => new Error(error.message)))
        );
    }

    // Get all insurers
    getAll(): Observable<Insurer[]> {
        return from(this.supabase.from('insurer').select('*')).pipe(
            map((response) => {
                if (response.error) {
                    throw new Error(response.error.message);
                }
                return (response.data || []) as Insurer[];
            }),
            catchError((error) => throwError(() => new Error(error.message)))
        );
    }

    // Get insurer by ID
    getById(id: number): Observable<Insurer> {
        return from(this.supabase.from('insurer').select('*').eq('id', id).single()).pipe(
            map((response) => response.data as Insurer),
            catchError((error) => throwError(() => new Error(error.message)))
        );
    }

    // Update insurer by ID
    update(id: number, insurer: Partial<Insurer>): Observable<Insurer> {
        return from(this.supabase.from('insurer').update(insurer).eq('id', id)).pipe(
            map((response) => {
                if (response.error) {
                    throw new Error(response.error.message);
                }

                const data = response.data as Insurer[] | undefined;
                return data && data.length > 0
                    ? data[0]
                    : ({ ...insurer, id } as Insurer);
            }),
            catchError((error) => throwError(() => new Error(error.message)))
        );
    }

    // Delete insurer by ID
    delete(id: number): Observable<void> {
        return from(this.supabase.from('insurer').delete().eq('id', id)).pipe(
            map(() => void 0),
            catchError((error) => throwError(() => new Error(error.message)))
        );
    }
}
