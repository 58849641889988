import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { fuseAnimations } from '@fuse/animations';
import { MatIconModule } from '@angular/material/icon';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogData } from './DialogTypes';
import { SimpleChanges } from '@angular/core';

@Component({
    selector: 'loading-overlay',
    standalone: true,
    imports: [CommonModule, MatProgressSpinnerModule, MatIconModule],
    templateUrl: './loading-overlay.component.html',
    styleUrls: ['./loading-overlay.component.scss'],
    animations: fuseAnimations,
})
export class LoadingOverlayComponent {
    constructor(
        public dialogRef: MatDialogRef<LoadingOverlayComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData
    ) {}

    ngDoCheck(): void {
        if (!this.data.isLoading) {
            setTimeout(() => {
                this.dialogRef.close()
            }, 2000);
        }
    }
}
