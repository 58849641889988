import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    ViewEncapsulation,
    Component,
    ViewChild,
    OnDestroy,
    AfterViewInit,
    OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { CommonModule, DatePipe } from '@angular/common';
import { MatSelectModule, MatSelectChange } from '@angular/material/select';
import { MatPaginatorModule, MatPaginator } from '@angular/material/paginator';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatSortModule, Sort, MatSort } from '@angular/material/sort';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { fuseAnimations } from '@fuse/animations';
import { FormsModule } from '@angular/forms';
import {
    BehaviorSubject,
    combineLatest,
    Subscription,
    switchMap,
    takeUntil,
    Subject,
    map,
    Observable,
} from 'rxjs';

import { FuseConfirmationDialogComponent } from '@fuse/services/confirmation/dialog/dialog.component';

import { InsuranceRetrievalView } from 'app/common/supabase-models/insurance-retrieval-view';
import { PolicyExtractionView } from 'app/common/supabase-models/policy-extraction-view';
import { InsuranceRetrievalViewService } from 'app/common/supabase-services/insurance-retrieval-view.service';
import { PolicyExtractionViewService } from 'app/common/supabase-services/policy-extraction-view.service';
import { InsuranceRetrievalService } from 'app/common/supabase-services/insurance-retrieval.service';
import { PolicyExtractionService } from 'app/common/supabase-services/policy-extraction.service';
import { ToDatePipe } from 'app/common/pipes/to-date.pipe';

type CombinedItem =
    | (PolicyExtractionView & { type: 'policy-extraction' })
    | (InsuranceRetrievalView & { type: 'policy-request' });

type Filters = {
    urgency: string;
    user: string;
    type: string;
    status: string[];
    assignee: string;
};

type AdvancedFilters = {
    query: string;
    filters: Filters;
};

@Component({
    selector: 'app-tasks',
    templateUrl: './tasks.component.html',
    styleUrls: ['./tasks.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: fuseAnimations,
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        MatSortModule,
        MatIconModule,
        MatTableModule,
        MatInputModule,
        MatSelectModule,
        TranslocoModule,
        MatDialogModule,
        MatButtonModule,
        MatTooltipModule,
        MatPaginatorModule,
        MatProgressBarModule,
        FuseConfirmationDialogComponent,
        ToDatePipe,
    ],
    providers: [DatePipe],
})
export class TasksComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild(MatSort, { static: true }) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    advancedFilters: AdvancedFilters = {
        query: '',
        filters: {
            urgency: '',
            user: '',
            type: '',
            status: [],
            assignee: '',
        },
    };
    filters: {
        query$: BehaviorSubject<string>;
        sort$: BehaviorSubject<Sort>;
        filters$: BehaviorSubject<Filters>;
    } = {
        query$: new BehaviorSubject(''),
        sort$: new BehaviorSubject({ active: '', direction: '' }),
        filters$: new BehaviorSubject({
            urgency: '',
            user: '',
            type: '',
            status: [],
            assignee: '',
        }),
    };
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    private languageSubscription: Subscription;
    currentLanguage: string;
    isLoading: boolean = false;
    displayedColumns: string[] = [
        'urgency',
        'taskID',
        'dateTime',
        'user',
        'type',
        'detail',
        'attachment',
        'status',
        'assignedTo',
        'actions',
    ];
    fullDataSource: CombinedItem[] = [];
    dataSource: CombinedItem[] = [];
    // Data mapping
    docTypeMapping = {
        'policy-extraction': 'Extraction',
        'policy-request': 'Policy Request',
    };
    urgencyColorMapping = {
        low: 'bg-green-400',
        medium: 'bg-orange-400',
        high: 'bg-red-400',
    };
    users: { uid: string; displayName: string; email: string }[] = [];
    assignees: { uid: string; displayName: string; email: string }[] = [];

    constructor(
        private router: Router,
        private datePipe: DatePipe,
        private matDialog: MatDialog,
        private translocoService: TranslocoService,
        private changeDetectorRef: ChangeDetectorRef,
        private policyExtractionViewService: PolicyExtractionViewService,
        private insuranceRetrievalViewService: InsuranceRetrievalViewService,
        private policyExtractionService: PolicyExtractionService,
        private insuranceRetrievalService: InsuranceRetrievalService
    ) {
        this.languageSubscription = this.translocoService.langChanges$.subscribe(
            (lang: string) => {
                this.currentLanguage = lang.toLowerCase();
            }
        );
    }

    ngOnInit(): void {
        this.loadFiltersFromLocalStorage();
        combineLatest([this.filters.query$, this.filters.sort$, this.filters.filters$])
            .pipe(
                switchMap(([query, sort, filters]) => {
                    this.isLoading = true;
                    return combineLatest({
                        documents: this.policyExtractionViewService.getAllPolicyExtractionView(),
                        policyRequests: this.insuranceRetrievalViewService.getAllInsuranceRetrievalView(),
                    }).pipe(
                        map(({ documents, policyRequests }) => {
                            let combinedList = this.combineAndTagDocuments(
                                documents,
                                policyRequests
                            );
                            this.extractUniqueUsersAndAssignees(combinedList);
                            combinedList = this.filterData(combinedList, filters, query);
                            combinedList = this.sortData(combinedList, sort);
                            return combinedList;
                        })
                    );
                }),
                takeUntil(this._unsubscribeAll)
            )
            .subscribe(
                (data) => {
                    this.fullDataSource = data;
                    this.paginator.length = this.fullDataSource.length;
                    this.applyPagination();
                    this.isLoading = false;
                    this.changeDetectorRef.markForCheck();
                },
                (error) => {
                    console.error('[APP ERROR] Error: ', error);
                    this.isLoading = false;
                    this.changeDetectorRef.markForCheck();
                }
            );
    }

    ngAfterViewInit(): void {
        this.sort.sortChange.subscribe((sort) => {
            this.filters.sort$.next(sort);
        });

        this.paginator.page.subscribe(() => {
            this.updateDataSourceBasedOnPagination();
        });
    }

    private applyPagination(): void {
        this.paginator.pageIndex = 0;
        this.updateDataSourceBasedOnPagination();
    }

    private updateDataSourceBasedOnPagination(): void {
        const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
        const paginatedData = this.fullDataSource.slice(
            startIndex,
            startIndex + this.paginator.pageSize
        );

        this.dataSource = paginatedData;
        this.changeDetectorRef.markForCheck();
    }

    private combineAndTagDocuments(
        documents: PolicyExtractionView[],
        policyRequests: InsuranceRetrievalView[]
    ): CombinedItem[] {
        const taggedDocuments: (PolicyExtractionView & { type: 'policy-extraction' })[] = documents.map((doc) => ({
            ...doc,
            type: 'policy-extraction',
        }));
    
        const taggedPolicyRequests: (InsuranceRetrievalView & { type: 'policy-request' })[] = policyRequests.map((request) => ({
            ...request,
            type: 'policy-request',
        }));
    
        return [...taggedDocuments, ...taggedPolicyRequests];
    }

    private filterData(
        data: CombinedItem[],
        filters: Filters,
        query: string
    ): CombinedItem[] {
        let filteredData = data;
    
        if (filters.urgency) {
            filteredData = filteredData.filter((item) => item.urgency === filters.urgency);
        }
    
        if (filters.user) {
            filteredData = filteredData.filter((item) => item.user_id === filters.user);
        }
    
        if (filters.type) {
            filteredData = filteredData.filter((item) => item.type === filters.type);
        }
    
        if (filters.status.length) {
            filteredData = filteredData.filter((item) => filters.status.includes(item.status));
        }
    
        if (filters.assignee) {
            filteredData = filteredData.filter((item) => item.assignee_id === filters.assignee);
        }
    
        if (query) {
            const lowerCaseQuery = query.toLowerCase();
            filteredData = filteredData.filter((item) => {
                const formattedCreatedAt = this.datePipe.transform(item.created_at, 'short')?.toLowerCase() || '';
                const searchableStr = [
                    item.task_id,
                    formattedCreatedAt,
                    item.user_full_name,
                    item.user_email,
                    this.docTypeMapping[item.type].toLowerCase(),
                    item.type === 'policy-extraction' ? item.filename : '',
                    item.status.toLowerCase(),
                    (item.assignee_id || 'unassigned').toLowerCase(),
                ]
                    .filter(Boolean)
                    .join(' ')
                    .toLowerCase();
    
                return searchableStr.includes(lowerCaseQuery);
            });
        }
    
        return filteredData;
    }

    private sortData(data: CombinedItem[], sort: Sort): CombinedItem[] {
        if (!sort.active || sort.direction === '') {
            sort = { active: 'updated_at', direction: 'desc' };
        }

        return data.sort((a, b) => {
            let valueA: any = a[sort.active as keyof CombinedItem] || '';
            let valueB: any = b[sort.active as keyof CombinedItem] || '';

            return (valueA < valueB ? -1 : 1) * (sort.direction === 'asc' ? 1 : -1);
        });
    }

    private extractUniqueUsersAndAssignees(dataSource: CombinedItem[]): void {
        const userSet = new Set<string>();
        const assigneeSet = new Set<string>();

        const users = [];
        const assignees = [];

        dataSource.forEach((item) => {
            if (item.user_id && !userSet.has(item.user_id)) {
                users.push({ uid: item.user_id, displayName: item.user_full_name, email: item.user_email });
                userSet.add(item.user_id);
            }

            if (item.assignee_id && !assigneeSet.has(item.assignee_id)) {
                assignees.push({ uid: item.assignee_id, displayName: item.user_full_name, email: item.user_email });
                assigneeSet.add(item.assignee_id);
            }
        });

        this.users = users;
        this.assignees = assignees;
    }

    onSearch(): void {
        this.filters.query$.next(this.advancedFilters.query);
    }

    updateFilter(): void {
        this.filters.filters$.next(this.advancedFilters.filters);
        this.saveFiltersToLocalStorage();
        this.changeDetectorRef.detectChanges();
    }

    clearFilters(): void {
        this.advancedFilters = {
            query: '',
            filters: {
                urgency: '',
                user: '',
                type: '',
                status: [],
                assignee: '',
            },
        };
        this.filters.filters$.next({ ...this.advancedFilters.filters });
        this.filters.query$.next('');
        this.filters.sort$.next({ active: '', direction: '' });
        this.saveFiltersToLocalStorage();
        this.changeDetectorRef.detectChanges();
    }

    private saveFiltersToLocalStorage(): void {
        let tasks: any = localStorage.getItem('tasks');
        tasks = {
            ...JSON.parse(tasks || '{}'),
            filters: { ...this.advancedFilters.filters },
        };
        localStorage.setItem('tasks', JSON.stringify(tasks));
    }

    private loadFiltersFromLocalStorage(): void {
        let tasks: any = localStorage.getItem('tasks');
        if (tasks) {
            tasks = JSON.parse(tasks);
            if (tasks.filters) {
                this.advancedFilters.filters = tasks.filters;
            }
        } else {
            this.advancedFilters.filters = {
                urgency: '',
                user: '',
                type: '',
                status: [],
                assignee: '',
            };
        }
        this.filters.filters$.next({ ...this.advancedFilters.filters });
    }

    navigateToItem(item: CombinedItem): void {
        const typeMap = {
            'policy-extraction': 'extraction',
            'policy-request': 'request',
        };

        let id: number;
        if (item.type === 'policy-extraction') {
            id = (item as PolicyExtractionView).policy_extraction_id;
        } else if (item.type === 'policy-request') {
            id = (item as InsuranceRetrievalView).insurance_retrieval_id;
        }

        const path = `admin/tasks/${typeMap[item.type]}/${id}`;
        this.router.navigate([path]);
    }

    delete(doc: CombinedItem): void {
        const isExtraction = doc.type === 'policy-extraction';
    
        const dialogRef = this.matDialog.open(FuseConfirmationDialogComponent, {
            disableClose: true,
            width: '32rem',
            data: {
                title: this.translocoService.translate('admin.deleteTask.confirmDeleteTitle'),
                message: this.translocoService.translate('admin.deleteTask.confirmDeleteMessage'),
                icon: {
                    show: true,
                    name: 'heroicons_outline:trash',
                    color: 'warn',
                },
                actions: {
                    confirm: {
                        show: true,
                        label: this.translocoService.translate('admin.deleteTask.delete'),
                        color: 'warn',
                    },
                    cancel: {
                        show: true,
                        label: this.translocoService.translate('admin.deleteTask.cancel'),
                    },
                },
                dismissible: false,
            }
        });
    
        dialogRef.afterClosed().subscribe((result) => {
            if (result === 'confirmed') {
                this.isLoading = true;
    
                const deleteObservable = isExtraction
                    ? this.policyExtractionService.delete(doc.policy_extraction_id)
                    : this.insuranceRetrievalService.delete(doc.insurance_retrieval_id);
    
                deleteObservable.subscribe({
                    next: () => {
                        // Remove the deleted task from local component data
                        this.fullDataSource = this.fullDataSource.filter(item => 
                            item.type === 'policy-extraction'
                                ? (item as PolicyExtractionView).policy_extraction_id !== (doc as PolicyExtractionView).policy_extraction_id
                                : (item as InsuranceRetrievalView).insurance_retrieval_id !== (doc as InsuranceRetrievalView).insurance_retrieval_id
                        );
    
                        // Update the paginated data
                        this.updateDataSourceBasedOnPagination();
    
                        this.isLoading = false;
                        this.changeDetectorRef.markForCheck();
                    },
                    error: (e: Error) => {
                        console.error('Error:', e.message);
                        this.isLoading = false;
                    },
                });
            }
        });
    }

    private handleTaskUpdate(task: CombinedItem, isExtraction: boolean): void {
        const taskIndex = this.fullDataSource.findIndex((t: CombinedItem) => {
            if (isExtraction && t.type === 'policy-extraction') {
                return (t as PolicyExtractionView).policy_extraction_id === (task as PolicyExtractionView).policy_extraction_id;
            } else if (!isExtraction && t.type === 'policy-request') {
                return (t as InsuranceRetrievalView).insurance_retrieval_id === (task as InsuranceRetrievalView).insurance_retrieval_id;
            }
            return false;
        });
    
        if (taskIndex !== -1) {
            // Directly update the status to 'closed'
            this.fullDataSource[taskIndex].status = 'closed';
            this.updateDataSourceBasedOnPagination();
        }
    
        this.isLoading = false;
        this.changeDetectorRef.markForCheck();
    }

    updateStatusToClosed(task: CombinedItem): void {
        this.isLoading = true;
        const isExtraction = task.type === 'policy-extraction';
    
        if (isExtraction) {
            // Handle PolicyExtraction update
            this.policyExtractionService.update(task.policy_extraction_id, { status: 'closed' })
                .subscribe({
                    next: () => {
                        // Since we don't need updatedTask, directly update the component data
                        this.handleTaskUpdate(task, isExtraction);
                    },
                    error: (error: Error) => {
                        console.error('Error updating status:', error.message);
                        this.isLoading = false;
                        this.changeDetectorRef.markForCheck();
                    },
                });
        } else {
            // Handle InsuranceRetrieval update
            this.insuranceRetrievalService.update(task.insurance_retrieval_id, { status: 'closed' })
                .subscribe({
                    next: () => {
                        // Directly update the component data
                        this.handleTaskUpdate(task, isExtraction);
                    },
                    error: (error: Error) => {
                        console.error('Error updating status:', error.message);
                        this.isLoading = false;
                        this.changeDetectorRef.markForCheck();
                    },
                });
        }
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
        if (this.languageSubscription) {
            this.languageSubscription.unsubscribe();
        }
    }
}
