import Decimal from 'decimal.js';
import { v4 as uuidv4 } from 'uuid';
import { LangCode } from 'app/common/supabase-models/common';

export function assertNever<T>(value: never): T {
    throw new Error(`Unexpected value: ${JSON.stringify(value)}`);
}

/**
 * Calculate monthly premium factoring discounts and ignoring expiration date.
 * @param netCentsValue - The total premium value in cents.
 * @param periodicityMonthsValue - The number of months in the periodicity.
 * @param discounts - Optional discounts to apply.
 * @returns {number} The calculated monthly premium.
 */
export function monthlyPremiumInCard(
    netCentsValue: number,
    periodicityMonthsValue: number,
    discounts?: {
        [key: string]: { amountCents: number; percentage: number };
    }
): number {
    if (netCentsValue && periodicityMonthsValue) {
        let netCentsDecimal = new Decimal(netCentsValue);

        // Apply discounts
        if (discounts) {
            Object.values(discounts).forEach((discount) => {
                if (discount.amountCents) {
                    const adjustedAmountCents = new Decimal(
                        discount.amountCents
                    ).dividedBy(periodicityMonthsValue);
                    netCentsDecimal = netCentsDecimal.minus(adjustedAmountCents);
                }
                if (discount.percentage) {
                    const percentageDiscount = netCentsDecimal
                        .times(discount.percentage)
                        .dividedBy(100);
                    netCentsDecimal = netCentsDecimal.minus(percentageDiscount);
                }
            });
        }

        const calculatedMonthlyPremium = netCentsDecimal
            .dividedBy(100) // Convert from cents to main unit
            .dividedBy(periodicityMonthsValue);

        return (
            calculatedMonthlyPremium.isFinite()
                ? calculatedMonthlyPremium.toDecimalPlaces(2)
                : new Decimal(0)
        )
            .toDecimalPlaces(2)
            .toNumber();
    }

    return new Decimal(0).toDecimalPlaces(2).toNumber();
}

/**
 * Calculate monthly premium considering expiration date.
 * @param netCentsValue - The total premium value in cents.
 * @param periodicityMonthsValue - The number of months in the periodicity.
 * @param expirationDate - The expiration date of the policy.
 * @param discounts - Optional discounts to apply.
 * @returns {number} The calculated monthly premium or 0 if expired.
 */
export function calculateMonthlyPremium(
    netCentsValue: number,
    periodicityMonthsValue: number,
    expirationDate: string,
    discounts?: {
        [key: string]: { amountCents: number; percentage: number };
    }
): number {
    const currentDate = new Date();
    const expirationDt = new Date(expirationDate);

    if (expirationDt >= currentDate) {
        return monthlyPremiumInCard(netCentsValue, periodicityMonthsValue, discounts);
    }

    return new Decimal(0).toDecimalPlaces(2).toNumber();
}

export function parseName(nameWithDate: string): string {
    return nameWithDate.replace(/\d{2}.\d{2}.\d{4}$/, '').trim();
}

export function generateUUID(): string {
    return uuidv4();
}

export function generateTaskID() {
    const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let prefix = '';
    for (let i = 0; i < 4; i++) {
        prefix += letters.charAt(Math.floor(Math.random() * letters.length));
    }

    const suffix = Math.floor(1000 + Math.random() * 9000);
    return `${prefix}-${suffix}`;
}

const TRANSLATIONS = {
    en: {
        monthly: 'Monthly',
        biMonthly: 'Every 2 months',
        quarterly: 'Every 3 months',
        semiAnnual: 'Every 6 months',
        annually: 'Annually',
        nextPayment: 'Next payment: ',
    },
    de: {
        monthly: 'Monatlich',
        biMonthly: 'Alle 2 Monate',
        quarterly: 'Alle 3 Monate',
        semiAnnual: 'Alle 6 Monate',
        annually: 'Jährlich',
        nextPayment: 'Nächste Zahlung: ',
    },
    fr: {
        monthly: 'Mensuel',
        biMonthly: 'Tous les 2 mois',
        quarterly: 'Tous les 3 mois',
        semiAnnual: 'Tous les 6 mois',
        annually: 'Annuellement',
        nextPayment: 'Prochain paiement : ',
    },
    it: {
        monthly: 'Mensile',
        biMonthly: 'Ogni 2 mesi',
        quarterly: 'Ogni 3 mesi',
        semiAnnual: 'Ogni 6 mesi',
        annually: 'Annualmente',
        nextPayment: 'Prossimo pagamento: ',
    },
};

const periodicityMap = {
    "MONTHLY": 1,
    "BI-MONTHLY": 2,
    "QUARTERLY": 3,
    "6 MONTHS": 6,
    "12 MONTHS": 12,
};

/**
 * Format a date into Swiss date format (DD.MM.YYYY).
 * @param date - The date to format.
 * @returns {string} Formatted date in DD.MM.YYYY format.
 */
function formatToSwissDate(date: Date): string {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
}

/**
 * Calculate the next payment date dynamically based on the effective date and periodicity.
 * @param effectiveDate - The policy's effective date.
 * @param periodicity - The periodicity string (e.g., "MONTHLY", "QUARTERLY").
 * @returns {string} Next payment date in Swiss date format (DD.MM.YYYY).
 */
export function calculateNextPaymentDate(
    effectiveDate: string,
    periodicity: string
): string {
    if (!effectiveDate || !periodicityMap[periodicity]) return '';

    const nextPaymentDate = new Date(effectiveDate);
    const monthsToAdd = periodicityMap[periodicity];

    // Increment the date until it's in the future
    const currentDate = new Date();
    while (nextPaymentDate <= currentDate) {
        nextPaymentDate.setMonth(nextPaymentDate.getMonth() + monthsToAdd);
    }

    return formatToSwissDate(nextPaymentDate);
}

/**
 * Get human-readable payment frequency display.
 * @param periodicity - The periodicity string (e.g., "MONTHLY", "QUARTERLY").
 * @param lang - Language code for translations ('en', 'de', 'fr', 'it').
 * @returns {string} Human-readable payment frequency.
 */
export function getPaymentFrequencyDisplay(
    periodicity: string,
    lang: LangCode = 'en'
): string {
    const translations = TRANSLATIONS[lang] || TRANSLATIONS.en;

    const frequencyMap = {
        "MONTHLY": translations.monthly,
        "BI-MONTHLY": translations.biMonthly,
        "QUARTERLY": translations.quarterly,
        "6 MONTHS": translations.semiAnnual,
        "12 MONTHS": translations.annually,
    };

    return frequencyMap[periodicity] || translations.annually;
}