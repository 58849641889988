import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

export const AdminGuard: CanActivateFn | CanActivateChildFn = (route, state) => {
    const router: Router = inject(Router);
    const auth: AuthService = inject(AuthService);

    return auth.roles$.pipe(
        // Wait until roles are not null
        filter((roles) => roles !== null),
        take(1), // Only take the first emitted value
        map((roles) => {
            const isAdmin = roles.includes('admin');
            const isSuperUser = roles.includes('user') && roles.includes('admin');
            if (!isAdmin && !isSuperUser) {
                router.navigate(['/']); // Redirect if not authorized
                return false;
            }
            return true; // Allow navigation if authorized
        })
    );
};
