<!-- policy-extraction-task.component.html -->

<div class="flex flex-col w-full h-full max-h-[calc(100vh-160px)] relative">
    <!-- Loading Indicator -->
    <div *ngIf="isLoading || insuranceLoading" class="absolute inset-x-0 top-0">
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
  
    <!-- Header -->
    <div class="flex items-center justify-between p-4 bg-white shadow border-b">
      <div class="flex justify-start items-center gap-3">
        <button
          class="flex justify-center items-center"
          matTooltip="Return to task list"
          (click)="navigateToTasks()"
        >
          <mat-icon svgIcon="heroicons_outline:arrow-small-left"></mat-icon>
        </button>
        <h1 *ngIf="document" class="text-lg font-bold">
          Task {{ document?.task_id || document?.policy_extraction_id }}: Extraction -
          {{ document?.user_full_name }}
        </h1>
      </div>
      <div *ngIf="document" class="flex justify-center items-center gap-3">
        <button
          matTooltip="Delete Extraction"
          [disabled]="isLoading || eventsLoading || insuranceLoading"
        >
          <mat-icon class="icon-size-5" svgIcon="heroicons_outline:trash"></mat-icon>
        </button>
        <button
          matTooltip="Publish all insurances and complete extraction"
          [disabled]="isLoading || eventsLoading || insuranceLoading || !canMarkAsComplete"
          (click)="markAsCompleted()"
        >
          <mat-icon class="icon-size-5" svgIcon="mat_outline:publish"></mat-icon>
        </button>
      </div>
    </div>
  
    <!-- Main Content -->
    <mat-drawer-container
      class="custom-drawer-container flex h-full max-h-[calc(100vh-220px)] overflow-auto"
    >
      <!-- Side Drawer -->
      <mat-drawer
        class="w-full sm:w-128 dark:bg-gray-900"
        mode="over"
        [opened]="false"
        position="end"
        [disableClose]="true"
        #matDrawer
      >
        <!-- Drawer Content Based on Context -->
        <ng-container [ngSwitch]="matDrawerContext">
          <!-- Extraction Form -->
          <ng-container *ngSwitchCase="'extraction'">
            <app-document-extraction-form
              [docExtraction]="document"
              [isAdmin]="true"
              (closeDetails)="closeDrawer()"
              (dataUpdated)="onDataUpdated()"
            ></app-document-extraction-form>
          </ng-container>
          <!-- Beneficiary Selection -->
          <ng-container *ngSwitchCase="'beneficiary'">
            <div class="flex flex-col justify-center p-4">
              <div class="flex justify-between items-center mb-4">
                <div class="text-lg font-semibold text-gray-700">
                  {{ insuredType === 'person' ? ('tasks.extraction.addBeneficiary' | transloco) : ('tasks.extraction.addInsuredEntity' | transloco) }}
                </div>
                <button (click)="closeDrawer()">
                  <mat-icon>close</mat-icon>
                </button>
              </div>

              <!-- Display SelectPersonComponent for Person -->
              <ng-container *ngIf="insuredType === 'person'">
                  <app-select-person
                      [allowAdhoc]="true"
                      [ownerId]="document?.user_id"
                      [selectedIds]="coveredPersonIds"
                      label="Select Person"
                      (onClickSave)="addBeneficiary($event, 'person')"
                  ></app-select-person>
              </ng-container>

              <!-- Display SelectObjectComponent for Object -->
              <ng-container *ngIf="insuredType === 'object'">
                  <app-select-object
                      [allowAdhoc]="true"
                      [ownerId]="document?.user_id"
                      [selectedIds]="coveredObjectIds"
                      label="Select Entity"
                      (onSaveObject)="addBeneficiary($event, 'object')"
                  ></app-select-object>
              </ng-container>

            </div>
          </ng-container>
          <!-- Insurance Details -->
          <ng-container *ngSwitchCase="'add-insurance'">
            <div class="flex flex-col justify-center p-4">
              <div class="flex justify-between items-center mb-8">
                <div class="text-lg font-semibold text-gray-700">Add Insurance</div>
                <button (click)="closeDrawer()">
                  <mat-icon>close</mat-icon>
                </button>
              </div>
              <app-add-insurance-form 
                [policyExtraction]="document"
                [existingInsurance]="selectedInsurance"
                [preSelectedCoveredEntityId]="selectedCoveredEntityId"
                [selectedCoveredEntityType]="selectedCoveredEntityType"
                [selectedObjectTypeId]="selectedObjectTypeId"
                (formCompleted)="handleInsuranceFormClose()"
              ></app-add-insurance-form>
            </div>
          </ng-container>
          <!-- Contract Data Form -->
          <ng-container *ngSwitchCase="'contract-data'">
            <div class="flex flex-col justify-center p-4">
              <div class="flex justify-between items-center mb-8">
                <div class="text-lg font-semibold text-gray-700">
                  {{ 'tasks.extraction.addDocData' | transloco }}
                </div>
                <button (click)="closeDrawer()">
                  <mat-icon>close</mat-icon>
                </button>
              </div>
              <app-contract-data-form
                [ownerId]="document?.user_id"
                [contractData]="document?.contract_data"
                (formSubmit)="updateContractData($event)"
              ></app-contract-data-form>
            </div>
          </ng-container>
        </ng-container>
      </mat-drawer>
  
      <!-- Main Drawer Content -->
      <mat-drawer-content class="h-full w-full bg-default">
        <div class="h-full w-full grid grid-cols-5 gap-4 px-4">
          <!-- PDF Viewer -->
          <div class="col-span-2 sticky top-14 pb-10">
            <pdf-view [downloadURL]="document?.path"></pdf-view>
          </div>
  
          <!-- Details Section -->
          <div
            class="col-span-3 p-4 max-h-[calc(100vh-260px)] overflow-auto bg-white rounded-md mt-5"
          >
            <!-- Contract Data Section -->
            <ng-container *ngIf="document?.contract_data">
              <div class="contract-data-section w-full mb-8">
                <div class="flex justify-start items-center border p-4 bg-gray-50">
                  <h2 class="text-lg font-bold">
                    {{ 'tasks.extraction.contractData' | transloco }} {{ document?.filename }}
                  </h2>
                </div>
                <div class="overflow-x-auto">
                  <table
                    mat-table
                    class="min-w-full divide-y divide-gray-200 relative"
                  >
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          {{ 'tasks.extraction.insurer' | transloco }}
                        </th>
                        <th
                          scope="col"
                          class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          {{ 'tasks.extraction.holder' | transloco }}
                        </th>
                        <th
                          scope="col"
                          class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          {{ 'tasks.extraction.contractDate' | transloco }}
                        </th>
                        <th
                          scope="col"
                          class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          {{ 'tasks.extraction.validity' | transloco }}
                        </th>
                        <th
                          scope="col"
                          class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          {{ 'tasks.extraction.periodicity' | transloco }}
                        </th>
                        <th
                          scope="col"
                          class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          {{ 'tasks.actions' | transloco }}
                        </th>
                      </tr>
                    </thead>
                    <tbody class="bg-white divide-y divide-gray-200">
                      <tr>
                        <td
                          class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                        >
                          {{ document.contract_data.insurer_id }}
                        </td>
                        <td
                          class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                        >
                          {{ document.contract_data.holder_id }}
                        </td>
                        <td
                          class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                        >
                          {{ document.contract_data.contract_date | date: 'dd.MM.yyyy' }}
                        </td>
                        <td
                          class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                        >
                          {{ document.contract_data.effective_date | date: 'dd.MM.yyyy' }}
                          -
                          {{ document.contract_data.expiration_date | date: 'dd.MM.yyyy' }}
                        </td>
                        <td
                          class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                        >
                        {{ document.contract_data.payment_periodicity }}
                        </td>
                        <td class="flex justify-center items-center gap-2 px-6 py-4">
                          <button
                            matTooltip="Edit"
                            [disabled]="isLoading"
                            (click)="openDrawer('contract-data')"
                          >
                            <mat-icon
                              class="icon-size-5"
                              svgIcon="heroicons_outline:pencil"
                            ></mat-icon>
                          </button>
                          <button
                            matTooltip="Delete"
                            [disabled]="isLoading"
                            (click)="updateContractData(null)"
                          >
                            <mat-icon
                              class="icon-size-5"
                              svgIcon="heroicons_outline:trash"
                            ></mat-icon>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </ng-container>
  
            <!-- Add Contract Data Button -->
            <button
              *ngIf="document && !document.contract_data"
              class="border-dashed border-2 text-md border-gray-300 rounded px-4 py-2 flex items-center gap-2 justify-center w-full mb-4"
              [disabled]="isLoading || eventsLoading || insuranceLoading"
              (click)="openDrawer('contract-data')"
            >
              {{ 'tasks.extraction.addDocData' | transloco }}
            </button>
  
            <!-- Beneficiaries Section -->
            <ng-container *ngFor="let coveredEntity of coveredEntities || []">
              <div class="beneficiary-section w-full mb-4">
                <div class="flex justify-between items-center border p-4 bg-gray-50">
                  <h2 *ngIf="coveredEntity.type === 'person'" class="text-lg font-bold">
                    {{ coveredEntity.full_name + ' ' + (coveredEntity.date_of_birth | date: 'dd.MM.yyyy') }}
                  </h2>
                  <h2 *ngIf="coveredEntity.type === 'object'" class="text-lg font-bold">
                    {{ coveredEntity.name }}
                  </h2>
                  <div class="flex justify-center items-center gap-2">
                    <button
                      [matTooltip]="coveredEntity.type === 'person' ? 'Delete beneficiary and their insurance.' : 'Delete insured entity and their insurance.'"
                      (click)="deleteBeneficiary(coveredEntity)"
                    >
                      <mat-icon class="icon-size-5" svgIcon="heroicons_outline:trash"></mat-icon>
                    </button>
                    <button
                      [matTooltip]="!insurancesGroupedByBeneficiary[coveredEntity.id]?.pendingCount ? null : 'Publish all insurances under this ' + (coveredEntity.type === 'person' ? 'beneficiary' : 'insured entity')"
                      [disabled]="!insurancesGroupedByBeneficiary[coveredEntity.id]?.pendingCount"
                      (click)="publishInsurancesByBeneficiary(coveredEntity.id)"
                    >
                      <mat-icon
                        class="icon-size-5"
                        svgIcon="mat_outline:published_with_changes"
                      ></mat-icon>
                    </button>
                  </div>
                </div>
                <!-- Insurances Table -->
                <div class="overflow-x-auto">
                  <table
                    mat-table
                    class="min-w-full divide-y divide-gray-200 relative"
                  >
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Type
                        </th>
                        <th
                          scope="col"
                          class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Holder
                        </th>
                        <th
                          scope="col"
                          class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Insurance Product
                        </th>
                        <th
                          scope="col"
                          class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Validity
                        </th>
                        <th
                          scope="col"
                          class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Net Premium
                        </th>
                        <th
                          scope="col"
                          class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody class="bg-white divide-y divide-gray-200">
                      <ng-container *ngIf=" insurancesGroupedByBeneficiary[coveredEntity.id]?.insurances?.length; else noInsurance">
                        <ng-container *ngFor="let insurance of insurancesGroupedByBeneficiary[coveredEntity.id].insurances">
                          <tr>
                            <td
                              class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                            >
                              {{ getLocalizedField(insurance, 'product_type_name') }}
                            </td>
                            <td
                              class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                            >
                              {{ insurance.holder_full_name }}
                            </td>
                            <td
                              class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                            >
                              {{ getLocalizedField(insurance, 'product_name') }}
                            </td>
                            <td
                              class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                            >
                              {{ insurance.effective_date | date: 'dd.MM.yyyy' }} -
                              {{ insurance.expiration_date | date: 'dd.MM.yyyy' }}
                            </td>
                            <td
                              class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                            >
                            {{ (insurance.premium.netCents / 100 | number:'1.2-2').replace(',', "'") }}
                            </td>
                            <td class="flex justify-center items-center gap-2 px-6 py-4">
                              <button
                                matTooltip="Edit"
                                (click)="editBeneficiaryInsurance(insurance)"
                              >
                                <mat-icon
                                  class="icon-size-5"
                                  svgIcon="heroicons_outline:pencil"
                                ></mat-icon>
                              </button>
                              <button
                                matTooltip="Duplicate"
                                (click)="duplicateBeneficiaryInsurance(insurance)"
                              >
                                <mat-icon class="icon-size-5" svgIcon="feather:copy"></mat-icon>
                              </button>
                              <button
                                matTooltip="Delete"
                                (click)="deleteInsurance(insurance)"
                              >
                                <mat-icon
                                  class="icon-size-5"
                                  svgIcon="heroicons_outline:trash"
                                ></mat-icon>
                              </button>
                              <button
                                [matTooltip]="
                                  insurance.captured_status === 'pending'
                                    ? 'Publish'
                                    : 'Unpublish'
                                "
                                (click)="updateInsuranceStatus(insurance)"
                              >
                                <mat-icon
                                  *ngIf="insurance.captured_status === 'pending'"
                                  class="icon-size-5"
                                  svgIcon="mat_outline:publish"
                                ></mat-icon>
                                <mat-icon
                                  *ngIf="insurance.captured_status === 'completed'"
                                  class="icon-size-5"
                                  svgIcon="mat_outline:unpublished"
                                ></mat-icon>
                              </button>
                            </td>
                          </tr>
                        </ng-container>
                      </ng-container>
                      <!-- No Insurance Template -->
                      <ng-template #noInsurance>
                        <tr>
                          <td
                            colspan="6"
                            class="px-6 py-4 whitespace-nowrap text-center text-sm text-gray-500"
                          >
                            No insurance policies found.
                          </td>
                        </tr>
                      </ng-template>
                    </tbody>
                  </table>
                </div>
                <!-- Add Insurance Button -->
                <div class="flex justify-center items-center border mt-4 p-2 rounded-sm">
                  <button
                    class="flex items-center px-2 py-1 text-sm text-secondary hover:text-black"
                    (click)="addBeneficiaryInsurance(coveredEntity)"
                  >
                    + Add Insurance
                  </button>
                </div>
              </div>
            </ng-container>
  
            <!-- Add Beneficiary Button -->
            <div class="flex justify-between items-center gap-3">
              <button
                *ngIf="document"
                class="border-dashed border-2 text-md border-gray-300 rounded px-4 py-2 flex items-center justify-center gap-2 w-full my-4 mb-8"
                [disabled]="isLoading || eventsLoading || insuranceLoading"
                (click)="openDrawer('beneficiary'); insuredType = 'person';"
              >
                {{ '+ ' + ('tasks.extraction.addBeneficiary' | transloco) }}
              </button>
              <button
                *ngIf="document"
                class="border-dashed border-2 text-md border-gray-300 rounded px-4 py-2 flex items-center justify-center gap-2 w-full my-4 mb-8"
                [disabled]="isLoading || eventsLoading || insuranceLoading"
                (click)="openDrawer('beneficiary'); insuredType = 'object';"
              >
                {{ '+ ' + ('tasks.extraction.addInsuredEntity' | transloco) }}
              </button>
            </div>
  
            <!-- Events Section -->
            <div class="activities-section w-full">
              <div *ngIf="events.length" class="flex justify-between items-center border p-4 bg-gray-50">
                <h2 class="text-lg font-bold">
                  {{ 'tasks.extraction.activities' | transloco }}
                </h2>
                <div class="flex justify-center items-center gap-2">
                  <button
                    [matTooltip]="!unpublishEventIds.length ? null : 'Publish all'"
                    [disabled]="!unpublishEventIds.length"
                    (click)="publishAllEvent()"
                  >
                    <mat-icon
                      class="icon-size-5"
                      svgIcon="mat_outline:published_with_changes"
                    ></mat-icon>
                  </button>
                </div>
              </div>
              <div *ngIf="events.length" class="overflow-x-auto">
                <table
                  mat-table
                  class="min-w-full divide-y divide-gray-200 relative"
                >
                  <div *ngIf="eventsLoading" class="absolute inset-x-0 top-0">
                    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                  </div>
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        {{ 'tasks.extraction.date' | transloco }}
                      </th>
                      <th
                        scope="col"
                        class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        {{ 'tasks.extraction.time' | transloco }}
                      </th>
                      <th
                        scope="col"
                        class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        {{ 'tasks.extraction.who' | transloco }}
                      </th>
                      <th
                        scope="col"
                        class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        {{ 'tasks.extraction.what' | transloco }}
                      </th>
                      <th
                        scope="col"
                        class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        {{ 'tasks.extraction.settings' | transloco }}
                      </th>
                      <th
                        scope="col"
                        class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        {{ 'tasks.actions' | transloco }}
                      </th>
                    </tr>
                  </thead>
                  <tbody class="bg-white divide-y divide-gray-200">
                    <ng-container *ngFor="let event of events">
                      <tr>
                        <td
                          class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                        >
                          {{ event.created_at | date: 'dd.MM.yyyy' }}
                        </td>
                        <td
                          class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                        >
                          {{ event.created_at | date: 'HH:mm' }}
                        </td>
                        <td
                          class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                        >
                          <!-- Assuming event.created_by has user details -->
                          {{ event.created_by.full_name }}
                        </td>
                        <td
                          class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 truncate max-w-80"
                          [innerHTML]="event | eventMessage"
                          [matTooltip]="event | eventMessage | stripHtml"
                        >
                        </td>
                        <td
                          class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 capitalize"
                        >
                          {{ event.status || 'unpublished' }}
                        </td>
                        <td class="flex justify-center items-center gap-2 px-6 py-4">
                          <button
                            [matTooltip]="
                              !event.status || event.status === 'unpublished'
                                ? 'Publish'
                                : 'Unpublish'
                            "
                            (click)="updateEventStatus(event)"
                          >
                            <mat-icon
                              *ngIf="!event.status || event.status === 'unpublished'"
                              class="icon-size-5"
                              svgIcon="mat_outline:publish"
                            ></mat-icon>
                            <mat-icon
                              *ngIf="event.status && event.status === 'published'"
                              class="icon-size-5"
                              svgIcon="mat_outline:unpublished"
                            ></mat-icon>
                          </button>
                        </td>
                      </tr>
                    </ng-container>
                  </tbody>
                </table>
              </div>
              <!-- Add Comment Button -->
              <div class="flex justify-center items-center border mt-4 p-2 rounded-sm">
                <button
                  class="flex items-center px-2 py-1 text-sm text-secondary hover:text-black"
                  [disabled]="!document"
                  (click)="openDrawer('extraction')"
                >
                  {{ 'tasks.updateTask' | transloco }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </mat-drawer-content>
    </mat-drawer-container>
  </div>
  