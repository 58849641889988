import {
    Component,
    Input,
    Output,
    OnInit,
    EventEmitter,
    ChangeDetectorRef,
    ChangeDetectionStrategy,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { TranslocoModule } from '@ngneat/transloco';

import { PersonService } from 'app/common/supabase-services/person.service';
import { ObjectService } from 'app/common/supabase-services/object.service';
import { Person } from 'app/common/supabase-models/person';
import { Object } from 'app/common/supabase-models/object';

import { PersonFormComponent } from 'app/common/components/person-form/person-form.component';
import { SelectObjectComponent } from '../select-object/select-object.component';

@Component({
    selector: 'app-select-holder',
    templateUrl: './select-holder.component.html',
    styleUrls: ['./select-holder.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        MatSelectModule,
        MatButtonModule,
        TranslocoModule,
        MatFormFieldModule,
        PersonFormComponent,
        SelectObjectComponent,
    ],
})
export class SelectHolderComponent implements OnInit {
    @Input() ownerId: string;
    @Input() entityId: number | null = null;
    @Input() allowAdhoc: boolean = true;
    @Input() label: string = 'Select Holder';
    @Input() disabled: boolean = false;
    @Output() onHolderChange = new EventEmitter<{ type: 'person' | 'entity'; data: Person | Object }>();
    @Output() adhocToggle = new EventEmitter<boolean>();

    personHolders: { type: 'person'; data: Person }[] = [];
    entityHolders: { type: 'entity'; data: Object }[] = [];
    selectedHolder: { type: 'person' | 'entity'; data: Person | Object } | null = null;
    showPersonForm: boolean = false;
    showEntityForm: boolean = false;

    constructor(
        private personService: PersonService,
        private objectService: ObjectService,
        private changeDetectorRef: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.loadHolders();
    }

    loadHolders() {
        if (!this.ownerId) {
            this.personHolders = [];
            this.entityHolders = [];
            return;
        }
    
        const persons$: Observable<{ type: 'person'; data: Person }[]> = this.personService
            .getAllByOwnerId(this.ownerId)
            .pipe(map((persons: Person[]) => persons.map(person => ({ type: 'person', data: person }))));
    
        const entities$: Observable<{ type: 'entity'; data: Object }[]> = this.objectService
            .getAllByObjectTypeAndOwner(3, this.ownerId)
            .pipe(map((objects: Object[]) => objects.map(object => ({ type: 'entity', data: object }))));
    
        combineLatest([persons$, entities$]).subscribe(([persons, entities]) => {
            this.personHolders = persons;
            this.entityHolders = entities;

            if (this.entityId) {
                this.selectedHolder = [...this.personHolders, ...this.entityHolders].find(holder => holder.data.id === this.entityId) || null;
            }

            this.changeDetectorRef.markForCheck();
        });
    }

    onSelectHolder(selected: { type: 'person' | 'entity'; data: Person | Object } | number) {
        if (!this.disabled) {
            if (typeof selected === 'number' && this.allowAdhoc) {
                if (selected === -1) {
                    this.showPersonForm = true;
                    this.adhocToggle.emit(true);
                } else if (selected === -2) {
                    this.showEntityForm = true;
                    this.adhocToggle.emit(true);
                }
            } else if (typeof selected !== 'number') {
                this.selectedHolder = selected;
                this.onHolderChange.emit(selected);
            }
        }
    }

    onSavePersonForm(person: Person) {
        this.selectedHolder = { type: 'person', data: person };
        this.onHolderChange.emit(this.selectedHolder);
        this.showPersonForm = false;
        this.loadHolders();
    }
    
    onCancelPersonForm() {
        this.selectedHolder = null;
        this.adhocToggle.emit(false);
        this.showPersonForm = false;
        this.loadHolders();
    }

    closeEntity(entity?: Object) {
        if (entity) {
            this.selectedHolder = { type: 'entity', data: entity };
            this.onHolderChange.emit(this.selectedHolder);
        } else {
            this.selectedHolder = null;
            this.adhocToggle.emit(false);
        }
        this.showEntityForm = false;
        this.loadHolders();
    }

    trackById(index: number, item: { type: 'person' | 'entity'; data: Person | Object }) {
        return item.data.id;
    }
}