import { Injectable } from '@angular/core';
import { SupabaseClient } from '@supabase/supabase-js';
import { from, Observable, throwError, forkJoin } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Insurance } from '../supabase-models/insurance';
import { SupabaseClientService } from './supabase-client.service';
import { EventLogService } from './event-log.service';

@Injectable({
    providedIn: 'root',
})
export class InsuranceService {
    private supabase: SupabaseClient;

    constructor(
        private supabaseClientService: SupabaseClientService,
        private eventLogService: EventLogService // Importing the EventLogService
    ) {
        this.supabase = this.supabaseClientService.getClient();
    }

    // Create a new insurance record
    create(insurance: Insurance): Observable<Insurance> {
        return from(this.supabase.from('insurance').insert([insurance])).pipe(
            map((response) => {
                if (response.error) {
                    throw new Error(response.error.message);
                }
                const data = response.data as Insurance[] | undefined;
                return data && data.length > 0 ? data[0] : insurance;
            }),
            catchError((error) => throwError(() => new Error(error.message)))
        );
    }

    // Get insurance by ID
    getById(id: number): Observable<Insurance> {
        return from(
            this.supabase.from('insurance').select('*').eq('id', id).single()
        ).pipe(
            map((response) => response.data as Insurance),
            catchError((error) => throwError(() => new Error(error.message)))
        );
    }

    // Update insurance by ID
    update(id: number, insurance: Partial<Insurance>): Observable<Insurance> {
        return from(this.supabase.from('insurance').update(insurance).eq('id', id)).pipe(
            map((response) => {
                if (response.error) {
                    throw new Error(response.error.message);
                }
                const data = response.data as Insurance[] | undefined;
                return data && data.length > 0 ? data[0] : (insurance as Insurance);
            }),
            catchError((error) => throwError(() => new Error(error.message)))
        );
    }

    // Delete insurance by ID
    delete(id: number): Observable<void> {
        return from(this.supabase.from('insurance').delete().eq('id', id)).pipe(
            map(() => void 0),
            catchError((error) => throwError(() => new Error(error.message)))
        );
    }

    // Delete multiple insurances by IDs
    deleteMultiple(ids: number[]): Observable<void> {
        return from(this.supabase.from('insurance').delete().in('id', ids)).pipe(
            map(() => void 0),
            catchError((error) => throwError(() => new Error(error.message)))
        );
    }

    // Update the capture status of multiple insurance records
    updateCaptureStatus(
        insuranceIds: number[],
        newStatus: 'pending' | 'completed'
    ): Observable<void[]> {
        const updates$ = insuranceIds.map((insuranceId) => {
            return this.getById(insuranceId).pipe(
                switchMap((prevData) => {
                    if (!prevData) {
                        throw new Error('Document does not exist!');
                    }

                    const newData: Partial<Insurance> = {
                        ...prevData,
                        captured_status: newStatus,
                        updated_at: new Date().toISOString(),
                    };

                    // Update the document with the new status
                    return this.update(insuranceId, newData).pipe(
                        // Once the document is updated, create the event log
                        switchMap(() => {
                            return this.eventLogService.createUpdateEvent(
                                prevData,
                                newData,
                                insuranceId,
                                prevData.creator_person_id,
                                'insurances'
                            );
                        }),
                        // Map to void to comply with Observable<void[]> return type, ignoring the event creation result
                        map(() => {})
                    );
                })
            );
        });

        // Combine all observables and wait for them to complete
        return forkJoin(updates$);
    }
}
