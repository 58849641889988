import { Injectable } from '@angular/core';
import { SupabaseClient } from '@supabase/supabase-js';
import { from, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { CoverageGroupWithMembers } from '../supabase-models/coverage-group-with-members';
import { SupabaseClientService } from './supabase-client.service';

@Injectable({
    providedIn: 'root',
})
export class CoverageGroupWithMembersService {
    private supabase: SupabaseClient;

    constructor(private supabaseClientService: SupabaseClientService) {
        this.supabase = this.supabaseClientService.getClient();
    }

    /**
     * Get all coverage groups with their members
     */
    getAll(): Observable<CoverageGroupWithMembers[]> {
        return from(this.supabase.from('coverage_group_with_members').select('*'))
            .pipe(
                map((response) => {
                    if (response.data) {
                        return response.data as CoverageGroupWithMembers[];
                    }
                    throw new Error('No coverage groups found');
                }),
                catchError((error) => throwError(() => new Error(error.message)))
            );
    }

    /**
     * Get coverage groups filtered by owner_id
     * @param ownerId The owner ID to filter the records
     */
    getByOwnerId(ownerId: string): Observable<CoverageGroupWithMembers[]> {
        return from(this.supabase
            .from('coverage_group_with_members')
            .select('*')
            .eq('owner_id', ownerId)
        ).pipe(
            map((response) => {
                if (response.data) {
                    return response.data as CoverageGroupWithMembers[];
                }
                throw new Error(`No coverage groups found for owner_id: ${ownerId}`);
            }),
            catchError((error) => throwError(() => new Error(error.message)))
        );
    }

    /**
     * Retrieve all records by owner ID
     * @param ownerId Owner ID to filter records
     */
    getAllByOwnerId(ownerId: string): Observable<CoverageGroupWithMembers[]> {
        return from(
            this.supabase.from('coverage_group_with_members').select('*').eq('owner_id', ownerId)
        ).pipe(
            map((response) => {
                if (response.data) {
                    return response.data as CoverageGroupWithMembers[];
                }
                throw new Error(`No records found for owner ID: ${ownerId}`);
            }),
            catchError((error) => throwError(() => new Error(error.message)))
        );
    }
}
