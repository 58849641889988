<div *ngIf="true || insurer?.localizations">
    <div class="overflow-hidden w-full">
        <form [formGroup]="form" (submit)="save($event)" class="px-4">
            <div formArrayName="localizations" class="col-span-1 font-semibold px-1">
                <div *ngFor="let lang of languages.controls; let langIndex=index">
                    <div class="grid grid-cols-1 sm:grid-cols-10 gap-4 py-4 border-b items-center" [formGroupName]="langIndex">
                        <div class="col-span-3 flex justify-between items-center gap-2">
                            <div class="font-semibold">
                                {{ lang.get('language').value.toUpperCase() }}
                            </div>
                            <img [src]="lang.get('preview').value || lang.get('logo').value" class="max-w-20 cursor-pointer" title="Click to upload new logo" (click)="logoInput.click()" *ngIf="lang.get('preview').value || lang.get('logo').value; else noImage" />
                            <ng-template #noImage>
                                <div class="text-center font-semibold">{{'insurers.noImage' | transloco}}</div>
                            </ng-template>
                            <mat-icon class="text-green-500 cursor-pointer" title="Click to upload new logo" svgIcon="heroicons_outline:arrow-up-tray" (click)="logoInput.click()"></mat-icon>
                        </div>
                        <mat-form-field class="col-span-1 sm:col-span-7 no-hint">
                            <input matInput formControlName="name" [placeholder]="('insurers.nameIn' | transloco) + ' ' + lang.get('language').value">
                        </mat-form-field>
                    </div>
                    <input hidden type="file" #logoInput (change)="patchLogo(lang, $event)" accept="image/*" />
                </div>
            </div>
            <div class="flex justify-end items-center pt-4 pb-1 gap-2">
                <button type="button" class="px-6" mat-stroked-button [disabled]="processing" (click)="cancel()">{{'insurers.cancel' | transloco}}</button>
                <button type="submit" class="px-8" mat-flat-button color="primary" [disabled]="processing">
                    {{ processing ? translocoService.translate('insurers.saving') : translocoService.translate('insurers.save') }}
                </button>
            </div>
        </form>
    </div>
</div>