<form class="flex flex-col gap-2" [formGroup]="form" (submit)="save()">
    <!-- Group Name -->
    <mat-form-field class="w-full" subscriptSizing="dynamic" appearance="outline">
        <mat-label>{{ 'coverageGroupForm.groupName' | transloco }}</mat-label>
        <input matInput formControlName="name" required />
    </mat-form-field>

    <!-- Description -->
    <mat-form-field class="w-full" subscriptSizing="dynamic" appearance="outline">
        <mat-label>{{ 'coverageGroupForm.description' | transloco }}</mat-label>
        <textarea matInput formControlName="description" rows="4"></textarea>
    </mat-form-field>

    <!-- Select Members (Mat Multi-Select) -->
    <mat-form-field class="w-full" subscriptSizing="dynamic" appearance="outline">
        <mat-label>{{ 'coverageGroupForm.members' | transloco }}</mat-label>
        <mat-select [multiple]="true" [(value)]="members">
            <mat-option *ngFor="let person of allPersons$ | async" [value]="person">
                {{ person.full_name }}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <!-- Actions -->
    <div class="flex items-center justify-end gap-2 mt-6">
        <button type="button" mat-stroked-button (click)="cancel()">
            {{ 'admin.docUploads.cancel' | transloco }}
        </button>
        <button type="submit" mat-flat-button color="primary" [disabled]="!form.valid">
            {{ translateButton() }}
        </button>
    </div>
</form>
