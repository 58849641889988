<div *ngIf="downloadURL; else loadingSpinner" class="w-full h-full relative" (textSelected)="onTextSelect($event)">
    <button [cdkMenuTriggerFor]="pdfContextMenu"></button>
    <ngx-extended-pdf-viewer [src]="downloadURL" [handTool]="false" [useBrowserLocale]="true" [textLayer]="true">
    </ngx-extended-pdf-viewer>
    <ng-template #pdfContextMenu>
        <div cdkMenu class="cdk-menu">
            <button class="cdk-menu-item" cdkMenuItem *ngFor="let field of formFields | keyvalue; trackBy: trackByFn"
                (click)="onContextMenuClick(field.value)">
                {{ field.value.name }}
            </button>
        </div>
    </ng-template>
</div>
<ng-template #loadingSpinner>
    <div class="flex justify-center items-center h-full relative">
        <div class="absolute inset-0 bg-gray-500 bg-opacity-50 flex justify-center items-center rounded">
            <mat-progress-spinner [diameter]="40" mode="indeterminate"></mat-progress-spinner>
        </div>
    </div>
</ng-template>