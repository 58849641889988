import { Component, Input, ViewChild, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
    StorageReference,
    Storage,
    ref,
    getDownloadURL,
    getStorage,
} from '@angular/fire/storage';
import { FormControl } from '@angular/forms';
import { CdkMenuModule, CdkMenuTrigger } from '@angular/cdk/menu';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { DocumentDirective } from 'app/common/directives/document.directive';
import { PdfViewService, pdfContextMenuType, menuItem } from './pdf-view.service';
import { InventoryService } from 'app/modules/admin/apps/ecommerce/inventory/inventory.service';

// Improvement. Move to another file.
type FormFields = {
    [key: string]: { key: string; name: string; formControl: FormControl };
};

@Component({
    selector: 'pdf-view',
    standalone: true,
    templateUrl: './pdf-view.component.html',
    styleUrls: ['./pdf-view.component.scss'],
    imports: [
        CommonModule,
        MatProgressSpinnerModule,
        NgxExtendedPdfViewerModule,
        CdkMenuModule,
        CdkMenuTrigger,
        DocumentDirective,
    ],
})
export class PdfViewComponent implements OnInit {
    @ViewChild(CdkMenuTrigger) pdfContextMenu: CdkMenuTrigger;
    @Input() downloadURL: string;
    private storage: Storage = getStorage();
    private _selection: string = null;
    formFields: FormFields = {};

    constructor(
        private _pdfViewService: PdfViewService,
        private insuranceService: InventoryService
    ) {}

    ngOnInit(): void {}

    getPDF(path: string) {
        const storageRef: StorageReference = ref(this.storage, path);
        getDownloadURL(storageRef).then((url) => {
            this.downloadURL = url;
        });
    }

    onTextSelect(text: string) {
        // Improvement. Centralize, brittle when changing the form name.
        // Focused on Health Insurance first.
        const whiteListFieldNameContext = [
            'type',
            'holder.name',
            'referenceNumber', // <-- Policy Number.
            'premium',
            'effectiveDate',
            'expirationDate',
            'beneficiary.name',
            'details.information',
        ];

        this.formFields = this.whiteListFields(
            // Improvement. Provide types.
            this.insuranceService.insuranceFormFields,
            whiteListFieldNameContext
        );
        this._selection = text;
        this.pdfContextMenu.open();
    }

    onContextMenuClick(menu: menuItem) {
        const data = {
            menu: menu,
            selection: this.fieldValueFilter(menu.key, this._selection),
        } as pdfContextMenuType;

        this._pdfViewService._contextMenu.next(data);
    }

    /**
     *
     * @param key
     * @param selection
     * @returns
     */
    fieldValueFilter(key: string, selection: string) {
        if (key.includes('Date') || key.includes('dob') || key.includes('birthday')) {
            return new Date(this._selection);
        } else if (key === 'premium') {
            return Number(this._selection.replace(/\D/g, ''));
        } else {
            return this._selection;
        }
    }

    trackByFn(index, value: FormFields) {
        return index;
    }

    whiteListFields(formFields: FormFields, whiteListKeys: string[]): FormFields {
        const result: FormFields = {};

        for (const key of whiteListKeys) {
            if (formFields[key]) {
                result[key] = formFields[key];
            }
        }

        return result;
    }
}
