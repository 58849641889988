import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
} from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslocoService, TranslocoModule } from '@ngneat/transloco';
import { MatTabsModule, MatTabChangeEvent } from '@angular/material/tabs';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Subscription, Subject, firstValueFrom } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { SelectAdminUserComponent } from '../select-admin-user/select-admin-user.component';

import { PolicyExtractionService } from '../../supabase-services/policy-extraction.service';
import { PersonWithSettingService } from '../../supabase-services/person-with-setting.service';
import { EventLogService } from '../../supabase-services/event-log.service';
import { DeviceService } from '../../services/device.service';

import { EventMessagePipe } from '../../pipes/event-message.pipe';
import { generateUUID } from '../../../core/helpers/helper';
import { ToDatePipe } from '../../pipes/to-date.pipe';

import { PersonWithSetting as CurrentUser } from '../../supabase-models/person-with-setting';
import { PolicyExtractionView } from '../../supabase-models/policy-extraction-view';
import { Status, Urgency } from '../../supabase-models/policy-extraction';
import { EventLog } from '../../supabase-models/event-log';
import {
  Comment,
  LangCode,
  CommentType,
  StatusMapping,
  CommentTypeMapping,
} from '../../supabase-models/common';

@Component({
  selector: 'app-document-extraction-form',
  templateUrl: './document-extraction-form.component.html',
  styleUrls: ['./document-extraction-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
      // Angular and Material modules
      FormsModule,
      CommonModule,
      MatIconModule,
      MatTabsModule,
      MatInputModule,
      TranslocoModule,
      MatButtonModule,
      MatSelectModule,
      MatTooltipModule,
      MatFormFieldModule,
      MatProgressSpinnerModule,
      // Custom components and pipes
      SelectAdminUserComponent,
      EventMessagePipe,
      ToDatePipe,
  ],
})
export class DocumentExtractionFormComponent implements OnInit, OnChanges, OnDestroy {
  @Input() docExtraction: PolicyExtractionView;
  @Input() isAdmin: boolean = true;
  @Output() closeDetails: EventEmitter<void> = new EventEmitter<void>();
  @Output() dataUpdated: EventEmitter<void> = new EventEmitter<void>();

  private _unsubscribeAll: Subject<any> = new Subject<any>();
  private languageSubscription: Subscription;
  private user: CurrentUser;

  events: EventLog[] = [];
  tempStatus: Status;
  tempUrgency: Urgency;
  tempAssigneeId: string;
  newComment: Comment = {
      id: '',
      user: null,
      type: 'general',
      description: '',
      created_at: new Date().toISOString(),
      updated_at: new Date().toISOString(),
  };
  editStatus: boolean = false;
  editUrgency: boolean = false;
  editAssignee: boolean = false;
  allStatusOptions: Status[] = ['pending', 'in-progress', 'completed'];
  activityOptions: CommentType[] = ['general', 'insurer-contact', 'admin-update'];
  activityTypeMapping = CommentTypeMapping;
  statusMapping = StatusMapping;
  isLoading: boolean = false;
  isMobile: boolean = false;
  activityType: CommentType = 'general';
  currentLanguage: LangCode;
  assigneeName: string;

  constructor(
      private eventLogService: EventLogService,
      private deviceService: DeviceService,
      private translocoService: TranslocoService,
      private changeDetectorRef: ChangeDetectorRef,
      private policyExtractionService: PolicyExtractionService,
      private personWithSettingService: PersonWithSettingService
  ) {
      this.personWithSettingService.personWithSetting$
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe((user: CurrentUser) => {
              this.user = user;
          });

      this.languageSubscription = this.translocoService.langChanges$.subscribe(
          (lang: string) => {
              this.currentLanguage = lang.slice(0, 2).toLowerCase() as LangCode;
          }
      );
  }

  ngOnInit() {
      this.isMobile = this.deviceService.isMobile();
      this.sortCommentsByLatest();
      if (this.docExtraction) {
          const recordId = this.docExtraction.policy_extraction_id;
          const ownerId = this.docExtraction.user_id;
          this.fetchEvents(recordId, ownerId);
          this.fetchAssigneeName();
      }
  }

  ngOnChanges(changes: SimpleChanges): void {
      if (changes.docExtraction && changes.docExtraction.currentValue) {
          const recordId = this.docExtraction.policy_extraction_id;
          const ownerId = this.docExtraction.user_id;
          this.fetchEvents(recordId, ownerId);
          this.fetchAssigneeName();
      }
  }

  private fetchAssigneeName(): void {
      if (this.docExtraction.assignee_id) {
          this.personWithSettingService
              .getPersonWithSettingsByUserId(this.docExtraction.assignee_id)
              .subscribe({
                  next: (assignee) => {
                      this.assigneeName = assignee.full_name;
                      this.changeDetectorRef.markForCheck();
                  },
                  error: (error) => {
                      console.error('Error fetching assignee details:', error);
                  },
              });
      } else {
          this.assigneeName = 'Unassigned';
      }
  }

  private fetchEvents(recordId: number, ownerId: string): void {
      this.isLoading = true;
      if (!this.isAdmin) {
          this.eventLogService
              .getEventsByRecordIdOwnerIdAndStatus(recordId, ownerId, 'published')
              .pipe(takeUntil(this._unsubscribeAll))
              .subscribe((events) => {
                  this.events = events.map((event) => ({
                      ...event,
                      language: this.currentLanguage,
                  }));
                  this.isLoading = false;
                  this.changeDetectorRef.detectChanges();
              });
      } else {
          this.eventLogService
              .getEventsByRecordIdAndOwnerId(recordId, ownerId)
              .pipe(takeUntil(this._unsubscribeAll))
              .subscribe((events) => {
                  this.events = events.map((event) => ({
                      ...event,
                      language: this.currentLanguage,
                  }));
                  this.isLoading = false;
                  this.changeDetectorRef.detectChanges();
              });
      }
  }

  private fetchComments(): void {
      this.isLoading = true;
      this.policyExtractionService
          .getById(this.docExtraction.policy_extraction_id)
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe({
              next: (extraction) => {
                  this.docExtraction.comments = extraction.comments || [];
                  this.sortCommentsByLatest();
                  this.isLoading = false;
                  this.changeDetectorRef.markForCheck();
              },
              error: (error) => {
                  console.error('Error fetching comments:', error);
                  this.isLoading = false;
              },
          });
  }

  onTabChange(event: MatTabChangeEvent): void {
      if (event.tab.textLabel === 'Activities') {
          // Re-fetch events when the Activities tab is selected
          if (this.docExtraction) {
              const recordId = this.docExtraction.policy_extraction_id;
              const ownerId = this.docExtraction.user_id;
              this.fetchEvents(recordId, ownerId);
          }
      } else if (event.tab.textLabel === 'Comments') {
          // Re-fetch comments when the Comments tab is selected
          if (this.docExtraction) {
              this.fetchComments();
          }
      }
  }

  // Method to start editing the policy extraction's status
  startEditStatus() {
      this.tempStatus = this.docExtraction.status;
      this.editStatus = true;
  }

  // Method to cancel editing the policy extraction's status
  cancelEditStatus() {
      this.editStatus = false;
  }

  toggleEditUrgency() {
      this.editUrgency = !this.editUrgency;
      if (this.editUrgency) {
          this.tempUrgency = this.docExtraction?.urgency || 'low';
      }
  }

  toggleEditAssignee() {
      this.editAssignee = !this.editAssignee;
      if (this.editAssignee) {
          this.tempAssigneeId = this.docExtraction?.assignee_id;
      }
  }

  handleAssigneeChange(assigneeId: string) {
      this.tempAssigneeId = assigneeId;
  }

  sortCommentsByLatest(): void {
      if (this.docExtraction && this.docExtraction.comments) {
          this.docExtraction.comments.sort((a, b) => {
              const dateA = new Date(a.created_at).getTime();
              const dateB = new Date(b.created_at).getTime();
              return dateB - dateA;
          });
      }
  }

  // Method to save the updated status of the policy extraction
  async updateStatus() {
      if (this.tempStatus !== this.docExtraction.status) {
          this.isLoading = true;
          const previousStatus = this.docExtraction.status;
          try {
              await this.policyExtractionService
                  .update(this.docExtraction.policy_extraction_id, { status: this.tempStatus })
                  .toPromise();
              // Update the local state
              this.docExtraction.status = this.tempStatus;
              // Create an event log for status update
              await this.eventLogService
                  .createUpdateEvent(
                      { status: previousStatus },
                      { status: this.tempStatus },
                      this.docExtraction.policy_extraction_id,
                      this.docExtraction.user_id,
                      'policy-extractions'
                  )
                  .toPromise();
              this.dataUpdated.emit();
              this.changeDetectorRef.markForCheck();
          } catch (error) {
              console.error('Error updating policy extraction status:', error);
          } finally {
              this.editStatus = false;
              this.isLoading = false;
          }
      } else {
          this.editStatus = false;
      }
  }

  async updateUrgency() {
      if (this.tempUrgency !== this.docExtraction?.urgency) {
          this.isLoading = true;
          const previousUrgency = this.docExtraction.urgency;
          try {
              await this.policyExtractionService
                  .update(this.docExtraction.policy_extraction_id, { urgency: this.tempUrgency })
                  .toPromise();
              // Update the local state
              this.docExtraction.urgency = this.tempUrgency;
              // Create an event log for urgency update
              await this.eventLogService
                  .createUpdateEvent(
                      { urgency: previousUrgency },
                      { urgency: this.tempUrgency },
                      this.docExtraction.policy_extraction_id,
                      this.docExtraction.user_id,
                      'policy-extractions'
                  )
                  .toPromise();
              this.dataUpdated.emit();
              this.changeDetectorRef.markForCheck();
          } catch (error) {
              console.error('Error updating extraction urgency:', error);
          } finally {
              this.editUrgency = false;
              this.isLoading = false;
          }
      } else {
          this.editUrgency = false;
      }
  }

  async updateAssignee() {
      if (this.tempAssigneeId !== this.docExtraction?.assignee_id) {
          this.isLoading = true;
          const previousAssigneeId = this.docExtraction.assignee_id;
          try {
              await this.policyExtractionService
                  .update(this.docExtraction.policy_extraction_id, { assignee_id: this.tempAssigneeId })
                  .toPromise();
              // Update the local state
              this.docExtraction.assignee_id = this.tempAssigneeId;
              // Fetch the assignee's name
              this.fetchAssigneeName();
              // Create an event log for assignee update
              await this.eventLogService
                  .createUpdateEvent(
                      { assignee_id: previousAssigneeId },
                      { assignee_id: this.tempAssigneeId },
                      this.docExtraction.policy_extraction_id,
                      this.docExtraction.user_id,
                      'policy-extractions'
                  )
                  .toPromise();
              this.dataUpdated.emit();
              this.changeDetectorRef.markForCheck();
          } catch (error) {
              console.error('Error updating extraction assignee:', error);
          } finally {
              this.editAssignee = false;
              this.isLoading = false;
          }
      } else {
          this.editAssignee = false;
      }
  }

  async addComment() {
      if (this.newComment.description.trim()) {
          this.isLoading = true;
          this.newComment.id = generateUUID();
          this.newComment.type = !this.isAdmin ? 'user-message' : this.activityType;
          this.newComment.user = {
              id: this.user.user_id,
              full_name: this.user.full_name,
              email: this.user.email,
              photo_url: this.user?.photo,
          };
          this.newComment.created_at = new Date().toISOString();
          this.newComment.updated_at = new Date().toISOString();

          try {
              // Fetch the current extraction
              const currentExtraction = await firstValueFrom(
                  this.policyExtractionService.getById(this.docExtraction.policy_extraction_id)
              );

              // Update the comments array
              const updatedComments = [...(currentExtraction.comments || []), this.newComment];

              // Update the extraction with new comments
              await firstValueFrom(
                  this.policyExtractionService.update(this.docExtraction.policy_extraction_id, {
                      comments: updatedComments,
                  })
              );

              // Update local state
              this.docExtraction.comments = updatedComments;
              this.sortCommentsByLatest();

              // Create an event log for adding a comment
              await firstValueFrom(
                  this.eventLogService.createCommentEvent(
                      this.docExtraction.policy_extraction_id,
                      this.docExtraction.user_id,
                      'policy-extractions',
                      {
                          id: this.newComment.id,
                          type: 'created',
                          description: this.newComment.description,
                      }
                  )
              );

              this.dataUpdated.emit();
              this.resetNewActivityForm();
              this.changeDetectorRef.markForCheck();
          } catch (error) {
              console.error('Error adding comment:', error);
          } finally {
              this.isLoading = false;
          }
      }
  }

  resetNewActivityForm() {
      this.newComment = {
          id: '',
          user: null,
          type: 'general',
          description: '',
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString(),
      };
  }

  onClickClose(): void {
      this.closeDetails.emit();
  }

  ngOnDestroy() {
      this._unsubscribeAll.next(null);
      this._unsubscribeAll.complete();
      if (this.languageSubscription) {
          this.languageSubscription.unsubscribe();
      }
  }
}