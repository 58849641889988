import { Injectable } from '@angular/core';
import { SupabaseClient } from '@supabase/supabase-js';
import { SupabaseClientService } from './supabase-client.service';
import { PersonWithSetting } from '../supabase-models/person-with-setting';
import { Observable, from } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class AdminUserService {
    private supabase: SupabaseClient;

    constructor(private supabaseClientService: SupabaseClientService) {
        this.supabase = this.supabaseClientService.getClient();
    }

    getAdminUsers(): Observable<PersonWithSetting[]> {
        return from(
            this.supabase
                .from('person_with_settings')
                .select('*')
                .contains('roles', ['admin'])
        ).pipe(
            map((response) => {
                if (response.error) {
                    throw response.error;
                }
                return response.data as PersonWithSetting[];
            }),
            catchError((error) => {
                console.error('Error fetching admin users:', error);
                throw error;
            })
        );
    }
}
