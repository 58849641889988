import { Injectable } from '@angular/core';
import { SupabaseClient } from '@supabase/supabase-js';
import { from, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { CoverageGroup } from '../supabase-models/coverage-group';
import { SupabaseClientService } from './supabase-client.service';

@Injectable({
    providedIn: 'root',
})
export class CoverageGroupService {
    private supabase: SupabaseClient;

    constructor(private supabaseClientService: SupabaseClientService) {
        this.supabase = this.supabaseClientService.getClient();
    }

    /**
     * Create a new coverage group
     * @param entity Coverage group data to insert
     */
    create(entity: CoverageGroup): Observable<CoverageGroup> {
        return from(this.supabase.from('coverage_group').insert([entity]).select())
            .pipe(
                map((response) => {
                    if (response.data && response.data.length > 0) {
                        return response.data[0] as CoverageGroup;
                    }
                    throw new Error('Failed to create coverage group');
                }),
                catchError((error) => throwError(() => new Error(error.message)))
            );
    }

    /**
     * Retrieve a coverage group by ID
     * @param id ID of the coverage group
     */
    getById(id: number): Observable<CoverageGroup> {
        return from(this.supabase.from('coverage_group').select('*').eq('id', id).single())
            .pipe(
                map((response) => response.data as CoverageGroup),
                catchError((error) => throwError(() => new Error(error.message)))
            );
    }

    /**
     * Update a coverage group by ID
     * @param id ID of the coverage group
     * @param entity Updated data
     */
    update(id: number, entity: Partial<CoverageGroup>): Observable<CoverageGroup> {
        return from(this.supabase.from('coverage_group').update(entity).eq('id', id).select())
            .pipe(
                map((response) => {
                    if (response.error) {
                        throw new Error(response.error.message);
                    }
                    return response.data[0] as CoverageGroup;
                }),
                catchError((error) => throwError(() => new Error(error.message)))
            );
    }

    /**
     * Delete a coverage group by ID
     * @param id ID of the coverage group
     */
    delete(id: number): Observable<void> {
        return from(this.supabase.from('coverage_group').delete().eq('id', id))
            .pipe(
                map(() => void 0),
                catchError((error) => throwError(() => new Error(error.message)))
            );
    }

    /**
     * Retrieve all coverage groups
     */
    getAll(): Observable<CoverageGroup[]> {
        return from(this.supabase.from('coverage_group').select('*'))
            .pipe(
                map((response) => response.data as CoverageGroup[]),
                catchError((error) => throwError(() => new Error(error.message)))
            );
    }
}
