<mat-form-field appearance="outline" class="w-full">
  <mat-label>{{ label }}</mat-label>
  <mat-select 
    [(ngModel)]="selectedProduct" 
    [compareWith]="compareProdWithFunction"
    (ngModelChange)="onSelectProduct($event)" 
    [disabled]="disabled"
  >
    <ng-container *ngFor="let group of (groupedProducts$ | async) | keyvalue">
      <mat-optgroup [label]="group.key">
        <mat-option
          *ngFor="let product of group.value" 
          [value]="product"
          [disabled]="product.insurance_product_id === productId" 
        >
          {{ product['product_name_' + selectedLanguage] || product.product_default_name }}
        </mat-option>
      </mat-optgroup>
    </ng-container>
    <mat-option *ngIf="isEmpty(groupedProducts$ | async)" disabled>
      No available options
    </mat-option>
  </mat-select>
</mat-form-field>