import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { NgSelectModule } from '@ng-select/ng-select';
import { cloneDeep } from 'lodash-es';

import { PersonService } from 'app/common/supabase-services/person.service';
import { RelationshipTypeCode } from 'app/models/insurance.model';
import { Person } from 'app/common/supabase-models/person';

@Component({
    selector: 'app-person-form',
    templateUrl: './person-form.component.html',
    styleUrls: ['./person-form.component.scss'],
    standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatIconModule,
        MatInputModule,
        MatFormFieldModule,
        MatDatepickerModule,
        MatSelectModule,
        TranslocoModule,
        NgSelectModule,
    ],
})
export class PersonFormComponent implements OnInit {
    @Input() method: 'create' | 'update' = 'create';
    @Input() person: Person;
    @Output() onSave = new EventEmitter<Person>();
    @Output() onCancel = new EventEmitter<void>();
    form: FormGroup;
    processing: boolean = false;
    relationshipItems: RelationshipTypeCode[] = [
        'father', 'mother', 'sibling', 'grandparent', 'lifepartner', 'child', 'me', 'wife', 'husband', 'others',
    ];
    translatedRelationshipItems: { value: string; label: string }[] = [];

    constructor(
        private _personService: PersonService,
        private _formBuilder: FormBuilder,
        private translocoService: TranslocoService
    ) {}

    ngOnInit(): void {
        this.form = this._formBuilder.group({
            owner_id: [this.person?.owner_id, [Validators.required]],
            first_name: [this.person?.first_name, [Validators.required]],
            last_name: [this.person?.last_name, [Validators.required]],
            date_of_birth: [this.person?.date_of_birth, [Validators.required]],
            email: [this.person?.email],
            relationship: [this.person?.relationship || 'others'],
            contact_number: [this.person?.contact_number || ''],
            address_line1: [this.person?.address_line1 || ''],
            address_line2: [this.person?.address_line2 || ''],
            address_city: [this.person?.address_city || ''],
            address_postal_code: [this.person?.address_postal_code || ''],
            address_country: [this.person?.address_country || ''],
        });
        this.translatedRelationshipItems = this.relationshipItems.map(item => ({
            value: item,
            label: this.translocoService.translate(`relationship.${item}`)
          }));
    }

    

    save() {
        if (this.form.invalid) return;
        
        this.processing = true;
        const dataToSave: Person = cloneDeep(this.form.value);
        if (this.method === 'update') {
            dataToSave.id = this.person.id;
        }

        const operation = this.method === 'create' 
            ? this._personService.create(dataToSave) 
            : this._personService.update(dataToSave.id, dataToSave);

        operation.subscribe(
            (data) => {
                this.processing = false;
                this.onSave.emit(data);
            },
            (error) => {
                this.processing = false;
                console.error(`${this.method} operation failed:`, error);
            }
        );
    }

    translateButton(): string {
        return this.translocoService.translate(
            this.method === 'create' ? 'admin.docUploads.create' : 'admin.docUploads.save'
        );
    }

    cancel(): void {
        this.onCancel.emit();
    }
}
