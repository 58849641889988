<ng-container *ngIf="!showPersonForm && !showEntityForm; else displayForm">
    <div class="flex flex-col justify-center items-center">
        <mat-form-field appearance="outline" class="w-full">
            <mat-label>{{ label }}</mat-label>
            <mat-select [(ngModel)]="selectedHolder" (ngModelChange)="onSelectHolder($event)" [disabled]="disabled">
                
                <!-- Person Group -->
                <mat-optgroup [label]="'selectHolder.person' | transloco">
                    <mat-option *ngIf="allowAdhoc" class="italic" [value]="-1">
                        {{ 'selectHolder.addNewPerson' | transloco }}
                    </mat-option>
                    <mat-option *ngFor="let holder of personHolders; trackBy: trackById" [value]="holder">
                        {{ holder.data.full_name }}
                    </mat-option>
                </mat-optgroup>
                
                <!-- Company Group -->
                <mat-optgroup [label]="'selectHolder.company' | transloco">
                    <mat-option *ngIf="allowAdhoc" class="italic" [value]="-2">
                        {{ 'selectHolder.addNewCompany' | transloco }}
                    </mat-option>
                    <mat-option *ngFor="let holder of entityHolders; trackBy: trackById" [value]="holder">
                        {{ holder.data.name }}
                    </mat-option>
                </mat-optgroup>
                
                <!-- No Available Options -->
                <mat-option *ngIf="!personHolders.length && !entityHolders.length && !allowAdhoc" disabled>
                    {{ 'selectHolder.noAvailableOptions' | transloco }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</ng-container>

<!-- Person Form -->
<ng-template #displayForm>
    <app-person-form
        *ngIf="showPersonForm"
        [method]="'create'"
        [person]="{ owner_id: ownerId }"
        (onSave)="onSavePersonForm($event)"
        (onCancel)="onCancelPersonForm()"
    >
    </app-person-form>

    <app-select-object 
        *ngIf="showEntityForm"
        [ownerId]="ownerId"
        [allowAdhoc]="true"
        [selectedObjectTypeId]="3"
        [activateEntityForm]="true"
        (onSaveObject)="closeEntity($event)"
        (onFormCancel)="closeEntity($event)"
    >
    </app-select-object>
</ng-template>