import {
    Component,
    Input,
    Output,
    OnInit,
    OnDestroy,
    OnChanges,
    EventEmitter,
    SimpleChanges,
    ChangeDetectorRef,
    ChangeDetectionStrategy,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoService, TranslocoModule } from '@ngneat/transloco';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { Subscription, Observable } from 'rxjs';

import { InsurerDetailsComponent } from 'app/modules/admin/apps/insurers/insurer-details/insurer-details.component';
import { InsurerWithLocalizationsJsonService } from 'app/common/supabase-services/insurer-with-localizations-json.service';
import { InsurerWithLocalizationsJson } from 'app/common/supabase-models/insurer-with-localizations-json';

@Component({
    selector: 'app-select-insurer',
    templateUrl: './select-insurer.component.html',
    styleUrls: ['./select-insurer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        MatSelectModule,
        MatButtonModule,
        TranslocoModule,
        MatFormFieldModule,
        InsurerDetailsComponent,
    ],
})
export class SelectInsurerComponent implements OnInit, OnChanges, OnDestroy {
    @Input() selectedInsurer: InsurerWithLocalizationsJson;
    @Input() selectedId: number[] = [];
    @Input() onChangeOnly: boolean = false;
    @Input() label: string = 'Select Insurer';
    @Input() btnLabel: string = 'Save';
    @Output() onChanges = new EventEmitter<InsurerWithLocalizationsJson>();
    @Output() adhocToggle = new EventEmitter<boolean>();
    @Output() onClickSave = new EventEmitter<InsurerWithLocalizationsJson>();
    private languageSubscription: Subscription;
    insurers$: Observable<InsurerWithLocalizationsJson[]>;
    toggleInsurerForm: boolean = false;
    currentLanguage: string;

    constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private translocoService: TranslocoService,
        private insurersService: InsurerWithLocalizationsJsonService
    ) {
        this.languageSubscription = this.translocoService.langChanges$.subscribe(
            (lang: string) => {
                this.currentLanguage = lang.toLowerCase();
                this.changeDetectorRef.markForCheck();
            }
        );
    }

    ngOnInit() {
        this.loadInsurers();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.selectedId) {
            this.loadInsurers();
        }
    }

    loadInsurers() {
        this.insurers$ = this.insurersService.getAll();
    }

    closeInsurer(insurer: InsurerWithLocalizationsJson) {
        if (insurer) {
            this.selectedInsurer = insurer;
            this.loadInsurers();
            this.onValueChange();
        }
        this.onAdhocToggle();
        this.changeDetectorRef.markForCheck();
    }

    onSelect(selectedValue: InsurerWithLocalizationsJson | number): void {
        if (typeof selectedValue !== 'number') {
            this.selectedInsurer = selectedValue;
            this.onValueChange();
        } else {
            if (selectedValue === 0) {
                this.onAdhocToggle();
            }
        }
    }

    onAdhocToggle() {
        this.toggleInsurerForm = !this.toggleInsurerForm;
        this.adhocToggle.emit(this.toggleInsurerForm);
    }

    onValueChange() {
        if (this.onChangeOnly) {
            this.onChanges.emit(this.selectedInsurer);
        }
    }

    onSave() {
        this.onClickSave.emit(this.selectedInsurer);
    }

    compareWithFunction(item1: InsurerWithLocalizationsJson, item2: InsurerWithLocalizationsJson) {
        return item1 && item2 ? item1.insurer_id === item2.insurer_id : item1 === item2;
    }

    trackById(index: number, item: InsurerWithLocalizationsJson) {
        return item.insurer_id;
    }

    getLocalizedInsurerName(insurer: InsurerWithLocalizationsJson): string {
        const localization = insurer.localizations[this.currentLanguage];
        return localization?.name || insurer.insurer_name;
    }

    ngOnDestroy() {
        if (this.languageSubscription) {
            this.languageSubscription.unsubscribe();
        }
    }
}
