export type User = {
    id: string;
    full_name: string;
    email: string;
    photo_url: string;
};
export type LangCode = 'de' | 'en' | 'it' | 'fr';
export type CollectionType =
    | 'policy-extractions'
    | 'insurance-retrievals'
    | 'insurances'
    | 'insurers'
    | 'people-and-objects'
    | 'products';
export type ActionType =
    | 'created'
    | 'updated'
    | 'deleted'
    | 'request-to-extraction'
    | 'insurance-unpublished'
    | 'insurance-published'
    | 'insurance-updated'
    | 'insurance-deleted'
    | 'beneficiary-added'
    | 'beneficiary-deleted';
export type CommentType = 'general' | 'user-message' | 'insurer-contact' | 'admin-update';
export type CommentLog = {
    id: string;
    type: ActionType;
    description: string;
    prev_description?: string;
};
export type EmailNotification = {
    email: string;
    title: string;
    description: string;
    urlLink: string;
    btnLabel: string;
};

export interface Comment {
    id: string;
    user: User | null;
    type: CommentType;
    description: string;
    created_at: string;
    updated_at: string;
}

export const CommentTypeMapping = {
    general: 'general',
    'user-message': 'userMessage',
    'insurer-contact': 'insurerContact',
    'admin-update': 'adminUpdate',
} as const;

export const StatusMapping = {
    pending: 'Pending',
    'in-progress': 'In Progress',
    completed: 'Completed',
    closed: 'Closed',
} as const;

export const CollectionTypeMapping = {
    'policy-extractions': 'Policy Extraction',
    'insurance-retrievals': 'Policy Request',
    insurances: 'Insurance',
    insurers: 'Insurer',
    'people-and-objects': 'People & Object',
    products: 'Product',
} as const;
