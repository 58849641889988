import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { CommonModule } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { cloneDeep } from 'lodash-es';
import { Observable, of } from 'rxjs';

import { CoverageGroupMembersService } from 'app/common/supabase-services/coverage-group-members.service';
import { CoverageGroupService } from 'app/common/supabase-services/coverage-group.service';
import { PersonService } from 'app/common/supabase-services/person.service';

import { CoverageGroupWithMembers } from 'app/common/supabase-models/coverage-group-with-members';
import { CoverageGroupMember } from 'app/common/supabase-models/coverage-group-members';
import { Person } from 'app/common/supabase-models/person';

@Component({
    selector: 'app-coverage-group-form',
    templateUrl: './coverage-group-form.component.html',
    styleUrls: ['./coverage-group-form.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatIconModule,
        MatInputModule,
        MatFormFieldModule,
        MatSelectModule,
        TranslocoModule,
    ],
})
export class CoverageGroupFormComponent implements OnInit {
    @Input() method: 'create' | 'update' = 'create';
    @Input() ownerId: string;
    @Input() coverageGroup?: CoverageGroupWithMembers;
    @Output() onSave = new EventEmitter<CoverageGroupWithMembers>();
    @Output() onCancel = new EventEmitter<void>();

    form: FormGroup;
    processing: boolean = false;
    members: Person[] = []; // Selected members
    allPersons$: Observable<Person[]> = of([]);

    constructor(
        private _groupService: CoverageGroupService,
        private _memberService: CoverageGroupMembersService,
        private _personService: PersonService,
        private _formBuilder: FormBuilder,
        private translocoService: TranslocoService
    ) {}

    ngOnInit(): void {
        // Initialize form
        this.form = this._formBuilder.group({
            name: [this.coverageGroup?.coverage_group_name, [Validators.required]],
            description: [this.coverageGroup?.description || ''],
        });

        // Fetch persons by ownerId
        this.fetchPersons();

        // Load members if editing an existing group
        if (this.method === 'update' && this.coverageGroup?.members) {
            this.members = cloneDeep(this.coverageGroup.members);
        }
    }

    /**
     * Fetch persons when the owner ID changes
     */
    fetchPersons() {
        if (this.ownerId) {
            this.allPersons$ = this._personService.getAllByOwnerId(this.ownerId);
        }
    }

    save() {
        if (this.form.invalid) return;

        this.processing = true;
        const groupData = cloneDeep(this.form.value);
        groupData.owner_id = this.ownerId;

        if (this.method === 'update') {
            groupData.id = this.coverageGroup.coverage_group_id;
        }

        const operation = this.method === 'create'
            ? this._groupService.create(groupData)
            : this._groupService.update(groupData.id, groupData);

        operation.subscribe(
            (savedGroup) => {
                if (this.method === 'create') {
                    this.saveMembers(savedGroup.id);
                } else {
                    this.updateMembers(savedGroup.id);
                }
            },
            (error) => {
                this.processing = false;
                console.error(`${this.method} operation failed:`, error);
            }
        );
    }

    /**
     * Save newly selected members for a new group
     */
    saveMembers(groupId: number) {
        const memberRequests: CoverageGroupMember[] = this.members.map((member) => ({
            coverage_group_id: groupId,
            person_id: member.id,
            relationship: member.relationship,
        }));

        memberRequests.forEach((memberRequest) => {
            this._memberService.addMember(memberRequest).subscribe();
        });

        this.processing = false;
        this.onSave.emit({ ...this.form.value, coverage_group_id: groupId, members: this.members });
    }

    /**
     * Update members for an existing group
     */
    updateMembers(groupId: number) {
        this._memberService.getMembersByGroupId(groupId).subscribe((existingMembers) => {
            const existingIds = existingMembers.map((m) => m.person_id);
            const newMembers = this.members.filter((m) => !existingIds.includes(m.id));
            const removedMembers = existingMembers.filter((m) => !this.members.some((nm) => nm.id === m.person_id));

            // Add new members
            newMembers.forEach((newMember) => {
                this._memberService.addMember({
                    coverage_group_id: groupId,
                    person_id: newMember.id,
                    relationship: newMember.relationship,
                }).subscribe();
            });

            // Remove unselected members
            removedMembers.forEach((member) => {
                this._memberService.removeMember(member.id).subscribe();
            });

            this.processing = false;
            this.onSave.emit({ ...this.form.value, coverage_group_id: groupId, members: this.members });
        });
    }

    translateButton(): string {
        return this.translocoService.translate(
            this.method === 'create' ? 'admin.docUploads.create' : 'admin.docUploads.save'
        );
    }

    cancel(): void {
        this.onCancel.emit();
    }
}