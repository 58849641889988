import { CommonModule } from '@angular/common';
import {
    Component,
    ChangeDetectionStrategy,
    ViewEncapsulation,
    ChangeDetectorRef,
    EventEmitter,
    ElementRef,
    ViewChild,
    OnDestroy,
    OnInit,
    Output,
    Input,
} from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { TranslocoModule } from '@ngneat/transloco';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DropZoneDirective } from 'app/common/directives/dropzone.directive';
import { Document } from 'app/common/supabase-models/insurance-retrieval';
import { SupabaseStorageService } from 'app/common/supabase-services/supabase-storage.service';
import { PersonWithSettingService } from 'app/common/supabase-services/person-with-setting.service';
import { PersonWithSetting } from 'app/common/supabase-models/person-with-setting';

@Component({
    selector: 'app-upload',
    templateUrl: './upload.component.html',
    styleUrls: ['./upload.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        CommonModule,
        MatIconModule,
        MatButtonModule,
        MatTooltipModule,
        DropZoneDirective,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        TranslocoModule,
    ],
})
export class UploadComponent implements OnInit, OnDestroy {
    @ViewChild('fileInput', { static: false }) fileInput: ElementRef;
    @Input() multiple: boolean = false;
    @Input() headerTitle: string;
    @Input() basePath: string;
    @Output() fileUploaded = new EventEmitter<Document | Document[]>();
    @Output() onClose = new EventEmitter<void>();

    private _unsubscribeAll: Subject<any> = new Subject<any>();
    private user: PersonWithSetting;
    files: any[] = [];
    uploading: boolean = false;
    isProcessComplete: boolean = false;

    constructor(
        private supabaseStorageService: SupabaseStorageService,
        private personWithSettingService: PersonWithSettingService,
        private _changeDetectorRef: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        this.personWithSettingService.personWithSetting$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((user: PersonWithSetting) => {
                this.user = user;
            });
    }

    startUpload(event: FileList): Promise<void> {
        this.uploading = true;
        this.files = [];
        const uploadedFiles: Document[] = [];

        const uploadTasks: Promise<void>[] = [];

        for (let i = 0; i < event.length; i++) {
            const file = event.item(i);
            const uniqueFileName = `${this.user.user_id}_${Date.now()}_${Math.random()
                .toString(36)
                .substring(2, 15)}_${file.name}`;
            const path = `${this.basePath}/${uniqueFileName}`;

            const fileUpload = {
                name: file.name,
                progress: 0,
                completed: false,
                path,
            };
            this.files.push(fileUpload);

            const index = this.files.findIndex((f) => f.name === file.name);

            const uploadPromise = new Promise<void>((resolve, reject) => {
                const upload$ = this.supabaseStorageService.uploadFileWithProgress('tasks', path, file);

                upload$.subscribe({
                    next: (progress) => {
                        if (typeof progress === 'number') {
                            // Update progress in the UI
                            if (index !== -1) {
                                this.files[index].progress = progress;
                                this._changeDetectorRef.detectChanges();
                            }
                        }
                    },
                    error: (error) => {
                        console.error('Upload error:', error);
                        if (index !== -1) {
                            this.files[index].completed = true;
                            this.files[index].error = true;
                            this._changeDetectorRef.detectChanges();
                        }
                        reject(error);
                    },
                    complete: () => {
                        const publicUrl = this.supabaseStorageService.getPublicUrl('tasks', path);
                        if (index !== -1) {
                            this.files[index].completed = true;
                            uploadedFiles.push({
                                path: publicUrl,
                                filename: this.files[index].name,
                            });
                            this._changeDetectorRef.detectChanges();
                        }
                        resolve();
                    },
                });
            });

            uploadTasks.push(uploadPromise);
        }

        return Promise.all(uploadTasks)
            .then(() => {
                const uploadedDocs: Document | Document[] = !this.multiple ? uploadedFiles[0] : uploadedFiles;
                this.fileUploaded.emit(uploadedDocs);
                this.isProcessComplete = true;
                this.uploading = false;
                this._changeDetectorRef.detectChanges();
                this.onClickClose();
            })
            .catch((error) => {
                console.error('One or more uploads failed:', error);
                this.uploading = false;
                this._changeDetectorRef.detectChanges();
            });
    }

    async onFilesDropped(event: FileList): Promise<void> {
        try {
            await this.startUpload(event);
            this.isProcessComplete = true;
            this._changeDetectorRef.detectChanges();
        } catch (error) {
            console.error('Error during file upload:', error);
        }
    }

    get isUploading() {
        return this.files.some((file) => !file.completed);
    }

    onClickClose(): void {
        this.onClose.emit();
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }
}