import { Injectable } from '@angular/core';
import {
    SupabaseClient,
    RealtimeChannel,
    RealtimePostgresChangesPayload,
} from '@supabase/supabase-js';
import { from, Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { SupabaseClientService } from './supabase-client.service';
import { InsuranceRetrievalView } from '../supabase-models/insurance-retrieval-view';

@Injectable({
    providedIn: 'root',
})
export class InsuranceRetrievalViewService {
    private supabase: SupabaseClient;
    private channel: RealtimeChannel;

    constructor(private _supabaseClientService: SupabaseClientService) {
        this.supabase = this._supabaseClientService.getClient();
    }

    getAllInsuranceRetrievalView(): Observable<InsuranceRetrievalView[]> {
        return from(
            this.supabase
                .from('insurance_retrieval_view')
                .select('*')
        ).pipe(
            map((response) => {
                if (response.error) {
                    console.error('Supabase error:', response.error);
                    throw response.error;
                }
                return (response.data || []) as InsuranceRetrievalView[];
            }),
            catchError((error) => {
                console.error('Error in getAllInsuranceRetrievalView:', error);
                return throwError(() => new Error(error.message));
            })
        );
    }

    getInsuranceRetrievalById(id: number): Observable<InsuranceRetrievalView> {
        return from(
            this.supabase
                .from('insurance_retrieval_view')
                .select('*')
                .eq('insurance_retrieval_id', id)
                .single()
        ).pipe(
            map((response) => {
                if (response.error) {
                    console.error('Supabase error:', response.error);
                    throw response.error;
                }
                return response.data as InsuranceRetrievalView;
            }),
            catchError((error) => {
                console.error('Error in getInsuranceRetrievalById:', error);
                return throwError(() => new Error(error.message));
            })
        );
    }

    getIncompleteInsuranceRetrievalsByUserId(
        userId: string
    ): Observable<InsuranceRetrievalView[]> {
        return from(
            this.supabase
                .from('insurance_retrieval_view')
                .select('*')
                .eq('user_id', userId)
                .neq('status', 'completed')
        ).pipe(
            map((response) => {
                if (response.error) {
                    throw response.error;
                }
                return (response.data || []) as InsuranceRetrievalView[];
            }),
            catchError((error) => throwError(() => new Error(error.message)))
        );
    }

    subscribeToInsuranceRetrievalsByUserId(
        userId: string
    ): Observable<RealtimePostgresChangesPayload<any>> {
        return new Observable((observer) => {
            this.channel = this.supabase
                .channel(`public:insurance_retrieval:user_id=eq.${userId}`)
                .on(
                    'postgres_changes',
                    {
                        event: '*',
                        schema: 'public',
                        table: 'insurance_retrieval',
                        filter: `user_id=eq.${userId}`,
                    },
                    (payload) => {
                        observer.next(payload);
                    }
                )
                .subscribe();

            return () => {
                this.supabase.removeChannel(this.channel);
            };
        });
    }
}
