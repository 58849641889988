import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpEventType, HttpHeaders } from '@angular/common/http';
import { SupabaseClient } from '@supabase/supabase-js';
import { from, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { SupabaseClientService } from './supabase-client.service';

@Injectable({
    providedIn: 'root',
})
export class SupabaseStorageService {
    private supabase: SupabaseClient;
    private supabaseUrl: string;

    constructor(
        private httpClient: HttpClient,
        private supabaseClientService: SupabaseClientService
    ) {
        this.supabase = this.supabaseClientService.getClient();
        this.supabaseUrl = this.supabaseClientService.supabaseUrl;
    }

    /**
     * Upload a file to Supabase storage
     * @param bucketName Name of the storage bucket
     * @param path Path to store the file in (e.g., 'profile-pictures/file.png')
     * @param file The file to upload
     */
    uploadFile(bucketName: string, path: string, file: File): Observable<string> {
        return from(this.supabase.storage.from(bucketName).upload(path, file)).pipe(
            map((response) => {
                if (response.error) {
                    throw new Error(response.error.message);
                }
                // Return the public URL of the uploaded file
                return this.supabase.storage.from(bucketName).getPublicUrl(path).data
                    .publicUrl;
            }),
            catchError((error) => throwError(() => new Error(error.message)))
        );
    }

    /**
     * Uploads a file to Supabase storage with progress tracking.
     *
     * @param bucketName - Name of the storage bucket.
     * @param path - Path where the file will be stored (e.g., 'profile-pictures/file.png').
     * @param file - The file to upload.
     * @param options - Optional parameters:
     *   - upsert: Whether to overwrite an existing file at the path (default is true).
     *   - cacheControl: Cache-Control max-age in seconds (default is '3600').
     * @returns An Observable<number> emitting the upload progress percentage.
     *
     * This method uses Angular's HttpClient to make a direct HTTP POST request to the Supabase Storage API,
     * allowing for progress tracking via reportProgress and observe options.
     *
     * Note: Ensure that the user is authenticated, and the access token is retrieved before making the request.
     * The method emits the upload progress and completes when the upload is finished.
     */
    uploadFileWithProgress(
        bucketName: string,
        path: string,
        file: File,
        options?: { upsert?: boolean; cacheControl?: string }
    ): Observable<number> {
        const upsert = options?.upsert ?? true;
        const cacheControl = options?.cacheControl ?? '3600';

        return new Observable<number>((observer) => {
            this.supabase.auth.getSession().then(({ data, error }) => {
                if (error || !data.session) {
                    observer.error('No session found.');
                    return;
                }
                const accessToken = data.session.access_token;
                const url = `${this.supabaseUrl}/storage/v1/object/${bucketName}/${path}?upsert=${upsert}&cacheControl=${cacheControl}`;
                const headers = new HttpHeaders({
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': file.type,
                });

                this.httpClient
                    .post(url, file, {
                        headers: headers,
                        reportProgress: true,
                        observe: 'events',
                    })
                    .subscribe({
                        next: (event: HttpEvent<any>) => {
                            if (event.type === HttpEventType.UploadProgress) {
                                const progress = Math.round(
                                    (event.loaded * 100) / (event.total || 1)
                                );
                                observer.next(progress);
                            } else if (event.type === HttpEventType.Response) {
                                observer.complete();
                            }
                        },
                        error: (error) => {
                            observer.error(error);
                        },
                    });
            });
        });
    }

    /**
     * Get the public URL for a file
     * @param bucketName Name of the storage bucket
     * @param path Path of the file in the bucket
     */
    getPublicUrl(bucketName: string, path: string): string {
        const { publicUrl } = this.supabase.storage
            .from(bucketName)
            .getPublicUrl(path).data;
        return publicUrl;
    }

    /**
     * Delete a file from Supabase storage
     * @param bucketName Name of the storage bucket
     * @param path Path of the file in the bucket
     */
    deleteFile(bucketName: string, path: string): Observable<void> {
        return from(this.supabase.storage.from(bucketName).remove([path])).pipe(
            map((response) => {
                if (response.error) {
                    throw new Error(response.error.message);
                }
            }),
            catchError((error) => throwError(() => new Error(error.message)))
        );
    }
}
