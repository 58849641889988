<div class="flex flex-col flex-auto w-full">
    <form class="p-4 rounded overflow-hidden" [formGroup]="form" (submit)="save()">

        <!-- Form Fields -->
        <div class="grid sm:grid-cols-4 gap-6 w-full mt-8">
            <!-- First Name -->
            <div class="sm:col-span-2">
                <mat-form-field class="w-full" subscriptSizing="dynamic">
                    <mat-label>{{ 'admin.docUploads.firstName' | transloco }}</mat-label>
                    <input 
                        matInput 
                        formControlName="first_name" 
                        placeholder="{{ 'admin.docUploads.firstName' | transloco }}" 
                        required />
                    <mat-icon class="icon-size-5" matPrefix svgIcon="heroicons_solid:user"></mat-icon>
                </mat-form-field>
            </div>

            <!-- Last Name -->
            <div class="sm:col-span-2">
                <mat-form-field class="w-full" subscriptSizing="dynamic">
                    <mat-label>{{ 'admin.docUploads.lastName' | transloco }}</mat-label>
                    <input 
                        matInput 
                        formControlName="last_name" 
                        placeholder="{{ 'admin.docUploads.lastName' | transloco }}" 
                        required />
                    <mat-icon class="icon-size-5" matPrefix svgIcon="heroicons_solid:user"></mat-icon>
                </mat-form-field>
            </div>

            <!-- Email -->
            <div class="sm:col-span-2">
                <mat-form-field class="w-full" subscriptSizing="dynamic">
                    <mat-label>{{ 'admin.docUploads.email' | transloco }}</mat-label>
                    <input 
                        matInput 
                        formControlName="email" 
                        placeholder="{{ 'admin.docUploads.email' | transloco }}" 
                        type="email"
                        pattern="[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}" />
                    <mat-icon class="icon-size-5" matPrefix svgIcon="heroicons_solid:envelope"></mat-icon>
                </mat-form-field>
            </div>

            <!-- Date of Birth -->
            <div class="sm:col-span-2">
                <mat-form-field class="w-full" subscriptSizing="dynamic">
                    <mat-label>{{ 'admin.docUploads.birthdate' | transloco }}</mat-label>
                    <mat-icon class="icon-size-5" matPrefix svgIcon="heroicons_solid:calendar"></mat-icon>
                    <input matInput [matDatepicker]="dobPicker" formControlName="date_of_birth" />
                    <mat-datepicker-toggle matIconSuffix [for]="dobPicker"></mat-datepicker-toggle>
                    <mat-datepicker #dobPicker></mat-datepicker>
                </mat-form-field>
            </div>

            <!-- Contact Number -->
            <div class="sm:col-span-2">
                <mat-form-field class="w-full" subscriptSizing="dynamic">
                    <mat-label>{{ 'admin.docUploads.contactNo' | transloco }}</mat-label>
                    <mat-icon class="icon-size-5" matPrefix svgIcon="heroicons_solid:phone"></mat-icon>
                    <input matInput formControlName="contact_number" />
                </mat-form-field>
            </div>

            <!-- Relationship -->
            <div class="sm:col-span-2">
                <mat-label class="font-medium">{{ 'admin.docUploads.relationship' | transloco }}</mat-label>
                <ng-select
                formControlName="relationship"
                matInput
                appearance="outline"
                class="-mt-1"
                [items]="translatedRelationshipItems"
                [clearable]="false"
                [searchable]="false"
                bindLabel="label"
                bindValue="value">
                </ng-select>
            </div>

            
            <!-- Address Line 1 -->
            <div class="sm:col-span-4">
                <mat-form-field class="w-full" subscriptSizing="dynamic">
                    <mat-label>{{ 'admin.docUploads.addrLn1' | transloco }}</mat-label>
                    <mat-icon class="icon-size-5" matPrefix svgIcon="heroicons_solid:home"></mat-icon>
                    <input matInput formControlName="address_line1" />
                </mat-form-field>
            </div>

            <!-- Address Line 2 -->
            <div class="sm:col-span-4">
                <mat-form-field class="w-full" subscriptSizing="dynamic">
                    <mat-label>{{ 'admin.docUploads.addrLn2' | transloco }}</mat-label>
                    <mat-icon class="icon-size-5" matPrefix svgIcon="heroicons_solid:building-office"></mat-icon>
                    <input matInput formControlName="address_line2" />
                </mat-form-field>
            </div>

            <!-- Postal Code -->
            <div class="sm:col-span-2">
                <mat-form-field class="w-full" subscriptSizing="dynamic">
                    <mat-label>{{ 'admin.docUploads.postCode' | transloco }}</mat-label>
                    <mat-icon class="icon-size-5" matPrefix svgIcon="heroicons_solid:envelope"></mat-icon>
                    <input matInput formControlName="address_postal_code" />
                </mat-form-field>
            </div>

            <!-- City -->
            <div class="sm:col-span-2">
                <mat-form-field class="w-full" subscriptSizing="dynamic">
                    <mat-label>{{ 'admin.docUploads.city' | transloco }}</mat-label>
                    <mat-icon class="icon-size-5" matPrefix svgIcon="heroicons_solid:building-office-2"></mat-icon>
                    <input matInput formControlName="address_city" />
                </mat-form-field>
            </div>
        </div>

        <!-- Divider -->
        <div class="my-6 border-t"></div>

        <!-- Actions -->
        <div class="flex items-center justify-end gap-2">
            <button 
                type="button" 
                class="px-8" 
                mat-stroked-button 
                [disabled]="processing" 
                (click)="cancel()">
                {{ 'admin.docUploads.cancel' | transloco }}
            </button>
            <button 
                type="submit" 
                class="px-8" 
                mat-flat-button 
                color="primary" 
                [disabled]="!form.valid">
                {{ translateButton() }}
            </button>
        </div>
    </form>
</div>
