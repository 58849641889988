import { Injectable } from '@angular/core';
import { SupabaseClient } from '@supabase/supabase-js';
import { from, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { InsurerLocalization } from '../supabase-models/insurer-localization';
import { SupabaseClientService } from './supabase-client.service';

@Injectable({
    providedIn: 'root',
})
export class InsurerLocalizationService {
    private supabase: SupabaseClient;

    constructor(private supabaseClientService: SupabaseClientService) {
        this.supabase = this.supabaseClientService.getClient();
    }

    // Create a new insurer localization
    create(localization: InsurerLocalization): Observable<InsurerLocalization> {
        return from(
            this.supabase.from('insurer_localization').insert([localization])
        ).pipe(
            map((response) => {
                // Check if there's an error in the response
                if (response.error) {
                    throw new Error(response.error.message);
                }

                // Ensure the data is treated as an array of InsurerLocalization
                const data = response.data as InsurerLocalization[] | undefined;

                // If data is available and has items, return the first record
                if (data && data.length > 0) {
                    return data[0];
                }

                // If no data is returned but no error occurred, assume the insert was successful
                return localization as InsurerLocalization;
            }),
            catchError((error) => throwError(() => new Error(error.message)))
        );
    }

    // Get insurer localization by ID and language code
    getByIdAndLanguage(
        insurer_id: number,
        language_code: string
    ): Observable<InsurerLocalization> {
        return from(
            this.supabase
                .from('insurer_localization')
                .select('*')
                .eq('insurer_id', insurer_id)
                .eq('language_code', language_code)
                .single()
        ).pipe(
            map((response) => response.data as InsurerLocalization),
            catchError((error) => throwError(() => new Error(error.message)))
        );
    }

    // Update insurer localization by ID
    update(
        id: number,
        localization: Partial<InsurerLocalization>
    ): Observable<InsurerLocalization> {
        return from(
            this.supabase.from('insurer_localization').update(localization).eq('id', id)
        ).pipe(
            map((response) => {
                // Check if the update operation returned an error
                if (response.error) {
                    throw new Error(response.error.message);
                }

                // Ensure the data is treated as an array of InsurerLocalization
                const data = response.data as InsurerLocalization[] | undefined;

                // If data is available and has items, return the first record
                if (data && data.length > 0) {
                    return data[0];
                }

                // If no data is returned but no error occurred, assume the update was successful
                return localization as InsurerLocalization;
            }),
            catchError((error) => throwError(() => new Error(error.message)))
        );
    }

    // Delete insurer localization by ID
    delete(id: number): Observable<void> {
        return from(
            this.supabase.from('insurer_localization').delete().eq('id', id)
        ).pipe(
            map(() => void 0),
            catchError((error) => throwError(() => new Error(error.message)))
        );
    }
}
