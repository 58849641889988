import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';

export type pdfContextMenuType = {
    menu: menuItem;
    selection: any;
};

export type menuItem = {
    control: AbstractControl,
    key: string,
    name: string
}
@Injectable({
    providedIn: 'root',
})
export class PdfViewService {
    public _contextMenu: BehaviorSubject<pdfContextMenuType | null> = new BehaviorSubject(null);

    get contextMenu(): Observable<pdfContextMenuType> {
        return this._contextMenu.asObservable();
    }
}
