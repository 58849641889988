<ng-container>
    <div class="relative">
        <div class="min-w-40 min-h-36 flex flex-col justify-end items-center">
            <ng-container>
                <div
                    class="absolute inset-0 -mt-10 flex flex-col justify-center items-center"
                    *ngIf="data.isLoading; else success"
                    @fadeOut
                >
                    <mat-progress-spinner
                        [diameter]="80"
                        [mode]="'indeterminate'"
                    ></mat-progress-spinner>
                </div>
                <ng-template #success>
                    <div
                        class="absolute inset-0 -mt-10 flex flex-col justify-center items-center"
                        @fadeIn
                    >
                        <mat-icon
                            [svgIcon]="'heroicons_outline:check-circle'"
                            style="transform: scale(4)"
                            class="text-teal-600"
                        >
                        </mat-icon>
                    </div>
                </ng-template>
            </ng-container>
            <h1 class="text-lg">{{ data.isLoading ? data.loadingText : data.successText }}</h1>
        </div>
    </div>
</ng-container>
