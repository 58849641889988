import {
    Component,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    ViewEncapsulation,
    ViewChild,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDrawer, MatSidenavModule } from '@angular/material/sidenav';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { ActivatedRoute, Router } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { fuseAnimations } from '@fuse/animations';
import cloneDeep from 'lodash-es/cloneDeep';
import { Subscription } from 'rxjs';

import { InsuranceRetrievalViewService } from 'app/common/supabase-services/insurance-retrieval-view.service';
import { InsuranceRetrievalService } from 'app/common/supabase-services/insurance-retrieval.service';
import { PolicyExtractionService } from 'app/common/supabase-services/policy-extraction.service';
import { InsuranceRetrievalView } from 'app/common/supabase-models/insurance-retrieval-view';
import { EventLogService } from 'app/common/supabase-services/event-log.service';
import { PolicyExtraction } from 'app/common/supabase-models/policy-extraction';
import { CollectionType, LangCode } from 'app/common/supabase-models/common';
import { Document } from 'app/common/supabase-models/insurance-retrieval';
import { EventLog } from 'app/common/supabase-models/event-log';

import { PolicyRequestFormComponent } from 'app/common/components/policy-request-form/policy-request-form.component';
import { UploadComponent } from 'app/common/components/upload/upload.component';
import { EventMessagePipe } from 'app/common/pipes/event-message.pipe';
import { StripHtmlPipe } from 'app/common/pipes/strip-html.pipe';
import { ToDatePipe } from 'app/common/pipes/to-date.pipe';
import { generateTaskID } from 'app/core/helpers/helper';

@Component({
    selector: 'app-policy-request-task',
    templateUrl: './policy-request-task.component.html',
    styleUrls: ['./policy-request-task.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: fuseAnimations,
    standalone: true,
    imports: [
        CommonModule,
        MatIconModule,
        MatButtonModule,
        TranslocoModule,
        MatTooltipModule,
        MatSidenavModule,
        PolicyRequestFormComponent,
        MatProgressBarModule,
        EventMessagePipe,
        UploadComponent,
        StripHtmlPipe,
        ToDatePipe,
    ],
})
export class PolicyRequestTaskComponent implements OnInit, OnDestroy {
    private subscription = new Subscription();
    private languageSubscription: Subscription;
    @ViewChild('matDrawer', { static: false }) matDrawer: MatDrawer;
    matDrawerContext: string = '';
    insuranceRetrieval?: InsuranceRetrievalView;
    events: EventLog[] = [];
    currentLanguage: LangCode;
    eventsLoading = false;
    isLoading = false;

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private changeDetectorRef: ChangeDetectorRef,
        private translocoService: TranslocoService,
        private eventLogService: EventLogService,
        private policyExtractionService: PolicyExtractionService,
        private insuranceRetrievalService: InsuranceRetrievalService,
        private insuranceRetrievalViewService: InsuranceRetrievalViewService
    ) {
        this.languageSubscription = this.translocoService.langChanges$.subscribe(
            (lang: string) => {
                this.currentLanguage = lang.toLowerCase() as LangCode;
            }
        );
    }

    ngOnInit(): void {
        const id = this.activatedRoute.snapshot.paramMap.get('id');
        if (id) {
            this.isLoading = true;
            const dataSubscription = this.insuranceRetrievalViewService
                .getInsuranceRetrievalById(parseInt(id))
                .subscribe({
                    next: (data) => {
                        this.insuranceRetrieval = data;
                        this.isLoading = false;
                        this.fetchEvents(data.insurance_retrieval_id, data.user_id);
                        this.changeDetectorRef.markForCheck();
                    },
                    error: () => {
                        this.isLoading = false;
                        // Handle error
                    },
                });
            this.subscription.add(dataSubscription);
        }
    }

    private fetchEvents(recordId: number, ownerId: string): void {
        this.eventsLoading = true;
        const eventsSubscription = this.eventLogService
            .getEventsByRecordIdAndOwnerId(recordId, ownerId)
            .subscribe({
                next: (events) => {
                    this.events = events.map((event) => ({
                        ...event,
                        language: this.currentLanguage,
                    }));
                    this.eventsLoading = false;
                    this.changeDetectorRef.markForCheck();
                },
                error: () => {
                    this.eventsLoading = false;
                    // Handle error
                },
            });
        this.subscription.add(eventsSubscription);
    }

    get unpublishEventIds(): number[] {
        return this.events
            .filter((event: EventLog) => !event.status || event.status === 'unpublished')
            .map((event: EventLog) => event.id as number);
    }

    updateSelectedEventsStatus(
        ids: number[],
        newStatus: 'unpublished' | 'published'
    ): void {
        this.eventsLoading = true;
        this.eventLogService.updateEventStatus(ids, newStatus).subscribe({
            next: () => {
                this.events = this.events.map((event) => {
                    if (ids.includes(event.id)) {
                        return { ...event, status: newStatus };
                    }
                    return event;
                });
                this.eventsLoading = false;
                this.changeDetectorRef.markForCheck();
            },
            error: (error) => {
                // Handle error
                console.error('Failed to update event status', error);
                this.eventsLoading = false;
                this.changeDetectorRef.markForCheck();
            },
        });
    }

    publishAllEvent() {
        const unpublishIds: number[] = this.unpublishEventIds;
        const newStatus: 'published' = 'published';
        this.updateSelectedEventsStatus(unpublishIds, newStatus);
    }

    updateEventStatus(event: EventLog) {
        let newStatus: 'unpublished' | 'published' = event?.status || 'unpublished';
        if (newStatus === 'unpublished') {
            newStatus = 'published';
        } else {
            newStatus = 'unpublished';
        }
        this.updateSelectedEventsStatus([event.id], newStatus);
    }

    async updatePolicyRequestDocument(documents: Document[] = []) {
        const updateDocs = [...(this.insuranceRetrieval?.documents || []), ...documents];
        this.isLoading = true;
        try {
            await this.insuranceRetrievalService
                .update(this.insuranceRetrieval.insurance_retrieval_id, {
                    documents: updateDocs,
                })
                .toPromise();
            // Update the local state
            this.insuranceRetrieval = cloneDeep({
                ...this.insuranceRetrieval,
                documents: updateDocs,
            });
        } catch (error) {
            console.error('Error updating policy:', error);
        } finally {
            this.isLoading = false;
        }
    }

    deleteEventById(eventId: number): void {
        this.eventsLoading = true;
        const deleteEventSubscription = this.eventLogService
            .deleteEvent(eventId)
            .subscribe({
                next: () => {
                    // Remove the deleted event from the events array
                    this.events = this.events.filter((event) => event.id !== eventId);
                    this.eventsLoading = false;
                    this.changeDetectorRef.markForCheck();
                },
                error: () => {
                    this.eventsLoading = false;
                    // Handle error
                },
            });
        this.subscription.add(deleteEventSubscription);
    }

    deletePolicy(): void {
        this.isLoading = true;
        const deletePolicyRequestSubscription = this.insuranceRetrievalService
            .delete(this.insuranceRetrieval.insurance_retrieval_id)
            .subscribe({
                next: () => {
                    this.isLoading = false;
                    this.navigateToTasks();
                },
                error: () => {
                    this.isLoading = false;
                    // Handle error
                },
            });
        this.subscription.add(deletePolicyRequestSubscription);
    }

    async createExtractionFromDocuments(): Promise<number[]> {
        const { user_id, documents, insurer_default_name } = this.insuranceRetrieval;

        if (!insurer_default_name) {
            console.error('Insurer default name is undefined');
            return [];
        }

        const uploadPromises = documents.map(async (doc) => {
            const data: PolicyExtraction = {
                task_id: generateTaskID(),
                path: doc.path,
                filename: doc.filename,
                status: 'pending',
                user_id: user_id,
                comments: [],
                urgency: 'low',
                created_at: new Date().toISOString(),
                updated_at: new Date().toISOString(),
            };

            try {
                const createdDocument = await this.policyExtractionService
                    .create(data)
                    .toPromise();
                return createdDocument.id || null;
            } catch (error) {
                console.error('Error creating policy extraction:', error);
                return null;
            }
        });

        const documentIds = (await Promise.all(uploadPromises)).filter(
            (id) => id !== null
        );

        return documentIds as number[];
    }

    async convertRequestToExtraction(): Promise<void> {
        this.isLoading = true;
        try {
            await this.insuranceRetrievalService
                .update(this.insuranceRetrieval.insurance_retrieval_id, {
                    status: 'completed',
                })
                .toPromise();

            const extractionIds: number[] = await this.createExtractionFromDocuments();

            const eventsToLog = extractionIds
                .map((extractionId) => {
                    return this.events.map(({ id, ...otherEventProps }: EventLog) => ({
                        ...otherEventProps,
                        record_id: extractionId,
                        collection_type: 'policy-extractions' as CollectionType,
                    }));
                })
                .flat();

            await this.eventLogService.createMultipleEventLogs(eventsToLog).toPromise();
            await this.eventLogService
                .createEvent(
                    this.insuranceRetrieval.insurance_retrieval_id,
                    this.insuranceRetrieval.user_id,
                    'insurance-retrievals',
                    'request-to-extraction',
                    this.insuranceRetrieval.insurer_default_name
                )
                .toPromise();
        } catch (error) {
            console.error('Error converting policy request to policy extraction:', error);
        } finally {
            this.isLoading = false;
            this.navigateToTasks();
        }
    }

    async closeRequest() {
        this.isLoading = true;
        try {
            await this.insuranceRetrievalService
                .update(this.insuranceRetrieval.insurance_retrieval_id, {
                    status: 'closed',
                })
                .toPromise();
        } catch (error) {
            console.error('Error updating policy status:', error);
        } finally {
            this.isLoading = false;
            this.navigateToTasks();
        }
    }

    navigateToTasks(): void {
        this.router.navigate(['admin/tasks']);
    }

    openDrawer(drawerType: string): void {
        if (this.matDrawer) {
            this.matDrawer.open();
            this.matDrawerContext = drawerType;
        } else {
            console.warn('Drawer is not available');
        }
    }

    closeDrawer(): void {
        if (this.matDrawer) {
            this.matDrawer.close();
            this.matDrawerContext = '';
        } else {
            console.warn('Drawer is not available');
        }
    }

    onDataUpdated(): void {
        if (this.insuranceRetrieval) {
            this.fetchEvents(
                this.insuranceRetrieval.insurance_retrieval_id,
                this.insuranceRetrieval.user_id
            );
        }
    }

    get isRequestCloseOrCompleted(): boolean {
        return ['completed', 'closed'].includes(this.insuranceRetrieval?.status);
    }

    get isRequestClosed(): boolean {
        return this.insuranceRetrieval?.status === 'closed';
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
        if (this.languageSubscription) {
            this.languageSubscription.unsubscribe();
        }
    }
}
