import { TextFieldModule } from '@angular/cdk/text-field';
import { AsyncPipe, CommonModule, NgFor, NgIf } from '@angular/common';
import {
    Component,
    OnInit,
    OnChanges,
    OnDestroy,
    Optional,
    Inject,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import {
    FormsModule,
    ReactiveFormsModule,
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { DateAdapter, MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
    MAT_DIALOG_DATA,
    MatDialog,
    MatDialogModule,
    MatDialogRef,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { PeopleAndObject } from 'app/common/supabase-models/people-and-object';
import { PeopleAndObjectService } from 'app/common/supabase-services/people-and-object.service';
import { SwissLuxonDateAdapter } from 'app/common/services/swiss-luxondateadapter';
import { MatSelectModule } from '@angular/material/select';
import { cloneDeep } from 'lodash-es';
import { LoadingOverlayComponent } from 'app/common/components/loading-overlay/loading-overlay.component';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { RelationshipTypeCode } from 'app/models/insurance.model';
import { NgSelectModule } from '@ng-select/ng-select';

@Component({
    selector: 'app-object-form',
    templateUrl: './object-form.component.html',
    styleUrls: ['./object-form.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        MatButtonModule,
        MatIconModule,
        FormsModule,
        TextFieldModule,
        NgSelectModule,
        NgFor,
        MatDialogModule,
        AsyncPipe,
        MatInputModule,
        MatFormFieldModule,
        ReactiveFormsModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatSelectModule,
        TranslocoModule,
    ],
    providers: [
        {
            provide: DateAdapter,
            useClass: SwissLuxonDateAdapter,
        },
    ],
})
export class ObjectFormComponent implements OnInit, OnChanges, OnDestroy {
    @Input() method: 'create' | 'update' = 'create';
    @Input() inputData: PeopleAndObject;
    @Output() closeForm = new EventEmitter<any>();
    form: UntypedFormGroup;
    processing: boolean = false;
    relationshipItems: RelationshipTypeCode[] = [
        'Father',
        'Mother',
        'Sibling',
        'Grandparent',
        'LifePartner',
        'Child',
        'Me',
        'Wife',
        'Husband',
        'Others',
    ];
    translatedRelationshipItems: string[];

    constructor(
        @Optional()
        private dialogRef: MatDialogRef<ObjectFormComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: PeopleAndObject,
        private _peopleAndObjectService: PeopleAndObjectService,
        private _formBuilder: UntypedFormBuilder,
        private _matDialog: MatDialog,
        private translocoService: TranslocoService
    ) {}

    ngOnInit(): void {
        const data = this.data || this.inputData;

        this.form = this._formBuilder.group({
            owner_id: [data.owner_id, [Validators.required]],
            first_name: [data.first_name, [Validators.required]],
            last_name: [data.last_name, [Validators.required]],
            date_of_birth: [data.date_of_birth, [Validators.required]],
            email: [data.email],
            relationship: [data.relationship || 'Others'],
            contact_number: [data.contact_number || ''],
            address_line1: [data.address_line1 || ''],
            address_line2: [data.address_line2 || ''],
            address_city: [data.address_city || ''],
            address_postal_code: [data.address_postal_code || ''],
            address_country: [data.address_country || ''],
            type: [data.type, [Validators.required]],
        });

        this.translatedRelationshipItems = this.relationshipItems.map(
            (item) => 'relationship.' + item.toLowerCase()
        );
    }

    ngOnChanges(): void {}

    ngOnDestroy(): void {
        this.closeForm.emit();
    }

    save() {
        const loadingOverlayData = {
            isLoading: true,
            loadingText: 'Saving Item',
            successText: 'Item Saved',
        };
        const loadingOverlayRef = this._matDialog.open(LoadingOverlayComponent, {
            data: loadingOverlayData,
            disableClose: true,
        });

        const dataToSave: PeopleAndObject = cloneDeep(this.form.value);
        if (this.method === 'update') {
            dataToSave.id = this.inputData.id;
        }

        if (this.method === 'create') {
            this._peopleAndObjectService
                .create(dataToSave)
                .subscribe(
                    (data) => {
                        loadingOverlayRef.componentInstance.data = {
                            ...loadingOverlayData,
                            isLoading: false,
                        };
                        if (this.dialogRef) {
                            this.dialogRef.close({ id: data.id, ...dataToSave });
                        }
                        this.closeForm.emit({ id: data.id, ...dataToSave });
                    },
                    (error) => {
                        loadingOverlayRef.componentInstance.data = {
                            ...loadingOverlayData,
                            isLoading: false,
                        };
                        console.error('Create operation failed:', error);
                    }
                );
        } else {
            this._peopleAndObjectService
                .update(dataToSave.id, dataToSave)
                .subscribe(
                    () => {
                        loadingOverlayRef.componentInstance.data = {
                            ...loadingOverlayData,
                            isLoading: false,
                        };
                        if (this.dialogRef) {
                            this.dialogRef.close();
                        }
                        this.closeForm.emit();
                    },
                    (error) => {
                        loadingOverlayRef.componentInstance.data = {
                            ...loadingOverlayData,
                            isLoading: false,
                        };
                        console.error('Update operation failed:', error);
                    }
                );
        }
    }

    getMethodTranslation(): string {
        const key = this.method === 'create' ? 'admin.docUploads.new' : '';
        return this.translocoService.translate(key);
    }

    translateButton(): string {
        const key = this.method === 'create' ? 'admin.docUploads.create' : 'admin.docUploads.save';
        return this.translocoService.translate(key);
    }

    cancel(): void {
        this.closeForm.emit();
    }
}
