<form [formGroup]="insuranceForm" class="form-container">
    <!-- Select Insurer -->
    <app-select-insurer
        [onChangeOnly]="true"
        [selectedId]="selectedInsurerId"
        (onChanges)="onInsurerSelected($event?.insurer_id)"
    ></app-select-insurer>

    <!-- Select Product -->
    <app-select-grouped-product
        *ngIf="selectedInsurerId"
        [insurerId]="selectedInsurerId"
        [productId]="selectedInsuranceProduct?.insurance_product_id"
        (onSelectProductChange)="onProductSelected($event)"
    ></app-select-grouped-product>

    <!-- Select Policy Holder -->
    <app-select-holder
        [ownerId]="existingInsurance?.owner_id || policyExtraction?.user_id"
        [entityId]="selectedPolicyHolderId || null"
        [disabled]="policyExtraction?.contract_data"
        label="Select Policy Holder"
        (onHolderChange)="onPolicyHolderSelected($event)">
    </app-select-holder>

    <!-- Select Covered Person -->
    <ng-container *ngIf="selectedCoveredEntityType !== 'object'">
        <app-select-beneficiary
            [onChangeOnly]="true"
            [disabled]="disabledEntitySelect"
            [ownerId]="existingInsurance?.owner_id || policyExtraction?.user_id"
            [selectedType]="selectedCoveredEntityType"
            [selectedId]="selectedCoveredEntityId"
            (onChange)="onCoveredEntitySelected($event.data, $event.type)"
        ></app-select-beneficiary>
    </ng-container>

    <!-- Select Covered Object -->
    <ng-container *ngIf="selectedCoveredEntityType === 'object'">
        <app-select-grouped-object
            [onChangeOnly]="true"
            [ownerId]="existingInsurance?.owner_id || policyExtraction?.user_id"
            [entityId]="selectedCoveredEntityId"
            [disabled]="disabledEntitySelect"
            label="Select Covered Object"
            (onSaveObject)="onCoveredEntitySelected($event, 'object')"
        ></app-select-grouped-object>
    </ng-container>

    <div class="grid grid-cols-1 sm:grid-cols-2 gap-3">
        <!-- Payment Periodicity -->
        <mat-form-field class="col-span-1" appearance="outline">
            <mat-label>{{ 'tasks.extraction.periodicity' | transloco }}</mat-label>
            <mat-select formControlName="payment_periodicity">
                <mat-option value="MONTHLY">{{ 'tasks.extraction.monthly' | transloco }}</mat-option>
                <mat-option value="BI-MONTHLY">{{ 'tasks.extraction.biMonthly' | transloco }}</mat-option>
                <mat-option value="QUARTERLY">{{ 'tasks.extraction.quarterly' | transloco }}</mat-option>
                <mat-option value="6 MONTHS">{{ 'tasks.extraction.semiAnnual' | transloco }}</mat-option>
                <mat-option value="12 MONTHS">{{ 'tasks.extraction.annual' | transloco }}</mat-option>
            </mat-select>
        </mat-form-field>

        <!-- Policy Date -->
        <mat-form-field class="col-span-1" appearance="outline">
            <mat-label>{{ 'admin.docUploads.policyDate' | transloco }}</mat-label>
            <input
                matInput
                [matDatepicker]="policyDatePicker"
                formControlName="policy_date"
            />
            <mat-datepicker-toggle matSuffix [for]="policyDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #policyDatePicker></mat-datepicker>
        </mat-form-field>

        <!-- Effective Date -->
        <mat-form-field class="col-span-1" appearance="outline">
            <mat-label>{{ 'admin.docUploads.effectiveDate' | transloco }}</mat-label>
            <input
                matInput
                [matDatepicker]="effectiveDatePicker"
                formControlName="effective_date"
            />
            <mat-datepicker-toggle matSuffix [for]="effectiveDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #effectiveDatePicker></mat-datepicker>
        </mat-form-field>

        <!-- Expiration Date -->
        <mat-form-field class="col-span-1" appearance="outline">
            <mat-label>{{ 'admin.docUploads.expirationDate' | transloco }}</mat-label>
            <input
                matInput
                [matDatepicker]="expirationDatePicker"
                formControlName="expiration_date"
            />
            <mat-datepicker-toggle matSuffix [for]="expirationDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #expirationDatePicker></mat-datepicker>
        </mat-form-field>
    </div>

     <!-- Premium Section -->
     <div formGroupName="premium" class="col-span-2">
        <div class="text-lg font-semibold mb-3">{{ 'admin.docUploads.premium' | transloco }}</div>
        <div class="grid grid-cols-3 gap-3">
            <mat-form-field class="col-span-1">
                <mat-label>{{ 'admin.docUploads.periodicityMos' | transloco }}</mat-label>
                <input matInput formControlName="periodicityNumMonths" type="number" />
            </mat-form-field>

            <mat-form-field class="col-span-1">
                <mat-label>{{ 'admin.docUploads.grossAmt' | transloco }}</mat-label>
                <input 
                    matInput 
                    formControlName="grossCents" 
                    type="text" 
                    (click)="selectAll($event)"
                    (blur)="formatCentsValue('premium.grossCents', $event.target.value)"
                    (focus)="onFocusRemoveFormat('premium.grossCents')"
                />
            </mat-form-field>

            <mat-form-field class="col-span-1">
                <mat-label>{{ 'admin.docUploads.netAmt' | transloco }}</mat-label>
                <input 
                    matInput 
                    formControlName="netCents" 
                    type="text" 
                    (click)="selectAll($event)"
                    (blur)="formatCentsValue('premium.netCents', $event.target.value)"
                    (focus)="onFocusRemoveFormat('premium.netCents')"
                />
            </mat-form-field>
        </div>

        <!-- Discounts Section -->
        <div class="mt-4" formGroupName="discounts">
            <span class="text-lg font-semibold">{{ 'admin.docUploads.discounts' | transloco }}</span>
            <div class="flex flex-col gap-1">
                <!-- Association Discount -->
                <div class="flex items-center gap-3" formGroupName="associationDiscount">
                    <mat-label class="w-1/3">{{ 'admin.docUploads.assocDisc' | transloco }}</mat-label>
                    <mat-form-field class="w-1/3">
                        <mat-label>{{ 'admin.docUploads.percentage' | transloco }}</mat-label>
                        <input matInput formControlName="percentage" type="number" />
                        <span matSuffix>%</span>
                    </mat-form-field>
                    <mat-form-field class="w-1/3">
                        <mat-label>{{ 'admin.docUploads.amount' | transloco }}</mat-label>
                        <input 
                            matInput 
                            formControlName="amountCents" 
                            type="text" 
                            (click)="selectAll($event)"
                            (blur)="formatCentsValue('premium.discounts.associationDiscount.amountCents', $event.target.value)"
                            (focus)="onFocusRemoveFormat('premium.discounts.associationDiscount.amountCents')"
                        />
                    </mat-form-field>
                </div>

                <!-- Family Discount -->
                <div class="flex items-baseline gap-3" formGroupName="familyDiscount">
                    <mat-label class="w-1/3">{{ 'admin.docUploads.famDisc' | transloco }}</mat-label>
                    <mat-form-field class="w-1/3">
                        <input matInput formControlName="percentage" type="number" />
                        <span matSuffix>%</span>
                    </mat-form-field>
                    <mat-form-field class="w-1/3">
                        <input 
                            matInput 
                            formControlName="amountCents" 
                            type="text" 
                            (click)="selectAll($event)"
                            (blur)="formatCentsValue('premium.discounts.familyDiscount.amountCents', $event.target.value)"
                            (focus)="onFocusRemoveFormat('premium.discounts.familyDiscount.amountCents')"
                        />
                    </mat-form-field>
                </div>

                <!-- Loyalty Discount -->
                <div class="flex items-baseline gap-3" formGroupName="loyaltyDiscount">
                    <mat-label class="w-1/3">{{ 'admin.docUploads.loyaltyDisc' | transloco }}</mat-label>
                    <mat-form-field class="w-1/3">
                        <input matInput formControlName="percentage" type="number" />
                        <span matSuffix>%</span>
                    </mat-form-field>
                    <mat-form-field class="w-1/3">
                        <input 
                            matInput 
                            formControlName="amountCents" 
                            type="text" 
                            (click)="selectAll($event)"
                            (blur)="formatCentsValue('premium.discounts.loyaltyDiscount.amountCents', $event.target.value)"
                            (focus)="onFocusRemoveFormat('premium.discounts.loyaltyDiscount.amountCents')"
                        />
                    </mat-form-field>
                </div>

                <!-- Environmental Discount -->
                <div class="flex items-baseline gap-3" formGroupName="environmentalDiscount">
                    <mat-label class="w-1/3">{{ 'admin.docUploads.envDisc' | transloco }}</mat-label>
                    <mat-form-field class="w-1/3">
                        <input matInput formControlName="percentage" type="number" />
                        <span matSuffix>%</span>
                    </mat-form-field>
                    <mat-form-field class="w-1/3">
                        <input 
                            matInput 
                            formControlName="amountCents" 
                            type="text" 
                            (click)="selectAll($event)"
                            (blur)="formatCentsValue('premium.discounts.environmentalDiscount.amountCents', $event.target.value)"
                            (focus)="onFocusRemoveFormat('premium.discounts.environmentalDiscount.amountCents')"
                        />
                    </mat-form-field>
                </div>

                <!-- Other Discounts -->
                <div class="flex items-baseline gap-3" formGroupName="otherDiscounts">
                    <mat-label class="w-1/3">{{ 'admin.docUploads.othDisc' | transloco }}</mat-label>
                    <mat-form-field class="w-1/3">
                        <input matInput formControlName="percentage" type="number" />
                        <span matSuffix>%</span>
                    </mat-form-field>
                    <mat-form-field class="w-1/3">
                        <input 
                            matInput 
                            formControlName="amountCents" 
                            type="text" 
                            (click)="selectAll($event)"
                            (blur)="formatCentsValue('premium.discounts.otherDiscounts.amountCents', $event.target.value)"
                            (focus)="onFocusRemoveFormat('premium.discounts.otherDiscounts.amountCents')"
                        />
                    </mat-form-field>
                </div>
            </div>
        </div>
    </div>

    <!-- Details Notes -->
    <mat-form-field class="w-full" appearance="outline">
        <mat-label>{{ 'admin.docUploads.details' | transloco }}</mat-label>
        <textarea matInput formControlName="details_notes"></textarea>
    </mat-form-field>

    <!-- Dynamic Form Component -->
    <app-dynamic-form
        *ngIf="selectedInsuranceProduct?.options_json_schema_multilang"
        [formGroup]="optionsForm"
        [optionsJsonSchemaMultilang]="selectedInsuranceProduct.options_json_schema_multilang"
        [optionsUiSchema]="selectedInsuranceProduct.options_ui_schema"
        [initialValues]="existingOptions"
    ></app-dynamic-form>

    <!-- Submit Button -->
    <div class="flex justify-end">
        <button
            mat-flat-button
            color="primary"
            type="submit"
            (click)="onSubmit()"
            [disabled]="!insuranceForm.valid || isLoading"
        >
            {{ submitText }}
        </button>
    </div>
</form>