import { Injectable } from '@angular/core';
import { SupabaseClient } from '@supabase/supabase-js';
import { from, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { CoverageGroupMember } from '../supabase-models/coverage-group-members';
import { SupabaseClientService } from './supabase-client.service';

@Injectable({
    providedIn: 'root',
})
export class CoverageGroupMembersService {
    private supabase: SupabaseClient;

    constructor(private supabaseClientService: SupabaseClientService) {
        this.supabase = this.supabaseClientService.getClient();
    }

    /**
     * Add a person to a coverage group
     * @param entity Coverage group member data to insert
     */
    addMember(entity: CoverageGroupMember): Observable<CoverageGroupMember> {
        return from(this.supabase.from('coverage_group_members').insert([entity]).select())
            .pipe(
                map((response) => {
                    if (response.data && response.data.length > 0) {
                        return response.data[0] as CoverageGroupMember;
                    }
                    throw new Error('Failed to add member to coverage group');
                }),
                catchError((error) => throwError(() => new Error(error.message)))
            );
    }

    /**
     * Retrieve all members of a coverage group
     * @param groupId ID of the coverage group
     */
    getMembersByGroupId(groupId: number): Observable<CoverageGroupMember[]> {
        return from(this.supabase.from('coverage_group_members').select('*').eq('coverage_group_id', groupId))
            .pipe(
                map((response) => response.data as CoverageGroupMember[]),
                catchError((error) => throwError(() => new Error(error.message)))
            );
    }

    /**
     * Remove a person from a coverage group
     * @param id ID of the coverage group member record
     */
    removeMember(id: number): Observable<void> {
        return from(this.supabase.from('coverage_group_members').delete().eq('id', id))
            .pipe(
                map(() => void 0),
                catchError((error) => throwError(() => new Error(error.message)))
            );
    }
}
