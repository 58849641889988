import {
    Component,
    Input,
    Output,
    OnInit,
    OnChanges,
    EventEmitter,
    SimpleChanges,
    ChangeDetectorRef,
    ChangeDetectionStrategy,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
    FormsModule,
    ReactiveFormsModule,
    FormBuilder,
    FormGroup,
    Validators,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatOptionModule } from '@angular/material/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { TranslocoModule } from '@ngneat/transloco';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { ObjectExpanded } from 'app/common/supabase-models/object-expanded';
import { ObjectService } from 'app/common/supabase-services/object.service';
import { SupabaseStorageService } from 'app/common/supabase-services/supabase-storage.service';
import { ObjectExpandedService } from 'app/common/supabase-services/object-expanded.service';

@Component({
    selector: 'app-select-grouped-object',
    templateUrl: './select-grouped-object.component.html',
    styleUrls: ['./select-grouped-object.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatOptionModule,
        MatButtonModule,
        MatCheckboxModule,
        TranslocoModule,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectGroupedObjectComponent implements OnInit, OnChanges {
    @Input() ownerId!: string; // Owner ID for filtering objects
    @Input() selectedIds: number[] = []; // Selected object IDs
    @Input() entityId: number | null = null; // Pre-selected object ID
    @Input() label: string = 'Select Object'; // Dropdown label
    @Input() allowAdhoc: boolean = false; // Allow ad-hoc object creation
    @Input() disabled: boolean = false; // Disable interaction
    @Input() onChangeOnly: boolean = false; // Save on change only
    @Output() onSaveObject = new EventEmitter<ObjectExpanded>(); // Emits selected object
    @Output() onFormCancel = new EventEmitter<void>(); // Emits form close

    groupedObjects$: Observable<{ [key: number]: ObjectExpanded[] }>; // Grouped objects
    activateEntityForm: boolean = false; // Show ad-hoc creation form
    form: FormGroup; // Form for ad-hoc object creation
    selectedGroupType: number | null = null; // Track group type for ad-hoc
    selectedObject: ObjectExpanded | null = null; // Selected object
    uploading: boolean = false; // Track logo upload status

    constructor(
        private objectExpandedService: ObjectExpandedService,
        private objectService: ObjectService,
        private supabaseStorageService: SupabaseStorageService,
        private formBuilder: FormBuilder,
        private changeDetectorRef: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.loadObjects();
        if (this.entityId) this.selectedObject = { id: this.entityId } as ObjectExpanded;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.ownerId || changes.entityId) {
            this.loadObjects();
        }
    }

    loadObjects() {
        this.groupedObjects$ = this.objectExpandedService.getByOwnerId(this.ownerId).pipe(
            map((objects) =>
                objects.reduce((groups, obj) => {
                    const groupKey = Number(obj.object_type_id);
                    if (!groups[groupKey]) {
                        groups[groupKey] = [];
                    }
                    groups[groupKey].push(obj);
                    return groups;
                }, {} as { [key: string]: ObjectExpanded[] })
            ),
            tap(() => this.changeDetectorRef.markForCheck())
        );
    }

    onSelectObject(selectedObject: ObjectExpanded | number) {
        if (selectedObject === 0 && this.allowAdhoc) {
            this.activateEntityForm = true;
            this.createForm();
        } else if (typeof selectedObject !== 'number') {
            this.selectedObject = selectedObject;
            if (this.onChangeOnly) this.onSaveObject.emit(this.selectedObject);
        }
    }

    openAdHocForm(groupType: number) {
        this.selectedGroupType = Number(groupType);
        this.createForm();
        this.activateEntityForm = true;
        this.changeDetectorRef.detectChanges();
    }

    createForm() {
        if (!this.selectedGroupType) {
            console.error('Group type must be selected before creating a form.');
            return;
        }

        switch (this.selectedGroupType) {
            case 1: // Car
                this.form = this.formBuilder.group({
                    make: ['', Validators.required],
                    model: ['', Validators.required],
                    year: ['', Validators.required],
                    vin: [''],
                    color: [''],
                    engine_size: [''],
                    registration_number: [''],
                });
                break;
            case 2: // Real Estate
                this.form = this.formBuilder.group({
                    address: ['', Validators.required],
                    size_in_sqft: ['', Validators.required],
                    number_of_rooms: ['', Validators.required],
                    year_built: [''],
                    architect: [''],
                    has_fire_alarm: [false],
                    has_security_system: [false],
                });
                break;
            case 3: // Company
                this.form = this.formBuilder.group({
                    company_name: ['', Validators.required],
                    address: ['', Validators.required],
                    legal_entity_type: ['', Validators.required],
                    founding_year: [''],
                    business_description: [''],
                    logo: [''],
                });
                break;
        }
    }

    uploadLogo(event: any): void {
        const file: File = event.target.files[0];
        if (!file) return;

        this.uploading = true;
        const uniqueFileName = `company_logo_${Date.now()}_${file.name}`;
        const uploadPath = `company_logos/${uniqueFileName}`;

        this.supabaseStorageService
            .uploadFile('company_logos', uploadPath, file)
            .subscribe({
                next: (url) => {
                    this.form.patchValue({ logo: url });
                    this.uploading = false;
                },
                error: (error) => {
                    console.error('Error uploading logo:', error);
                    this.uploading = false;
                },
            });
    }

    saveObject() {
        if (this.activateEntityForm && this.form.valid && this.selectedGroupType) {
            const objectData = {
                object_type_id: this.selectedGroupType,
                name: this.selectedGroupType === 1
                    ? `${this.form.get('make')?.value} ${this.form.get('model')?.value} ${this.form.get('year')?.value}`
                    : this.selectedGroupType === 2
                    ? `${this.form.get('address')?.value} - ${this.form.get('size_in_sqft')?.value} sqft, ${this.form.get('number_of_rooms')?.value} rooms`
                    : this.selectedGroupType === 3
                    ? `${this.form.get('company_name')?.value}`
                    : '',
                dynatt: this.form.value,
                owner_id: this.ownerId
            };

            this.objectService.saveObject(objectData).subscribe({
                next: (response) => {
                    this.loadObjects();
                    this.selectedObject = response;
                    this.activateEntityForm = false;
                    this.selectedGroupType = null;
                    this.onSaveObject.emit(this.selectedObject);
                    this.changeDetectorRef.markForCheck();
                },
                error: (err) => console.error('Error saving object:', err.message),
            });
        }
    }

    onSave() {
        this.onSaveObject.emit(this.selectedObject);
    }

    closeForm() {
        this.activateEntityForm = false;
        this.selectedGroupType = null;
        this.form.reset();
        this.onFormCancel.emit();
    }

    isEmpty(groupedObjects: { [key: number]: ObjectExpanded[] } | null): boolean {
        if (!groupedObjects) return true;
        return Object.values(groupedObjects).every((group) => group.length === 0);
    }

    compareObjWithFunction(item1: ObjectExpanded, item2: ObjectExpanded) {
        return item1 && item2 ? item1.id === item2.id : item1 === item2;
    }
}
