import { Injectable } from '@angular/core';
import {
    SupabaseClient,
    RealtimeChannel,
    RealtimePostgresChangesPayload,
} from '@supabase/supabase-js';
import { from, Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { SupabaseClientService } from './supabase-client.service';
import { PolicyExtractionView } from '../supabase-models/policy-extraction-view';

@Injectable({
    providedIn: 'root',
})
export class PolicyExtractionViewService {
    private supabase: SupabaseClient;
    private channel: RealtimeChannel;

    constructor(private _supabaseClientService: SupabaseClientService) {
        this.supabase = this._supabaseClientService.getClient();
    }

    getAllPolicyExtractionView(): Observable<PolicyExtractionView[]> {
        return from(
            this.supabase
                .from('policy_extraction_view')
                .select('*')
        ).pipe(
            map((response) => {
                if (response.error) {
                    console.error('Supabase error:', response.error);
                    throw response.error;
                }
                return (response.data || []) as PolicyExtractionView[];
            }),
            catchError((error) => {
                console.error('Error in getAllPolicyExtractionView:', error);
                return throwError(() => new Error(error.message));
            })
        );
    }

    getPolicyExtractionById(id: number): Observable<PolicyExtractionView> {
        return from(
            this.supabase
                .from('policy_extraction_view')
                .select('*')
                .eq('policy_extraction_id', id)
                .single()
        ).pipe(
            map((response) => {
                if (response.error) {
                    console.error('Supabase error:', response.error);
                    throw response.error;
                }
                return response.data as PolicyExtractionView;
            }),
            catchError((error) => {
                console.error('Error in getPolicyExtractionById:', error);
                return throwError(() => new Error(error.message));
            })
        );
    }

    getIncompletePolicyExtractionsByUserId(
        userId: string
    ): Observable<PolicyExtractionView[]> {
        return from(
            this.supabase
                .from('policy_extraction_view')
                .select('*')
                .eq('user_id', userId)
                .neq('status', 'completed')
        ).pipe(
            map((response) => {
                if (response.error) {
                    throw response.error;
                }
                return (response.data || []) as PolicyExtractionView[];
            }),
            catchError((error) => throwError(() => new Error(error.message)))
        );
    }

    subscribeToPolicyExtractionsByUserId(
        userId: string
    ): Observable<RealtimePostgresChangesPayload<any>> {
        return new Observable((observer) => {
            this.channel = this.supabase
                .channel(`public:policy_extraction:user_id=eq.${userId}`)
                .on(
                    'postgres_changes',
                    {
                        event: '*',
                        schema: 'public',
                        table: 'policy_extraction',
                        filter: `user_id=eq.${userId}`,
                    },
                    (payload) => {
                        observer.next(payload);
                    }
                )
                .subscribe();

            return () => {
                this.supabase.removeChannel(this.channel);
            };
        });
    }
}
