import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { of, from, switchMap } from 'rxjs';
import { assertNever } from 'app/core/helpers/helper';

export const AuthGuard: CanActivateFn | CanActivateChildFn = (route, state) => {
    const router: Router = inject(Router);

    // Convert the Promise to an Observable using `from()`
    return from(inject(AuthService).check()).pipe(
        switchMap((status) => {
            console.log('[debug] auth guard -> authenticated: ', status);

            switch (status) {
                case 'unauthenticated': {
                    const redirectURL = state.url === '/sign-out' ? '' : `redirectURL=${state.url}`;
                    const urlTree = router.parseUrl(`sign-in?${redirectURL}`);
                    return of(urlTree);
                }
                case 'authenticated': {
                    return of(true);
                }
                default: {
                    return of(assertNever(status));
                }
            }
        })
    );
};
