<div class="w-full h-full p-8">
    <div class="flex flex-wrap justify-center items-center gap-4">
        <mat-form-field appearance="outline" class="w-full md:max-w-40 lg:max-w-60 bg-white"
            [subscriptSizing]="'dynamic'">
            <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_solid:magnifying-glass'"></mat-icon>
            <input placeholder="Search" matInput [(ngModel)]="advancedFilters.query" name="searchQuery"
                (ngModelChange)="onSearch()" />
        </mat-form-field>
        <mat-form-field appearance="outline" class="w-full md:max-w-40 lg:max-w-60 bg-white"
            [subscriptSizing]="'dynamic'">
            <mat-label>{{'tasks.urgency' | transloco}}</mat-label>
            <mat-select [(ngModel)]="advancedFilters.filters.urgency" name="urgency" (ngModelChange)="updateFilter()">
                <mat-option value="low">{{'tasks.urgencies.low' | transloco}}</mat-option>
                <mat-option value="medium">{{'tasks.urgencies.medium' | transloco}}</mat-option>
                <mat-option value="high">{{'tasks.urgencies.high' | transloco}}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" class="w-full md:max-w-40 lg:max-w-60 bg-white"
            [subscriptSizing]="'dynamic'">
            <mat-label>{{'tasks.user' | transloco}}</mat-label>
            <mat-select [(ngModel)]="advancedFilters.filters.user" name="user" (ngModelChange)="updateFilter()">
                <mat-option *ngFor="let user of users" [value]="user.uid">
                    {{ user.displayName }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" class="w-full md:max-w-40 lg:max-w-60 bg-white"
            [subscriptSizing]="'dynamic'">
            <mat-label>{{'tasks.type' | transloco}}</mat-label>
            <mat-select [(ngModel)]="advancedFilters.filters.type" name="type" (ngModelChange)="updateFilter()">
                <mat-option value="policy-extraction">{{'Extraction'}}</mat-option>
                <mat-option value="policy-request">{{'Policy Request'}}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" class="w-full md:max-w-40 lg:max-w-60 bg-white"
            [subscriptSizing]="'dynamic'">
            <mat-label>{{'tasks.status' | transloco}}</mat-label>
            <mat-select [(ngModel)]="advancedFilters.filters.status" name="status" multiple
                (ngModelChange)="updateFilter()">
                <mat-option value="pending">{{'tasks.statuses.pending' | transloco}}</mat-option>
                <mat-option value="in-progress">{{'tasks.statuses.inprogress' | transloco}}</mat-option>
                <mat-option value="completed">{{'tasks.statuses.completed' | transloco}}</mat-option>
                <mat-option value="closed">{{'tasks.statuses.closed' | transloco}}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" class="w-full md:max-w-40 lg:max-w-60 bg-white"
            [subscriptSizing]="'dynamic'">
            <mat-label>{{'tasks.assignee' | transloco}}</mat-label>
            <mat-select [(ngModel)]="advancedFilters.filters.assignee" name="assignee" (ngModelChange)="updateFilter()">
                <mat-option value="null">{{'Unassigned'}}</mat-option>
                <mat-option *ngFor="let assignee of assignees" [value]="assignee.assignee_id">
                    {{ assignee.user_full_name }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <button class="flex justify-center items-center gap-2" (click)="clearFilters()">
            <mat-icon class="icon-size-6" svgIcon="heroicons_solid:arrow-path"></mat-icon>
            <span class="text-md text-secondary">{{'tasks.clear' | transloco}}</span>
        </button>
    </div>
    <div class="mt-8 relative">
        <div class="absolute inset-x-0 top-0" *ngIf="isLoading">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
        <table mat-table [dataSource]="dataSource" matSort>
            <!-- Urgency Column -->
            <ng-container matColumnDef="urgency">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="urgency"></th>
                <td mat-cell *matCellDef="let element" [ngClass]="urgencyColorMapping[element.urgency || 'low']"></td>
            </ng-container>

            <!-- Task ID Column -->
            <ng-container matColumnDef="taskID">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="task_id"> {{'tasks.taskId' | transloco}} </th>
                <td mat-cell *matCellDef="let element"> {{element.task_id}} </td>
            </ng-container>

            <!-- Date/Time Column -->
            <ng-container matColumnDef="dateTime">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="created_at"> {{'tasks.dateTime' | transloco}} </th>
                <td mat-cell *matCellDef="let element"> {{element.created_at | toDate | date: 'dd.MM.yyyy HH:mm'}} </td>
            </ng-container>

            <!-- User Column -->
            <ng-container matColumnDef="user">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="user"> {{'tasks.user' | transloco}} </th>
                <td mat-cell *matCellDef="let element"> {{element.user_full_name || element.user_email}} </td>
            </ng-container>

            <!-- Type Column -->
            <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="type"> {{'tasks.type' | transloco}} </th>
                <td mat-cell *matCellDef="let element"> {{docTypeMapping[element.type]}} </td>
            </ng-container>

            <!-- Detail Column -->
            <ng-container matColumnDef="detail">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="detail"> {{'tasks.detail' | transloco}} </th>
                <td mat-cell *matCellDef="let element">
                    <ng-container *ngIf="element.type === 'policy-request'">
                        {{ element.insurer_default_name || element.insurer_name_en }}
                    </ng-container>
                </td>
            </ng-container>

            <!-- Attachment Column -->
            <ng-container matColumnDef="attachment">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="attachment"> {{'tasks.attachment' | transloco}} </th>
                <td mat-cell *matCellDef="let element">
                    <ng-container *ngIf="element.type === 'policy-extraction'">
                        {{ element.filename }}
                    </ng-container>
                </td>
            </ng-container>

            <!-- Status Column -->
            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="status"> {{'tasks.status' | transloco}} </th>
                <td mat-cell *matCellDef="let element"> {{ element.status }} </td>
            </ng-container>

            <!-- Assigned To Column -->
            <ng-container matColumnDef="assignedTo">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="assignee_id"> {{'tasks.assignee' | transloco}} </th>
                <td mat-cell *matCellDef="let element">
                    {{element.assignee_id ? element.user_full_name : 'Unassigned'}}
                </td>
            </ng-container>

            <!-- Actions Column -->
            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef> {{'tasks.actions' | transloco}} </th>
                <td mat-cell *matCellDef="let element">
                    <div class="flex justify-center items-center gap-2">
                        <button matTooltip="Edit" (click)="navigateToItem(element); $event.stopPropagation()">
                            <mat-icon class="icon-size-5" svgIcon="heroicons_outline:pencil"></mat-icon>
                        </button>
                        <button 
                            *ngIf="element.status !== 'closed'" matTooltip="Close" 
                            (click)="updateStatusToClosed(element); $event.stopPropagation()"
                        >
                            <mat-icon class="icon-size-5" svgIcon="heroicons_outline:x-mark"></mat-icon>
                        </button>
                        <button matTooltip="Delete" (click)="delete(element); $event.stopPropagation()">
                            <mat-icon class="icon-size-5" svgIcon="heroicons_outline:trash"></mat-icon>
                        </button>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr 
                mat-row 
                *matRowDef="let row; columns: displayedColumns;" 
                (click)="navigateToItem(row)" 
                class="task-row cursor-pointer"
            ></tr>
        </table>
        <mat-paginator class="flex justify-end" #paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20]"
            showFirstLastButtons></mat-paginator>
    </div>
</div>