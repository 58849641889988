import { Pipe, PipeTransform } from '@angular/core';
import { Timestamp } from 'firebase/firestore';

@Pipe({
    name: 'toDate',
    pure: true,
    standalone: true,
})
export class ToDatePipe implements PipeTransform {
    transform(value: Date | Timestamp): Date {
        if (value instanceof Timestamp) {
            return value.toDate();
        }
        return value;
    }
}
