import { Injectable, inject } from '@angular/core';
import {
    Firestore,
    addDoc,
    collection,
    doc,
    getDoc,
    getDocs,
    query,
    serverTimestamp,
    setDoc,
    where,
} from '@angular/fire/firestore';
import { Profile } from 'app/models/profile.model';
import { from } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ProfileService {
    private _firestore = inject(Firestore);
    private profilesRef = collection(this._firestore, `profiles`);

    constructor() {}

    async create(data: Profile) {
        const snap = await getDocs(
            query(this.profilesRef, where('userId', '==', data.userId))
        );

        if (snap.docs.length > 0) {
            /**
             * Temporary workaround to trigger profile change
             * @todo Create user admin page to for triggering user roles/claims update
             */
            const doc = snap.docs[0].data()
            this.update({ role: doc.role, 'updatedAt': new Date() })
            return
        } 

        return from(
            addDoc(this.profilesRef, {
                ...data,
                createdAt: serverTimestamp(),
            })
            // .then(() => {
            //     console.log('New profile created', data);
            // })
        );
    }

    async update(data) {
        const snap = doc(this.profilesRef, data.userId);

        return from(
            setDoc(snap, {
                ...data,
            }, {merge: true} )
            // .then(() => {
            //     console.log('Profile updated', data);
            // })
        );
    }
}
