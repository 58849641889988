import { Injectable } from '@angular/core';
import { SupabaseClient } from '@supabase/supabase-js';
import { from, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { InsurerWithLocalizationsJson } from '../supabase-models/insurer-with-localizations-json';
import { SupabaseClientService } from './supabase-client.service';

@Injectable({
    providedIn: 'root',
})
export class InsurerWithLocalizationsJsonService {
    private supabase: SupabaseClient;

    constructor(private supabaseClientService: SupabaseClientService) {
        this.supabase = this.supabaseClientService.getClient();
    }

    // Fetch all insurers with localizations
    getAll(): Observable<InsurerWithLocalizationsJson[]> {
        return from(
            this.supabase.from('insurer_with_localizations_json').select('*')
        ).pipe(
            map((response) => {
                if (response.error) {
                    throw new Error(response.error.message);
                }
                return response.data as InsurerWithLocalizationsJson[];
            }),
            catchError((error) => throwError(() => new Error(error.message)))
        );
    }

    // Fetch a specific insurer with localizations by ID
    getById(id: number): Observable<InsurerWithLocalizationsJson> {
        return from(
            this.supabase
                .from('insurer_with_localizations_json')
                .select('*')
                .eq('insurer_id', id)
                .single()
        ).pipe(
            map((response) => {
                if (response.error) {
                    throw new Error(response.error.message);
                }
                return response.data as InsurerWithLocalizationsJson;
            }),
            catchError((error) => throwError(() => new Error(error.message)))
        );
    }
}
