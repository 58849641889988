<ng-container *ngIf="!activeAdhoc || activeAdhoc === 'insurer'">
    <app-select-insurer
        [selectedInsurer]="selectedInsurer" 
        [onChangeOnly]="true"
        label="Select Insurer*"
        (adhocToggle)="setInsurerAdhoc($event)"
        (onChanges)="handleInsurerChange($event)"
    ></app-select-insurer>
</ng-container>

<ng-container *ngIf="!activeAdhoc || activeAdhoc === 'object'">
    <app-select-people-objects
        [selectedObject]="selectedObject" 
        [ownerId]="ownerId" 
        [selectedIds]="[selectedObject?.id]"
        [onChangeOnly]="true"
        label="Select Holder*"
        (adhocToggle)="setObjectAdhoc($event)"
        (onChanges)="handleObjectChange($event)"
    ></app-select-people-objects>
</ng-container>

<ng-container *ngIf="!activeAdhoc">
    <form [formGroup]="contractDataForm">
        <div class="grid grid-cols-1 sm:grid-cols-2 gap-3">
            <mat-form-field class="col-span-1">
                <mat-label>{{ 'tasks.extraction.periodicity' | transloco }}</mat-label>
                <mat-select formControlName="payment_periodicity">
                    <mat-option value="MONTHLY">{{ 'tasks.extraction.monthly' | transloco }}</mat-option>
                    <mat-option value="BI-MONTHLY">{{ 'tasks.extraction.biMonthly' | transloco }}</mat-option>
                    <mat-option value="QUARTERLY">{{ 'tasks.extraction.quarterly' | transloco }}</mat-option>
                    <mat-option value="6 MONTHS">{{ 'tasks.extraction.semiAnnual' | transloco }}</mat-option>
                    <mat-option value="12 MONTHS">{{ 'tasks.extraction.annual' | transloco }}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="col-span-1">
                <mat-label>{{ 'tasks.extraction.contractDate' | transloco }}</mat-label>
                <input matInput [matDatepicker]="contractDatePicker" formControlName="contract_date" />
                <mat-datepicker-toggle matIconSuffix [for]="contractDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #contractDatePicker></mat-datepicker>
            </mat-form-field>
            <mat-form-field class="col-span-1">
                <mat-label>{{ 'admin.docUploads.effectiveDate' | transloco }}</mat-label>
                <input matInput [matDatepicker]="effectiveDatePicker" formControlName="effective_date" />
                <mat-datepicker-toggle matIconSuffix [for]="effectiveDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #effectiveDatePicker></mat-datepicker>
            </mat-form-field>
            <mat-form-field class="col-span-1">
                <mat-label>{{ 'admin.docUploads.expirationDate' | transloco }}</mat-label>
                <input matInput [matDatepicker]="expirationDatePicker" formControlName="expiration_date" />
                <mat-datepicker-toggle matIconSuffix [for]="expirationDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #expirationDatePicker></mat-datepicker>
            </mat-form-field>
        </div>
        <div class="flex justify-end items-center mt-4">
            <button mat-raised-button color="primary" [disabled]="!contractDataForm.valid || !selectedObject || !selectedInsurer" (click)="onSubmit()">
                {{ 'Save' }}
            </button>
        </div>
    </form>
</ng-container>